// ** React Imports
import { ReactElement } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import CloudDownload from 'mdi-material-ui/CloudDownload'
import OtherHousesIcon from '@mui/icons-material/OtherHouses'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SchoolIcon from '@mui/icons-material/School'

// ** Hook
import { useTheme } from '@mui/material/styles'
interface DataType {
  stats: string
  title: string
  color: string
  icon: ReactElement
}

const salesData: DataType[] = [
  {
    stats: '0',
    title: 'Etablissements',
    color: '#00AEEF',
    icon: <OtherHousesIcon sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '0',
    title: 'Elèves',
    color: '#F06E27',
    icon: <SchoolIcon sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '0',
    color: '#83C142',
    title: 'Enseignants',
    icon: <AccountCircleIcon sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '0',
    color: '#00AEEF',
    title: 'Utilusateurs',
    icon: <AccountCircleIcon sx={{ fontSize: '1.75rem' }} />
  },
  {
    stats: '0',
    color: '#F06E27',
    title: 'Téléchargements',
    icon: <CloudDownload sx={{ fontSize: '1.75rem' }} />
  }
]

const renderStats = () => {
  return salesData.map((item: DataType, index: number) => (
    <Grid item xs={12} md={2} lg={2} sm={2} key={index}>
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          variant='rounded'
          sx={{
            mr: 3,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: 'common.white',
            backgroundColor: `${item.color}`
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption'>{item.title}</Typography>
          <Typography variant='h6'>{item.stats}</Typography>
        </Box>
      </Box>
    </Grid>
  ))
}

const StatisticsCard = () => {
  const theme = useTheme();
  return (
    <Card sx={{background:theme.palette.mode === 'dark'?'#323337':'#fff' }}>
      <CardHeader
        title='Statistiques'
        
        titleTypographyProps={{
          sx: {
            mb: 2.5,
            lineHeight: '2rem !important',
            letterSpacing: '0.15px !important'
          }
        }}
      />
      <CardContent sx={{ pt: theme => `${theme.spacing(3)} !important` }}>

        <Grid container spacing={3}>
        <Grid item md={1} lg={1} sm={1}/>
          {renderStats()}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default StatisticsCard
