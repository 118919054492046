// ** MUI Imports
import { styled, Theme, useTheme } from '@mui/material/styles'
import Carousel from 'react-material-ui-carousel'
import { Box,Card, CardContent,  } from '@mui/material'
import Stack from '@mui/material/Stack';

const ImgStyled = styled('img')(({ theme }) => ({
    maxWidth: '100%',
}))

const CarouselMy = () => {
    const theme = useTheme();
    const items = [{
          img:'rootech.png'
      },
        {
            img:'logo512.png'
        }
    ]

    return <Card sx={{background:theme.palette.mode === 'dark'?'#323337':'#fff',ml:0.5}}>
        <CardContent>
            <Stack sx={{height:451}} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                <ImgStyled src={`/rootech.png`} alt='Profile Pic' />
            </Stack>
        </CardContent>
      
    </Card> 
}

export default CarouselMy