import { ReactNode } from "react";
import {useColorScheme, getInitColorSchemeScript} from "@mui/material/styles";
import { Grid } from "@mui/material";
import Footer from "./footer";
import Header from "./header";


interface Props{
    children: ReactNode
    currentTheme: any
}
const drawerWidth = 270

const EtablissementLayout = ({children,currentTheme}:Props) => {

  return <>
    {getInitColorSchemeScript}
    <main className={"main-" + currentTheme}>
      <Grid container>
        <Header />
        <Grid sx={{
          width: {xs:"100%",md:`calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, lg:`calc(100% - ${drawerWidth}px)`},
          ml:{xs:0.5, sm:0.5, md:34.5},
          mr:0.5,
          mt:{xs:23,sm:23,md:21},
        }}>
          <Grid item xs={12} md={12} lg={12}>
            {children}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Footer/>
        </Grid>
      </Grid>
    </main>
  </>
}

export default EtablissementLayout;