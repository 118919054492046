import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { StoreRoutesType, storeRoutes } from "./store.routes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {storeRoutes.map(({path, component, routes}: StoreRoutesType) => {
            if (routes) {
             return  <Route  path={path} element={component()} key={Math.round(Math.random() * 1000)}>
                {routes.map((subItem: StoreRoutesType,sI: number) => {
                  if (sI === 0 ) {
                    return <Route index element={subItem?.component()} key={Math.round(Math.random() * 1000)}/>
                  } else {
                    return <Route path={`${path}${subItem.path}`} element={subItem?.component()} key={Math.round(Math.random() * 1000)}/>
                  }
                })}
              </Route>
            }else {
              return <Route path={path} element={component()} key={Math.round(Math.random() * 1000)} />
            }
        })}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  )
}


export default Router