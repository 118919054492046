import React from "react";
import {
  Chip,
  FormControl,
  Grid, IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Box
} from "@mui/material";
import {
  AccountCircle,
  LockReset,
  Login,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  BusinessCenter,
  School,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import VALUES from "./stateCommun";
import Button from "@mui/material/Button";
import BackdropWithPortal from "../../components/global/backdrop";
import { useColorScheme } from "@mui/material/styles";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthLogin, credentials } from "../../services/api";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { loginUser } from '../../services/store/auth/auth.slice';
import { useAppDispatch } from '../../services/store/hooks';
import { useNavigate } from 'react-router-dom'
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export interface State extends SnackbarOrigin {
  open: boolean;
}

function AuthAgentView({ currentTheme }: any) {

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleLogin = async (values: credentials) => {
    setOpenBackdrop(true);
    const authUser = await AuthLogin(values);
    setOpenBackdrop(false);
    if (authUser?.data !== undefined && authUser?.data.status === 200) {
      console.log(authUser?.data);
      dispatch(loginUser({
        compteUtilisateur: authUser?.data.compteUtilisateur,
        isAuthenticated: true
      }));
      Navigation('/etablissement')
    } else {
      console.log("error", authUser);

      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }

  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      nomUtilisateur: '',
      motDePasse: ''
    },
    validationSchema: Yup.object({
      nomUtilisateur: Yup.string().required('Required'),
      motDePasse: Yup.string().required('Required')
    }),
    onSubmit: (values) => {

      handleLogin(values)
    }
  });

  return (
    <>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} justifyContent={'center'} >
              <Link to='/auth' style={{ textDecoration: 'none', marginRight: 20 }}>
                <Chip
                  className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                  icon={<School sx={{ fill: 'black' }} />}
                  label={'Eleve'}
                  variant={currentTheme !== "dark" ? "outlined" : "filled"}
                  sx={currentTheme !== "dark" ? { background: '#fff', color: '#000' } :
                    { background: '#323337', color: '#fff' }}
                />
              </Link>
              <Link to='/auth/authAgent' style={{ textDecoration: 'none' }}>
                <Chip
                  className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                  icon={<BusinessCenter sx={{ fill: 'black' }} />}
                  label={'Agent'}
                  variant={currentTheme !== "dark" ? "outlined" : "filled"}
                  sx={currentTheme !== "dark" ? { background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', color: '#000' } :
                    { background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', color: '#fff' }}
                />
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant='subtitle2' className="sub-ty-text-gray">
              Nom d'utilisateur :
            </Typography>
            <TextField
              size="small"
              label="Nom d'utilisateur"
              id="nomUtilisateur"
              name="nomUtilisateur"
              value={values.nomUtilisateur}
              fullWidth
              onChange={handleChange}
              error={
                touched.nomUtilisateur === true ?
                  errors.nomUtilisateur === 'Required' ? true : false
                  : false
              }
              helperText={
                touched.nomUtilisateur === true ?
                  errors.nomUtilisateur === 'Required' ? "le nom d'utilisateur est obligatoire." : ''
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant='subtitle2' className="sub-ty-text-gray" >
              Mot de passe :
            </Typography>

            <FormControl variant="outlined" size='small' fullWidth>
              <TextField
                size="small"
                label="Mot de passe"
                id="motDePasse"
                name="motDePasse"
                type={showPassword ? 'text' : 'password'}
                value={values.motDePasse}
                fullWidth
                onChange={handleChange}
                error={
                  touched.motDePasse === true ?
                    errors.motDePasse === 'Required' ? true : false
                    : false
                }
                helperText={
                  touched.motDePasse === true ?
                    errors.motDePasse === 'Required' ? "le mot de passe est obligatoire." : ''
                  : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} justifyContent="space-around">
              <Link to='#' style={{ textDecoration: 'none' }} >
                <Chip className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                  icon={<LockReset className={currentTheme !== "dark" ? 'iconEl' : 'iconEl_dark'} />} label="Mot de passe oublié ?"
                  variant={currentTheme !== "dark" ? "outlined" : "filled"}

                  sx={currentTheme !== "dark" ? { background: '#fff', color: '#000' } :
                    { background: '#323337', color: '#fff' }} />
              </Link>

              <Button
                type={'submit'}
                sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize' }}
                size="small" variant="contained"
                startIcon={<Login />}>
                Connexion
              </Button>

              <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">Identifiant incorrect!</Alert>
      </Snackbar>
    </>
  );

}

function AgentStore() {
  const { mode } = useColorScheme();
  return <AuthAgentView currentTheme={mode} />
}



export default AgentStore;
