import React, { useState } from "react";
import MUIDataTable, { Responsive,FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";
import {eleves} from "./data";

export type dataType = {
  id:number
  nom: string
  postnom: string
  prenom:  string
  sexe:  string
  classe: string
  lieuDeNaissance: string
  dateDeNaissance:  string
  nationalite: string
  photo:  string
  telephone: string
  email: string
  etatCivil: string
}
const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});

const ListEleveMsgDataTable = ({handleClickRow}:any) => { 
  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [tableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight] = useState("");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();

  const columns = [
    { name: "id",label:'N°',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "nom",label:'Nom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "postnom",label:'Postnom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "prenom",label:'Prénom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "telephone",label:'Téléphone',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
  ];
  // const rows:dataType[] = [];
  const rows= [];
  for (let i = 0; i < eleves.length; i++) {
    const d = eleves[i];
    rows.push({
      id: i + 1,
      nom: d.nom,
      postnom: d.postnom,
      prenom:  d.prenom,
      sexe:  d.sexe,
      classe: d.classe,
      lieuDeNaissance: d.lieuDeNaissance,
      dateDeNaissance:  d.dateDeNaissance,
      nationalite: d.nationalite,
      photo:  d.photo,
      telephone: d.telephone,
      email: d.email,
      etatCivil: d.etatCivil
    });
  }
  
  const handleRowClick = (rowMeta:any,rowData: any) => {
    handleClickRow(rowMeta);
  };
  
  const options = {
    selectableRowsHideCheckboxes: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: filterBtn,
    filterType,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onRowClick: handleRowClick,
    onRowsSelect : (curRowSelected:any, allRowsSelected: any) => {
      console.log("---RowSelect")
      console.log("Row Selected: ", curRowSelected);
      console.log("All Selected: ", allRowsSelected);
    }
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides:{
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important'
              cursor:"pointer"

            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
          
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
    },
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider  theme={getMuiTheme()}>
        <MUIDataTable
            title={"Liste des élèves"}
            data={rows}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
    </CacheProvider> 
  );
}

export default ListEleveMsgDataTable;

