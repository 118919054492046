import { useState } from "react";
import MUIDataTable, { Responsive,FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";

const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});
const DataTable = ({handleClickRow}:any) => { 
  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();

  const columns = [
    { name: "id",label:'N°',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "nom",label:'Nom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "logo",label:'Logo',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "province",label:'Province',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "No",label:`description`,
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
  ];
  const rows= [{}];
  // if(data.length > 0){
  //   for (let i = 0; i < data.length; i++) {
  //     console.log(data.length)
  //      const d = data[i];
  //      rows.push({
  //        id: i + 1,
  //        nom: d.nom,
  //        postnom: d.postnom,
  //        prenom:  d.prenom,
  //        sexe:  d.sexe,
  //        classe: d.classe,
  //        lieuDeNaissance: d.lieuDeNaissance,
  //        dateDeNaissance:  d.dateDeNaissance,
  //        nationalite: d.nationalite,
  //        photo:  d.photo,
  //        telephone: d.telephone,
  //        email: d.email,
  //        etatCivil: d.etatCivil
  //      });
  //   }
  // }
  
  const handleRowClick = (rowMeta:any) => {
    handleClickRow(rowMeta);
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: filterBtn,
    filterType,
    responsive,
    onRowClick: handleRowClick
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides:{
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
          
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
    },
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider  theme={getMuiTheme()}>
        <MUIDataTable
            title={"Liste des établissements"}
            data={rows}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
    </CacheProvider> 
  );
  
}

export default DataTable;

