import * as React from 'react';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { dataType } from "../DataTable";
import { eleves } from '../DataTable/data';
import { GetRoleUsers, GetUsers } from '../../../services/api';
import ListUserDataTable from '../DataTable/listUserRoles';

interface Props {
  mode: Mode | undefined
}

const RolesSommaireView = ({ mode }: Props) => {
  const [userList, setUserList] = React.useState<dataType[]>([]);
  const [currentUser, setCurrentUsers] = React.useState<dataType | undefined>(userList[0]);
  const [rolesList, setRolesList] = React.useState<{ id: number, nom: string }[]>([]);
  const [rolesState, setRoleState] = React.useState<string>(`null`);
  const [toggleViewRoles, setToggleViewRoles] = React.useState<boolean>(true);

  const getRoles = async () => {
    const get_roles = await GetRoleUsers();
    if (get_roles?.data !== undefined && get_roles?.data.status === 200) {
      console.log(get_roles?.data);
      const roles_List = get_roles?.data.roleUtilisateurList
      const roles = roles_List[0];
      setRolesList(roles_List);
      setRoleState(`null`);
      setToggleViewRoles(false);
    } else {
      setRolesList([]);
      setRoleState(`null`);
      setToggleViewRoles(true);
    }
  }
  const getUsers = async () => {
    const get_users = await GetUsers();
    if (get_users?.data !== undefined && get_users?.data.status === 200) {
      console.log(get_users?.data);
      const users_List = get_users?.data.utilisateurList
      const users = users_List[0];
      setUserList(users_List);
      setCurrentUsers(users);
    } else {
      setUserList([]);
      setCurrentUsers(undefined);
    }
  }

  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getUsers();
      getRoles();
    }
    return () => {
      isMuted = false;
    }
  }, []);

  const handleClickRow = (rowMeta: { dataIndex: number, rowIndex: number }) => {
    setCurrentUsers(userList[rowMeta.rowIndex]);
    console.log(eleves[rowMeta.rowIndex]);
  }
  const handelChangeSection = (e: SelectChangeEvent<string>) => {
    setRoleState(e.target.value);
  }

  return (<Grid container md={12} lg={12} xs={12}>
    <Grid item md={12} lg={12} xs={12} sm={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Rôles</InputLabel>
                <Select
                  size='small'
                  label='Rôles'
                  defaultValue='null'
                  value={rolesState}
                  onChange={handelChangeSection}
                  fullWidth
                >
                  <MenuItem value={`null`}>Sélectionnez le rôle</MenuItem>
                  {rolesList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    <Grid item container md={12} lg={12} xs={12} sm={12} sx={{ pt: 0.5 }}>
      <Grid item md={12} lg={12} xs={12} sm={12}>
        <ListUserDataTable handleClickRow={handleClickRow} />
      </Grid>
    </Grid>
    
  </Grid>
  )
}

function RolesSommaireStore() {
  const { mode } = useColorScheme();
  return <RolesSommaireView mode={mode} />
}

export default RolesSommaireStore;
