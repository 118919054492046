

const VALUES = {
    amount: '',
    password: '',
    showPassword: false,
    name_user : '',
    openBackdrop: false,

    currency_ecole: [
        {
            value: '',
            label: '',
        },
    ],
}

export default VALUES;