import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TemporaryDrawer from '../drawer';
import { useAppDispatch, useAppSelector } from '../../../../services/store/hooks';
import { logoutUser, selectAuth } from '../../../../services/store/auth/auth.slice';
import OptionMenu from '../drawer/options';
import { useColorScheme } from '@mui/material/styles';
import MobileDrawer from '../drawer/mobileDrawer';
import { Divider, ListItemIcon } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { FormControl, InputLabel, } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { AnneeScolaire } from '../../../../services/api/AnneeScolaireApi';
import { addAnneeScolaire, selectAnneeScolaire } from '../../../../services/store/annee/annee.scolaire.slice';
import { ChangeAnneeScolaire } from '../../../../services/store/anneeScolaireEncours/annee.scolaire.encours.slice';

interface Props {
  currentTheme:any
  setMode:any
}
const checkCurrentLink = () => {
  const pathname = window.location.pathname.substring(15);
  const position = pathname.indexOf('-');
  if(position !== -1){
    const path = pathname.substring(0,position);
    if(path === 'Eleve')
      return 'Elève'
    else if (path === 'OrganiserEcole')
    return `Organiser l'école`
    else
    return path
  }else{
    return 'Elève'
  }
}

const Header = ({currentTheme,setMode}: Props) => {

  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const anneeScolaire: any = useAppSelector(selectAnneeScolaire);
  const AnneeEncours:any = anneeScolaire.anneeScolaireList[0];
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [annee, setAnnee] = React.useState<string>(`${AnneeEncours.id}`);
  const [openDrawer,setOpenDrawer] = React.useState(false);
  const [linkOption, setLinkOption] =  React.useState<string>(checkCurrentLink);
  const drawerWidth = 270;
  if(Auth.isAuthenticated == false){
    Navigate('http://localhost:3000/');
  }
  React.useEffect(() => {
    let isMuted = true
    async function getAnne() {
      const anneeScolaire =  await AnneeScolaire();
      if(anneeScolaire?.data !== undefined && anneeScolaire?.data.status === 200){
        console.log(anneeScolaire?.data);
        const AnneeScolaire = anneeScolaire?.data.anneeScolaireList[0]
        dispatch(addAnneeScolaire({
          anneeScolaireList: anneeScolaire?.data.anneeScolaireList,
        }));
        setAnnee(`${AnneeScolaire.id}`)
        dispatch(ChangeAnneeScolaire({
          id: AnneeScolaire.id,
          annee: AnneeScolaire.annee,
        }));
      }
    }
    if(Auth.isAuthenticated === false){
      Navigate('/');
    }else{
     
      if(isMuted){
        console.log(Auth);
        getAnne();
      }
    }
    return () => {
      isMuted = false
    }
  },[]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }; 
   
  const handleTheme = () => {
    setMode(currentTheme === 'dark'? 'ligth':'dark');
  };
  
  const onLogout = () => {
    dispatch(logoutUser());
    Navigate('/');
  };

  const toggleDrawer =(open: boolean) => {
    setOpenDrawer(open);
  };

  const handleChangeLinkOPtion = (link:string) => {
    if(linkOption === link){
      setLinkOption('link');
      // setLinkOption(link);
      setTimeout(() => {
        setLinkOption(link);
      }, 10);
    }else{
      setLinkOption(link);
      
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setAnnee(event.target.value);
    const AnneeScolaire = anneeScolaire.anneeScolaireList.find((item: any) => item.id === parseInt(event.target.value))
    if (AnneeScolaire !== undefined) {
      dispatch(ChangeAnneeScolaire({
        id: AnneeScolaire.id,
        annee: AnneeScolaire.annee,
      }));
    }
  };
  return  <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <AppBar position="fixed" sx={{
          background: currentTheme === 'dark'?'#323337':'#fff',
          width: {xs:"100%",md:`calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, lg:`calc(100% - ${drawerWidth}px)`  }
        }}>

          <Toolbar style={{ alignItems: "left", justifyContent: "left" }}>
              <Box sx={{flexGrow:2, display: { xs: 'flex', md: 'flex' } }}>
              <FormControl fullWidth>
                <InputLabel>Année scolaire</InputLabel>
                <Select 
                  size='small'
                  label='Année scolaire'
                  value={annee} 
                  onChange={(e) => handleSelectChange(e)}
                  fullWidth
                >
                  {anneeScolaire.anneeScolaireList.map((item: {id: number, annee: string}) => 
                     <MenuItem value={`${item.id}`}>{item.annee}</MenuItem>
                  )}
                 
                </Select>
              </FormControl>
              </Box>

              {/* menu on mobile */}
              <Grid justify={"center"} alignItems={"center"} container>
                <Grid item>
                  <Grid container justify={"center"}>
                    <Box sx={{flexGrow:1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => toggleDrawer(true)}
                          color="inherit"
                        >
                          <MenuIcon htmlColor={currentTheme === 'dark'?'#fff':'#121212'}/>
                        </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              
              <Box sx={{ flexGrow: 1, display:'flex',}}>
                <Tooltip title="Open settings">
                  <>
                    <Typography sx={{color:currentTheme === 'dark'?'#fff':'#323337',pt: 1, pr:1, fontSize:'14px'}}>
                      {user.prenom} {" "}
                    </Typography> 
                    <Typography sx={{ color:currentTheme === 'dark'?'#fff':'#323337',pt: 1, pr:1, fontSize:'14px'}}>
                      {user.nom}  {" "}
                    </Typography> 
                    <IconButton 
                      onClick={handleOpenUserMenu} 
                      sx={{ 
                        p: 0 ,  
                        background: currentTheme === 'dark'?'#323337':'#fff',
                        fontSize:"10px",
                        textDecoration:"none",
                        color:currentTheme === 'dark'?'#fff':'#323337',
                      }}
                    >
                      <Avatar alt="Img Profile" src="/images/avatars/9.jpg" />
                    </IconButton>
                  </>
                </Tooltip>
                <Menu
                  anchorEl={anchorElUser}
                  id="account-menu"
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        background:currentTheme === 'dark'?'#323337':'#fff',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      background:currentTheme === 'dark'?'#323337':'#fff',
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem>
                    <Avatar /> Profile
                  </MenuItem>
                  {/* <MenuItem onClick={handleTheme}>
                    <ListItemIcon>
                      {currentTheme === 'dark'? 
                      <Brightness7Icon fontSize="small" />
                      :
                      <Brightness4Icon fontSize="small" />
                      }
                    </ListItemIcon>
                    {currentTheme === 'dark'? 'Claire':"Sombre"}
                    
                  </MenuItem> */}
                  <Divider />
                  <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Déconnexion
                  </MenuItem>
                </Menu>
              </Box> 
          </Toolbar>

          <OptionMenu Link={linkOption} mode={currentTheme}/>
        </AppBar>
      </Grid>
      <Box sx={{display: { xs: 'none', md: 'flex' } }}>
        <TemporaryDrawer ChangeLinkOPtion={handleChangeLinkOPtion} currentTheme={currentTheme}/>
      </Box>
      <MobileDrawer currentTheme={currentTheme} toggleDrawer={toggleDrawer} open={openDrawer}  ChangeLinkOPtion={handleChangeLinkOPtion}/>
    </Grid>
 
}
function HeaderStore(){
  const { mode, setMode } = useColorScheme();

  return <Header currentTheme={mode} setMode={setMode} />
}
export default HeaderStore;
