import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { AppState } from '..';

export interface State {
  id: number
  annee: string
}

export interface Action {
}

const initialState: State = {
  id: 0,
  annee: "0000 - 0000"
}



export const anneeScolaireEnCoursSlice: any = createSlice({
  name: 'anneeScolaireEncours',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    ChangeAnneeScolaire: (state, action: PayloadAction<State>) => {
      state.id = action.payload.id
      state.annee = action.payload.annee
    },
  },
})

export const { ChangeAnneeScolaire } = anneeScolaireEnCoursSlice.actions

export const selectAnneeScolaireEncours = (state: AppState) => state.anneeScolaireEncours

export default anneeScolaireEnCoursSlice.reducer

export const persistConfigAnneeScolaireEnCours = {
  key: 'anneeScolaireEnCoursSliceRootLevel3',
  version: 1,
  storage
}
