import * as React from 'react';
import { Grid } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import EleveCoursPost from '../FeaturedPost/eleveCoursPost';
import { dataType } from '../DataTable/ElevesList';
import CoterConduiteEleveCard from '../FeaturedPost/coterconduiteEleveCard';
import { eleves } from '../DataTable/data';
import { State } from '../../auth/auth.agent.view';
import { GetElevesByIdClasse } from '../../../services/api';
interface Props {
  mode: Mode | undefined
}

function EleveCoteConduiteView({ mode }: Props) {
  const [currentCours, setCurrentCours] = React.useState<dataType>(eleves[0]);
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [elevesList, setElevesList] = React.useState<dataType[] | undefined>();
  const [eleve, setEleves] = React.useState<dataType | undefined>(elevesList ? elevesList[0] : undefined);
  const [currentEleve, setCurrentEleve] = React.useState<dataType | undefined>();
  const [index, setIndex] = React.useState<number>(0)
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const getEleves = async (id: string) => {
    const res: any = await GetElevesByIdClasse(id);
    if (res?.data !== undefined && res?.data.status === 200) {
      const eleveList: dataType[] = [];
      for (let i = 0; i < res?.data.eleveList.length; i++) {
        let Eleve = res?.data.eleveList[i];
        Eleve.n = i + 1;
        eleveList.push(Eleve);
      }
      const firstEleve: dataType = eleveList[0];
      setElevesList(eleveList);
      setEleves(firstEleve)
      setCurrentEleve(firstEleve);
    }else{
      setElevesList([]);
      setEleves(undefined);
      setCurrentEleve(undefined);
    }
  }
  
  const classeSelected = (id: string) => {
    getEleves(id);
    setIndex(0)
    console.log(id);

  }

  const changedEleveInCard = (eleve: dataType) => {
    setCurrentEleve(eleve)
    console.log(eleve);
  }

  return (
    <Grid container spacing={0.5} md={12} lg={12} xs={12}>
      <Grid item md={6} lg={6} xs={6}>
        <EleveCoursPost index={index} setIndex={setIndex} elevesList={elevesList} eleve={eleve} setEleves={setEleves} changedEleveInCard={changedEleveInCard} mode={mode} />
      </Grid>
      <Grid item md={6} lg={6} xs={6}>
        <CoterConduiteEleveCard classeSelected={classeSelected} index={index} setIndex={setIndex} setEleves={setEleves} elevesList={elevesList} eleve={currentEleve} mode={mode} />
      </Grid>
    </Grid>
  );
}


function EleveCoteConduiteViewStore() {
  const { mode } = useColorScheme();
  return <EleveCoteConduiteView mode={mode} />
}
export default EleveCoteConduiteViewStore;
