import {
  Autocomplete,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput, Stack,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import {
  LockReset,
  Login,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import {
  BusinessCenter,
  School,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useColorScheme } from "@mui/material/styles";
import BackdropWithPortal from "../../components/global/backdrop";
import VALUES from "./stateCommun";

function AuthEleveView({ currentTheme }: any) {

  const [values, setValues] = React.useState({
    currency_ecole: '',
    amount: '',
    password: '',
    showPassword: false,
    openBackdrop: false
  });

  const handleChange_password = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setValues({
      ...values,
      openBackdrop: false,
    });
  };

  const handleToggle = () => {
    setValues({
      ...values,
      openBackdrop: !values.openBackdrop,
    });
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} justifyContent={'center'} >
            <Link to='/auth' style={{ textDecoration: 'none', marginRight: 20 }}>
              <Chip
                className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                icon={<School sx={{ fill: 'black' }} />}
                label={'Eleve'}
                variant={currentTheme !== "dark" ? "outlined" : "filled"}
                sx={currentTheme !== "dark" ? { background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', color: '#000' } :
                  { background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', color: '#fff' }} />
            </Link>
            <Link to='/auth/authAgent' style={{ textDecoration: 'none' }}>
              <Chip
                className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                icon={<BusinessCenter sx={{ fill: 'black' }} />}
                label={'Agent'}
                variant={currentTheme !== "dark" ? "outlined" : "filled"}
                sx={currentTheme !== "dark" ? { background: '#fff', color: '#000' } :
                  { background: '#323337', color: '#fff' }}
              />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle2' className="sub-ty-text-gray" mb={1}>
            De quelle école êtes-vous ?
          </Typography>

          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            options={VALUES.currency_ecole}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Ecole" />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' className="sub-ty-text-gray" mb={1}>
            Inserez votre code d'identification :
          </Typography>
          <FormControl fullWidth variant="outlined" size='small'>
            <InputLabel htmlFor="outlined-adornment-password">Code d'identification </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange_password('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Code d'identification"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} justifyContent="space-around">
            <Link to='#' style={{ textDecoration: 'none' }}>
              <Chip className={currentTheme !== "dark" ? 'buttonEl' : 'buttonEl_dark'}
                icon={<LockReset className={currentTheme !== "dark" ? 'iconEl' : 'iconEl_dark'} />} label="J'ai oublié mon code"
                variant={currentTheme !== "dark" ? "outlined" : "filled"}
                sx={currentTheme !== "dark" ? { background: '#fff', color: '#000' } :
                  { background: '#323337', color: '#fff' }} />
            </Link>

            <Button onClick={handleToggle} sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize' }} size="small" variant="contained" startIcon={<Login />}>
              Connexion
            </Button>

            <BackdropWithPortal openBackdrop={values.openBackdrop} handleClose={handleClose} />
          </Stack>
        </Grid>
      </Grid>

    </>
  );
}

function EleveStore() {
  const { mode } = useColorScheme();
  return <AuthEleveView currentTheme={mode} />
}

export default EleveStore;
