// ** MUI Imports
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
// ** Custom Components Imports
import ReactApexcharts from 'react-apexcharts'

const WeeklyOverview = () => {
  // ** Hook
  const theme = useTheme()

  const state = {    
    series: [{
      name: 'Etablissement',
      color:'#00AEEF',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66,85,69,25]
    }, {
      name: 'Elèves',
      color:'#F06E27',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94,63,98,69]
    }, {
      name: 'Enseignants',
      color:'#83C142',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41,56,85,96]
    }],
    options: {
      chart: {
        parentHeightOffset: 0,
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan','Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aoùt', 'Sep', 'Oct','Nov','Déc'],
      },
      yaxis: {
        title: {
          text: `Bilan de l'année`
        }
      },
      fill: {
        opacity: 1,
        colors: ['#00AEEF', '#F06E27', '#83C142'],
      },
      tooltip: {
        y: {
          formatter: function (val:any) {
            return val
          }
        }
      }
    },  
  }

  return (
    <Card sx={{background:theme.palette.mode === 'dark'?'#323337':'#fff' }}>
      <CardHeader
        title={`Bilan de l'année`}
        titleTypographyProps={{
          sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
        }}
      />
      <CardContent sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}>
        <ReactApexcharts type='bar' height={388} options={state.options} series={state.series} />
      </CardContent>
    </Card>
  )
}

export default WeeklyOverview
