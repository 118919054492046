import { useEffect, useState } from "react";
import {
  BusinessCenter,
  School,
} from "@mui/icons-material";
import {
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";

import AuthLayaout from "../../components/layout/auth";
import {useColorScheme, getInitColorSchemeScript} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {Outlet, Link} from "react-router-dom";

import "../../assets/styles/authentification.css"
import "../../assets/styles/flex-manage.css"
import VALUES from "../../views/auth/stateCommun";

const AuthModule = () => {
  const { mode, setMode } = useColorScheme();
  const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: ${mode})`);
  const [statePathName, setStatePathName] = useState<string>('/auth')
 
  return <>
    {getInitColorSchemeScript()}
    <AuthLayaout
      currentTheme={mode}
      setMode={setMode}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} pt={3}>
            <Grid item xs={12}>
              <span className={"ty-text ty-text-"+mode}>Gestion Scolaire</span>
              <span className="sub-ty-text" style={{marginBottom:'.5rem'}}>Fiable, efficace, intégrante et facile à utiliser</span>

              <Typography variant="body1" mb={2}>
                La motivation de RooTech est celle de vous faciliter l’administration,
                l’apprentissage et de vous fournir d’autres services en tout genre qui vous facilitent la vie.
              </Typography>

              <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" textAlign="center">
                      RooTech, l'idéal !
                    </Typography>
                  </Grid>
              </Grid>
              <Stack mb={2}>
                <Skeleton variant="rectangular" height={118} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper 
            variant="outlined"
            sx={mode !== "dark" ?  
            {padding:'1rem', borderRadius:'1rem'} : {padding:'1rem', borderRadius:'1rem', background:'#161719'}}
          >
            <Grid container spacing={2} mb={3}>
              <Grid item xs={12}>
                <span className={"ty-text ty-text-"+mode}>Authentification</span>
                <span className="sub-ty-text">Connectez-vous en tant que :</span>
              </Grid>
            </Grid>
            <Outlet />
          </Paper>
        </Grid>
      </Grid>
    </AuthLayaout>
    <div className={"circle circle-" + mode + " circle1"}></div>
    <div className={"circle circle-" + mode + " circle2"}></div>
    <div className={"circle circle-" + mode + " circle3"}></div>
    <div className={"circle circle-" + mode + " circle4"}></div>
    <div className={"circle circle-" + mode + " circle5"}></div>
    <div className={"circle circle-" + mode + " circle6"}></div>
    </>
}

export default AuthModule