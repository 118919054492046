import * as React from 'react';
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import SupportIcon from '@mui/icons-material/Support';
import DemandeAssistance from './demandeAssistance';
import PageAide from './pageAide';
import ConfidentialiteSecurite from './confidentialiteSecurite';
import Accessebilite from './accessibilite';

interface Props {
  mode: Mode | undefined
}

function AideIndexView({ mode }: Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleCreatEleve = (values: any) => { }


  return (
    <Grid md={12} lg={12} xs={12} sx={{mt:{xs: -20, md: -10}}}>
      <Grid container spacing={0.5}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant='h6'>
            Aide
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DemandeAssistance />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <PageAide />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ConfidentialiteSecurite />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Accessebilite />
        </Grid>
      </Grid>
    </Grid>
  );
}


function AideIndexViewStore() {
  const { mode } = useColorScheme();
  return <AideIndexView mode={mode} />
}
export default AideIndexViewStore;
