import GroupIcon from '@mui/icons-material/Group';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChatIcon from '@mui/icons-material/Chat';
import SubjectIcon from '@mui/icons-material/Subject';
import SupportIcon from '@mui/icons-material/Support';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SyncLockIcon from '@mui/icons-material/SyncLock';

const OptionsList = [
  {
    name:"Elève",
    icon:GroupIcon,
    options:[
      {
        name:'Sommaire',
        root:'Sommaire'
      },
      {
        name:'Inscription',
        root:'Inscription'
      },
      {
        name:'Déplacement',
        root:'Deplacement'
      },
      {
        name:'Transfert',
        root:'Transfert'
      },
      {
        name:'Cotation des conduites',
        root:'Cotelaconduite'
      },
     
    ]
  },
  {
    name:"Cours",
    icon:ImportContactsIcon,
    options:[
      {
        name:'Sommaire',
        root:'Sommaire',
      },
      {
        name:'Enregistrement',
        root:'Enregistrer',
      },
      {
        name:'Cotation',
        root:'CoterConduite'
      },
    ]
  },
  {
    name:"Agent",
    icon: PersonOutlineIcon,
    options:[
      {
        name:'Sommaire',
        root:'Sommaire'
      },
      {
        name:'Enregistrement',
        root:'Enregistrer'
      },
      {
        name:'Attribution de cours',
        root:'AttribuerCours'
      },
    ]
  },
  {
    name:"Organiser l'école",
    icon:AccountTreeIcon,
    options:[
      {
        name:'Enregistrement de sections',
        root:'EnregistreSection'
      },
      {
        name:`Enregistrement d'options`,
        root:'EnregistreOption' 
      },
      {
        name:'Enregistrement de classes',
        root:'EnregistreClasse'
      },
    ]
  },
  {
    name:"Gestion des rôles",
    icon:SyncLockIcon,
    options:[
      {
        name:'Sommaire',
        root:'Sommaire'
      },
    ]
  },

  {
    name:"Impression",
    icon: LocalPrintshopIcon,
    options:[
      {
        name:'Index',
        root:'Index'
      }
    ]
  },
  {
    name:"Etablissement",
    icon:AccountBalanceIcon,
    options:[
      {
        name:'Index',
        root:'Index'
      }
    ]
  },
  {
    name:"Messagerie",
    icon:ChatIcon,
    options:[
      {
        name:'Index',
        root:'Index'
      }
    ]
  },
  {
    name:"Aide",
    icon:SupportIcon,
    options:[
      {
        name:'Index',
        root:'Index'
      }
    ]
  },
  {
    name:"Documentation",
    icon:SubjectIcon,
    options:[
      {
        name:'Index',
        root:'Index'
      }
    ]
  },
]

export default OptionsList;