import { combineReducers } from "redux";
import {persistReducer} from 'redux-persist'
import anneeScolaireReducer, { persistConfigAnneeScolaire } from "./annee/annee.scolaire.slice";
import authReducer, {persistConfigAuth} from "./auth/auth.slice";
import degreReducer, { persistConfigdegre } from "./degre/degre.slice";
import periodeReducer, { persistConfigperiode } from "./periode/periode.slice";
import anneeScolaireEncoursReducer, {persistConfigAnneeScolaireEnCours} from './anneeScolaireEncours/annee.scolaire.encours.slice'

export default combineReducers({
    auth : persistReducer(persistConfigAuth, authReducer),
    anneeScolaire: persistReducer(persistConfigAnneeScolaire, anneeScolaireReducer),
    anneeScolaireEncours: persistReducer(persistConfigAnneeScolaireEnCours, anneeScolaireEncoursReducer),
    degre: persistReducer(persistConfigdegre, degreReducer),
    periode: persistReducer(persistConfigperiode, periodeReducer),
})