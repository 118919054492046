import { Grid} from "@mui/material";
import { useTheme } from '@mui/material/styles'
import FeaturedPost from "../../home/FeaturedPost";
import img7 from '../../../assets/img/img7.jpg'
import { useColorScheme } from "@mui/material/styles";

const EtablissementIndexView = ({mode}:any) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0.5} sx={{mt: -20,mr: -1}}>
      <Grid  item xs={12} md={12} sm={12}>
        <FeaturedPost post={
          { description: `  RooTech combine la meilleure expérience de développemet avec une concentration
              obsessionnelle sur les performance des utilisateur.
              Notre plateforme vous permet de voir tout nos produits et services.`,
              image: img7,
              imageLabel: 'innovation',
          title: 'RooTech'}
        }mode={mode}/>
      </Grid>
    </Grid>
  )
}
function EtablissementIndexViewStore(){
  const { mode } = useColorScheme();
  return <EtablissementIndexView mode={mode}/>
}
  
export default EtablissementIndexViewStore;