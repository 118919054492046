import { Box, Grid, Link, Typography } from "@mui/material";

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
       Copyright © 2022 RooTech DRC. Tous droits réservés.
      </Typography>
    );
}
const Footer = () => {
    return  <Grid md={8} sx={{ml:{md:45,xs:2,lg:45}, mt:10,mr:{md:1,xs:1,lg:1}}}>
      <Box sx={{p:6}} component="footer">
          <hr />
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        RooTech
      </Typography>
      <Copyright />
    </Box>
  </Grid>
}

export default Footer;