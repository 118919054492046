import * as React from 'react';
import { Alert, Box, Card, CardContent, CardHeader, Grid, Typography,} from "@mui/material";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save';
import { useAppSelector } from '../../../services/store/hooks';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { CreateSection, GetSection } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';
import { flash } from 'react-animations';

interface Props {
  mode : Mode | undefined
}

function EnregistreSectionView({mode}:Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type:'',
    msg:'',
  });
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const { vertical, horizontal, open } = state;
  const {openSuccess} = stateSuccess;
  const [onFocused, setOnFocused] = React.useState<boolean>(false);
  
  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false});
  }

  const handleCreateSection = async (value:{nom: string}) => {
    setOpenBackdrop(true);
    const createSection = await CreateSection({
      nom: value.nom,
      ecole: compteUtilisateur.ecole.id
    });
    setOpenBackdrop(false);
    if(createSection?.data !== undefined && createSection?.data.status === 201){
      console.log(createSection?.data);
      setStateSuccess({ openSuccess: true, ...{vertical: 'top',
      horizontal: 'center',} });
      setRes({
        type: 'success',
        msg: createSection?.data.message
      });
      setTimeout(() => {
        setStateSuccess({ openSuccess: false, ...{vertical: 'top',
        horizontal: 'center'} });
        setOnFocused(true)
      }, 3000);
      values.nom = '';
    }else{
      console.log("error",createSection);
      setRes({
        type: 'error',
        msg: 'une erreur est survenue lors du traitement de la requête'
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const {handleChange, values, handleSubmit, errors} = useFormik({ 
    initialValues: {
      nom: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreateSection(values)
    }
  });

  return (
    <Grid md={12} lg={12} xs={12}>
      <Card sx={{
          background:mode === 'dark'?'#323337':'#fff',
          width: '100%',
          mt:{xs:-27,md:-6,sm:-6,lg:-6}
        }}>
        <CardContent>
          <Box 
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            
          >
            <Grid container spacing={2}>
              <Grid item md={3}/>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  focused={onFocused}
                  fullWidth
                  onFocus={() => {setOnFocused(true)}}
                  error={errors.nom === 'Required' ? true : false}
                  helperText={errors.nom === 'Required' ? "le nom de la section est obligatoire." : ''}
                />
              </Grid>
              <Grid item md={3}/>

              <Grid item md={4}/>
              <Grid item xs={4}>
                <Button 
                  fullWidth
                  type={'submit'}
                  sx={{background:'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',textTransform:'capitalize',color:'#323337'}}
                  variant="contained" 
                  startIcon={<SaveIcon htmlColor='#323337'/>}
                >
                  Enregistrer
                </Button>
              </Grid>
              <Grid item md={4}/>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'horizontal-key'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop}/>
    </Grid>
  );
}

function EnregistreSectionViewStore(){
  const { mode } = useColorScheme();
  return <EnregistreSectionView mode={mode}/>
}
  
export default EnregistreSectionViewStore;
