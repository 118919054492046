import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Avatar, Box, CardHeader, FormControl, IconButton, InputLabel, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import GradingIcon from '@mui/icons-material/Grading';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
interface FeaturedPostProps {
  post: {
    id: number
    nom: string
    postnom: string
    prenom: string
    sexe: string
    classe: string
    lieuDeNaissance: string
    dateDeNaissance: string
    nationalite: string
    photo: string
    telephone: string
    email: string
    etatCivil: string
  } | undefined;
  mode: any
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  width: { xs: '100%', md: 500 },
};

const ButtonStyled = styled(Button)<ButtonProps & { component?: React.ElementType; htmlFor?: string }>(({ theme }) => ({}));

export default function FeaturedPost(props: FeaturedPostProps) {
  const { post, mode } = props;
  const [open, setOpen] = React.useState(false);
  const [sexe, setSexe] = React.useState<string | undefined>('m');
  const [dateState, setDateState] = React.useState<Date | null | undefined>(new Date(post?.dateDeNaissance ? post?.dateDeNaissance : ""));
  const [imgSrc, setImgSrc] = React.useState<string | undefined>(post?.photo ? post.photo : '/images/avatars/9.jpg');
  const [Etatcivil, setEtatCivil] = React.useState<string | undefined>(post?.etatCivil);
  const [eleveData, setEleveData] = React.useState(post);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setEleveData(post);
    setSexe(post?.sexe)
    setImgSrc(post?.photo)
    setDateState(new Date(post?.dateDeNaissance ? post?.dateDeNaissance : ""))
    setOpen(true)
  };
  const onChange = (file: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = file.target as HTMLInputElement
    if (files && files.length !== 0) {
      reader.onload = () => {
        setImgSrc(reader.result as string)
      }
      reader.readAsDataURL(files[0]);
    }
  }
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSexe(event.target.value);
  }



  return (
    <Grid item>
      <CardActionArea component="div" onClick={handleOpen}>
        <Card
          sx={{
            background: mode === 'dark' ? '#323337' : '#fff',
            pl: 3,
            pr: 3,
            height: { xs: 'auto', md: 720 },
          }}
        >
          <CardHeader>
            <Typography variant="h6">
              Elèves
            </Typography>
          </CardHeader>
          <CardMedia
            component="img"
            sx={{
              width: 200,
              height: 200,
              margin: 'auto',
              mt: 5,
              borderRadius: 25,
            }}
            image={post?.photo ? post.photo : '/images/avatars/9.jpg'}
            alt={post?.photo}
          />
          <CardContent>
            {post ?
              <>
                <Typography variant="subtitle1">
                  Nom: {post?.nom}
                </Typography>
                <Typography variant="subtitle1">
                  Post-nom: {post?.postnom}
                </Typography>
                <Typography variant="subtitle1">
                  Prénom: {post?.prenom}
                </Typography>
                <Typography variant="subtitle1">
                  Sexe: {post?.sexe}
                </Typography>
                <Typography variant="subtitle1">
                  Date de naissance : {post?.dateDeNaissance}
                </Typography>
                <Typography variant="subtitle1">
                  Lieux de naissance :  {post?.lieuDeNaissance}
                </Typography>
                <Typography variant="subtitle1">
                  Nationalité: {post?.nationalite}
                </Typography>
                <Typography variant="subtitle1">
                  Etat civil: {post?.etatCivil}
                </Typography>
                <Typography variant="subtitle1">
                  Tel: {post?.telephone}
                </Typography>
                <Typography variant="subtitle1">
                  email: {post?.email}
                </Typography>
              </>
              :
              <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                Aucun eleve n'a était sélectionner
              </Typography>
            }
          </CardContent>
        </Card>
      </CardActionArea>
      {GetModal({
        post: eleveData,
        open,
        sexe,
        imgSrc,
        dateState,
        Etatcivil,
        setEtatCivil,
        setDateState,
        handleClose,
        handleSelectChange,
        onChange
      })}
    </Grid>
  );
}

const GetModal = ({
  post,
  open,
  sexe,
  imgSrc,
  dateState,
  Etatcivil,
  setEtatCivil,
  setDateState,
  handleClose,
  handleSelectChange,
  onChange
}: any) => {

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      id: post?.id,
      nom: post?.nom,
      postnom: post?.postnom,
      prenom: post?.prenom,
      sexe: post?.sexe,
      lieuDeNaissance: post?.lieuDeNaissance,
      dateDeNaissance: post?.dateDeNaissance,
      email: post?.email,
      telephone: post?.telephone,
      nationalite: post?.nationalite,
      etatCivil: post?.etatCivil
    },
    validationSchema: Yup.object({
      nom: Yup.string(),
      postnom: Yup.string(),
      prenom: Yup.string(),
      sexe: Yup.string(),
      lieuDeNaissance: Yup.string(),
      dateDeNaissance: Yup.date(),
      nationalite: Yup.string(),
      photo: Yup.string(),
      etatCivil: Yup.string(),
      telephone: Yup.string(),
      email: Yup.string().email('email incorrect!'),
    }),
    onSubmit: (values) => {
      handleCreatEleve(values)
    }
  });
  const handleCreatEleve = (values: any) => { }
  
  const getImage = () => {
    if (post?.photo === undefined) {
      return '/images/avatars/9.jpg'
    } else if (post?.photo === null) {
      return '/images/avatars/9.jpg'
    } else if (post?.photo === "") {
      return '/images/avatars/9.jpg'
    } else {
      return post?.photo
    }
  }

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Card >
          <CardHeader
            avatar={
              <Avatar alt='profile img' src={getImage()} />
            }
            action={
              <IconButton aria-label="settings" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            }
            title="Modification"
            subheader={`${post?.nom} ${post?.postnom} ${post?.prenom}`}
          />
          <CardContent sx={{ height: 500, overflowY: 'scroll' }}>
            <Grid container spacing={2} md={12} xs={12}>

              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <img src={getImage()} alt='Profile Pic' width={200} height={200} />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonStyled startIcon={<AddAPhotoIcon htmlColor='#323337' />} sx={{ textTransform: 'capitalize' }} fullWidth color={'success'} component='label' variant='contained' htmlFor='image'>
                      <input
                        hidden
                        type='file'
                        onChange={onChange}
                        accept='image/png, image/jpeg'
                        id='image'
                      />
                    </ButtonStyled>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type={'submit'}
                      sx={{
                        background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                        textTransform: 'capitalize'
                      }}
                      variant="contained"
                      startIcon={<GradingIcon htmlColor='#323337' />}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Postnom"
                  id="postnom"
                  value={values.postnom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="prenom"
                  id="Prenom"
                  value={values.prenom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Sexe</InputLabel>
                  <Select
                    size='small'
                    label='Sexe'
                    defaultValue='M'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='F'>Femme</MenuItem>
                    <MenuItem value='M'>Homme</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="lieuDeNaissance"
                  label="Lieu de naissance"
                  value={values.lieuDeNaissance}
                  onChange={handleChange}
                  placeholder='kinshasa ...'
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date de naissance"
                    value={dateState}
                    onChange={(newValue) => {
                      setDateState(newValue);
                    }}
                    renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="nationalite"
                  label="nationalite"
                  value={values.nationalite}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Etat civil</InputLabel>
                  <Select
                    size='small'
                    label='Etat civil'
                    defaultValue={Etatcivil}
                    value={Etatcivil}
                    onChange={(e) => setEtatCivil(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value='célibataire'>Célibataire</MenuItem>
                    <MenuItem value='marié'>Marié</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="telephone"
                  label="télèphone"
                  value={values.telephone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}