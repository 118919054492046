import {Container} from "@mui/material";
import { ReactNode } from "react";
import Head from "./header";

interface AuthLayaoutProps {
    children : ReactNode,
    currentTheme: any,
    setMode:any
}

const  AuthLayaout = ({children,
    currentTheme, 
    setMode
}:AuthLayaoutProps) => {

    return(
        <main className={"mainauth-" + currentTheme}>
            <Container className={"glass glass-" + currentTheme}>
                <Head currentTheme={currentTheme} setMode={setMode}/>
                {children}
            </Container>
        </main>
    )
}

export default AuthLayaout