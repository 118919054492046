import * as React from 'react';
import { Alert, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import Button from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SaveIcon from '@mui/icons-material/Save';
import { CreateEleve, GetClasseByIdOption, GetOptionByIdSection, GetSection, InscriptionEleve } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';
import moment from 'moment';
import { dataType } from '../DataTable/transfertEleve';
import { useAppSelector } from '../../../services/store/hooks';

import { selectAnneeScolaireEncours } from '../../../services/store/anneeScolaireEncours/annee.scolaire.encours.slice';
import axios from 'axios';
import sha1 from 'sha1';
interface Props {
  mode: Mode | undefined
}

function EleveInscriptionView({ mode }: Props) {
  const AnneeScolaireEncours = useAppSelector(selectAnneeScolaireEncours);
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [sexe, setSexe] = React.useState('F')
  const [imgSrc, setImgSrc] = React.useState<string>('/images/avatars/9.jpg');
  const [dateState, setDateState] = React.useState<Date | null>();
  const [Etatcivil, setEtatCivil] = React.useState<string>('Célibataire');
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  });
  const [sectionState, setSectionState] = React.useState('null');
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [elevesList, setElevesList] = React.useState<dataType[]>([]);
  const [currentEleve, setCurrentEleve] = React.useState<dataType | undefined>(elevesList[0]);
  const [fileImg, setFile] = React.useState<any>();
  const [nomAutoFocus, setnomAutoFocus] = React.useState<boolean>(true);
  // const [nomAutoFocused, setnomAutoFocused] = React.useState<boolean>(false);
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList;
      setSectionList(section_List);
      setOptionState(`null`);
    } else {
      setSectionList(undefined);
      setOptionState(`null`);
    }
  }
  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      setClasseState(`null`);

    } else {
      setOptionList(undefined);
      setClasseState(`null`);
      setToggleViewOption(true);
    }
  }
  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
    } else {
      setClasseList([]);
      setClasseState('null');
      setToggleViewClasse(true);
    }
  }
  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
  }
  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }
  const handelChangeClasse = async (e: SelectChangeEvent<string>) => {
    setClasseState(e.target.value);
  }

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const handleCreatEleve = async (values: any) => {
    setOpenBackdrop(true);
    var data = new FormData();
    var CurrentDate = moment().unix();
    var publicID = sha1(`${values.nom}${values.postnom}${values.prenom}${CurrentDate}`)
    var si = `public_id=${publicID}${CurrentDate}&timestamp=${CurrentDate}k_qCVXtdjeAhGgIathi2Cl3dPg8`;
    data.append('public_id', `${publicID}${CurrentDate}`);
    data.append('timestamp', `${CurrentDate}`);
    data.append('signature', sha1(si));
    data.append('api_key', '425764859379838');
    data.append('file', fileImg);

    if (classeState === 'null') {
      setRes({
        type: 'error',
        msg: 'Veuillez sélectionner une classe'
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
      setOpenBackdrop(false);
    }else{

      var config = {
        method: 'post',
        url: 'https://api.cloudinary.com/v1_1/rootech-drc/image/upload',
        data: data
      };

      const UploadImg = await axios(config)
        .then(function (response) {
          console.log(response.data);
          return response
        })
        .catch(function (error) {
          console.log(error);
          return error
        });
       var image = null
      if (UploadImg?.data !== undefined) {
        image = UploadImg.data.secure_url
      }
      var mmt = moment()
      var mois = mmt.month() + 1
      const createEleve = await CreateEleve({
        nom: values.nom,
        postnom: values.postnom,
        prenom: values.prenom,
        sexe: sexe,
        lieuDeNaissance: values.lieuDeNaissance,
        dateDeNaissance: dateState !== undefined ? moment(dateState).calendar() : `${mois >= 10 ? mois : `0${mois}`}/${mmt.date()}/${mmt.year()}`,
        nationalite: values.nationalite,
        photo: image,
        etatCivil: Etatcivil,
        telephone: values.telephone,
        email: values.email,
        code_identification: '',
      });
      setOpenBackdrop(false);
      if (createEleve?.data !== undefined && createEleve?.data.status === 201) {

        const inscrireEleve = await InscriptionEleve({
          anneeScolaire: AnneeScolaireEncours.id,
          eleve: createEleve?.data.eleve.id,
          classe: parseInt(classeState)
        });
        if (inscrireEleve?.data !== undefined && inscrireEleve?.data.status === 201) {
          setStateSuccess({
            openSuccess: true, ...{
              vertical: 'top',
              horizontal: 'center',
            }
          });
          setRes({
            type: 'success',
            msg: createEleve?.data.message
          });
          setTimeout(() => {
            setStateSuccess({
              openSuccess: false, ...{
                vertical: 'top',
                horizontal: 'center'
              }
            });
          }, 3000);
        } else {
          console.log("error_inscrireEleve", inscrireEleve);
          setRes({
            type: 'error',
            msg: 'une erreur est survenue lors du traitement de la requête'
          });
          handleClick({
            vertical: 'top',
            horizontal: 'center',
          })
        }
        values.nom = '';
        values.postnom = '';
        values.prenom = '';
        values.lieuDeNaissance = '';
        values.nationalite = '';
        values.telephone = '';
        values.email = '';
        setSexe('F');
        setImgSrc('/images/avatars/9.jpg');
        setEtatCivil('Célibataire');
        setFile('');
        setDateState(new Date);
        setnomAutoFocus(true);
        // setnomAutoFocused(true);
      } else {
        console.log("error_createEleve", createEleve);
        if (createEleve?.data === undefined) {
          setRes({
            type: 'error',
            msg: 'une erreur est survenue lors du traitement de la requête'
          });
        }else{
          setRes({
            type: 'error',
            msg: createEleve?.data.message
          });
        }
       
        handleClick({
          vertical: 'top',
          horizontal: 'center',
        })
      }
    
      setOpenBackdrop(false);
    }
  }

  const onChange = (file: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = file.target as HTMLInputElement
    if (files && files.length !== 0) {
      reader.onload = () => {
        setImgSrc(reader.result as string);
      }
      setFile(files[0]);
      reader.readAsDataURL(files[0]);
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSexe(event.target.value);
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      nom: "",
      postnom: "",
      prenom: "",
      lieuDeNaissance: "",
      nationalite: "",
      photo: "",
      telephone: "",
      email: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
      prenom: Yup.string().required('Required'),
      nationalite: Yup.string().required('Required'),
      telephone: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreatEleve(values);
    }
  });

  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false;
    }
  }, []);

  return (
    <Grid item md={12} lg={12} xs={12}>
      <Card sx={{
        background: mode === 'dark' ? '#323337' : '#fff',
        width: '100%',
      }}>
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item container md={12} xs={12}>
                <Grid item md={4} xs={12} />

                <Grid item md={4} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                        }}
                        src={imgSrc} alt='Profile Pic'
                        width={250}
                        height={250}
                        onClick={() => setImgSrc('/images/avatars/9.jpg')}
                        className='w-100'
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ ml: 1 }}>
                      <Button
                        sx={{ textTransform: 'none' }}
                        color={'success'}
                        fullWidth
                        component='label'
                        variant='outlined'
                        htmlFor='image'>
                        <input
                          hidden
                          type='file'
                          onChange={onChange}
                          accept='image/png, image/jpeg'
                          id='image'
                        />
                        <AddAPhotoIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12} />

              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label='Section'
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                    
                  >
                    <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label='Option'
                    defaultValue='null'
                    value={optionState}
                    onChange={handelChangeOption}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem key={item.id}  value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Classe</InputLabel>
                  <Select
                    size='small'
                    label='Classe'
                    defaultValue='null'
                    value={classeState}
                    onChange={handelChangeClasse}
                    fullWidth
                    disabled={toggleViewClasse}
                  >
                    <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                    {classeList?.map((item) => <MenuItem  key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                  // focused={nomAutoFocused}
                  autoFocus={nomAutoFocus}
                  error={
                    touched.nom === true ?
                      errors.nom === 'Required' ? true : false
                      : false
                  }
                  helperText={
                    touched.nom === true ?
                      errors.nom === 'Required' ? "le nom d'éléve est obligatoire." : ''
                      : ''
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Post Nom"
                  id="postnom"
                  value={values.postnom}
                  onChange={handleChange}
                  fullWidth
                  // error={touched.postnom ?
                  //   errors.postnom === 'Required' ? true : false
                  //   : false
                  // }
                  // helperText={touched.postnom ? errors.postnom === 'Required' ? "Le postnom d'éléve est obligatoire." : '' : ''}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Prénom"
                  id="prenom"
                  value={values.prenom}
                  onChange={handleChange}
                  fullWidth
                  error={touched.prenom ? errors.prenom === 'Required' ? true : false : false}
                  helperText={touched.prenom ? errors.prenom === 'Required' ? "Le prénom d'éléve est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Sexe</InputLabel>
                  <Select
                    size='small'
                    label='Sexe'
                    defaultValue='F'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='F'>Féminin</MenuItem>
                    <MenuItem value='M'>Masculin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="lieuDeNaissance"
                  label="Lieu de naissance"
                  value={values.lieuDeNaissance}
                  onChange={handleChange}
                  placeholder='kinshasa ...'
                  fullWidth
                  // error={touched.lieuDeNaissance ? errors.lieuDeNaissance === 'Required' ? true : false : false}
                  // helperText={touched.lieuDeNaissance ? errors.lieuDeNaissance === 'Required' ? "Le lieu de naissance d'éléve est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date de naissance"
                    value={dateState}
                    onChange={(newValue) => {
                      setDateState(newValue);
                    }}
                    renderInput={(params) => <TextField size='small' {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="nationalite"
                  label="Nationalité"
                  value={values.nationalite}
                  onChange={handleChange}
                  fullWidth
                  error={touched.nationalite ? errors.nationalite === 'Required' ? true : false : false}
                  helperText={touched.nationalite ? errors.nationalite === 'Required' ? "La nationalité d'éléve est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Etat civil</InputLabel>
                  <Select
                    size='small'
                    label='Etat civil'
                    defaultValue='Célibataire'
                    value={Etatcivil}
                    onChange={(e) => setEtatCivil(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value='Célibataire'>Célibataire</MenuItem>
                    <MenuItem value='Marié(e)'>Marié(e)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="telephone"
                  label="Téléphone"
                  value={values.telephone}
                  onChange={handleChange}
                  fullWidth
                  error={touched.telephone ? errors.telephone === 'Required' ? true : false : false}
                  helperText={touched.telephone ? errors.telephone === 'Required' ? "Le numéro de téléphone d'éléve est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  // error={touched.email ? errors.email === 'Required' ? true : false : false}
                  // helperText={touched.email ? errors.email === 'Required' ? "L'adresse e-mail d'éléve est obligatoire." : '' : ''}
                />
              </Grid>

              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{
                    background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                    textTransform: 'capitalize',
                    boxShadow: 10,
                    color: '#323337'
                  }}
                  variant="contained"
                  startIcon={<SaveIcon htmlColor='#323337' />}
                >
                  Enregistrer
                </Button>
              </Grid>
              <Grid item xs={12} md={4} />

            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'dkkfrii'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

function EleveInscriptionViewStore() {
  const { mode } = useColorScheme();
  return <EleveInscriptionView mode={mode} />
}

export default EleveInscriptionViewStore;