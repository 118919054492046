import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { AppState } from '..';

export interface State {
  degreList: [
    {
      id: number
      nom: string
    },

  ]
}

export interface Action {
}

const initialState: State = {
    degreList: [
    {
      id: 0,
      nom: ""
    },

  ]
}


export const degreSlice = createSlice({
  name: 'degre',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addDegre: (state, action: PayloadAction<State>) => {
      state.degreList = action.payload.degreList
    },
  },
})

export const { addDegre } = degreSlice.actions

export const selectDegre = (state: AppState) => state.degre

export default degreSlice.reducer

export const persistConfigdegre = {
  key: 'degreRootLevel3',
  version: 1,
  storage
}
