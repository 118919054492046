import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { AppState } from '..';

export interface State {
  anneeScolaireList: [
    {
      id: number
      annee: string
    },

  ]
}

export interface Action {
}

const initialState: State = {
  anneeScolaireList: [
    {
      id: 0,
      annee: "0000 - 0000"
    },

  ]
}


export const anneeScolaireSlice = createSlice({
  name: 'anneeScolaire',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addAnneeScolaire: (state, action: PayloadAction<State>) => {
      state.anneeScolaireList = action.payload.anneeScolaireList
    },
  },
})

export const { addAnneeScolaire } = anneeScolaireSlice.actions

export const selectAnneeScolaire: any = (state: AppState) => state.anneeScolaire

export default anneeScolaireSlice.reducer

export const persistConfigAnneeScolaire = {
  key: 'anneeScolaireRootLevel3',
  version: 1,
  storage
}
