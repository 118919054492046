import * as React from 'react';
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, Menu, MenuItem, SnackbarOrigin, Typography } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useAppDispatch, useAppSelector } from '../../../services/store/hooks';
import { addDegre, selectDegre } from '../../../services/store/degre/degre.slice';
import { GetPeriode } from '../../../services/api/periodeApi';
import { GetDegre } from '../../../services/api/degreApi';
import { GetSession } from '../../../services/api/sessionApi';
import ListSubheader from '@mui/material/ListSubheader';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import { GetClasseByIdOption, GetOptionByIdSection, GetSection } from '../../../services/api';

interface Props {
  mode: Mode | undefined
}

const ButtonStyled = styled(Button)<ButtonProps & { component?: React.ElementType; htmlFor?: string }>(({ theme }) => ({}));

function EleveImprimerView({ mode }: Props) {
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const dispatch = useAppDispatch();
  const degreState = useAppSelector(selectDegre);
  const [select, setSelect] = React.useState('1');
  const [select1, setSelect1] = React.useState('a');
  const [periode, setPeriode] = React.useState<string>('null');
  const [periodeList, setPeriodeList] = React.useState<{ id: number, nom: string }[]>([])
  const [degre, setDegre] = React.useState<string>(`null`);
  const [classe, setClasse] = React.useState('');
  const [sessionList, setSessionList] = React.useState<{ id: number, nom: string }[]>([]);
  const [session, setSession] = React.useState<string>('null');
  const [typeDocument, setTypeDocument] = React.useState<string>('1');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  })
  // const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [toggleViewPeriode, setToggleViewPeriode] = React.useState<boolean>(true);

  const _handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const _handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList;      setSectionList(section_List);
      setOptionState(`null`);
    }else{
      setSectionList(undefined);
      setOptionState(`null`);
    }
  }
  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.option
      const option = option_List[0];
      setOptionList([option_List]);
      setOptionState(`${option.id}`);
      setClasseState(`null`);

    }
  }
  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classe
      const classe = classe_List[0];
      setClasseList([classe_List]);
      setClasseState(`${classe.id}`);
    }
  }

  const getSession = async () => {
    const session = await GetSession();
    if (session?.data !== undefined && session?.data.status === 200) {
      console.log(session?.data);
      const sessionList = session?.data.sessionList[0]
      setSessionList(session?.data.sessionList);
      setSession(`${sessionList.id}`);
    }
  }

 

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
  }
  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCreatEleve = (values: any) => { }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelect(event.target.value);
  }
  const handleSelect1Change = (event: SelectChangeEvent<string>) => {
    setSelect1(event.target.value);
  }
  const handleChangeTypeDocument = (event: SelectChangeEvent<string>) => {
    setTypeDocument(event.target.value);
  }
  async function getDegre() {
    const degre = await GetDegre();
    if (degre?.data !== undefined && degre?.data.status === 200) {
      console.log(degre?.data);
      const degreList = degre?.data.degreList
      dispatch(addDegre({
        degreList: degreList,
      }));
    }
  }
  const handelChangeDegre = async (e: SelectChangeEvent<string>) => {
    setToggleViewPeriode(true);
    setDegre(e.target.value)
    const getPeriode = await GetPeriode(e.target.value);
    if (getPeriode?.data !== undefined && getPeriode?.data.status === 200) {
      console.log(getPeriode?.data);
      const epreuveList = getPeriode?.data.epreuveList;
      const firstItem = epreuveList[0]
      setPeriode(`${firstItem.id}`);
      setPeriodeList(getPeriode?.data.epreuveList);
      setToggleViewPeriode(false);

    }

  }
  const handleChangeSession = (event: SelectChangeEvent<string>) => {
    setSelect1(event.target.value);
  }

  React.useEffect(() => {
    let isMuted = true
    if (isMuted) {
      getSession();
      getDegre();
      getSection();
    }
    return () => {
      isMuted = false
    }
  }, []);
  return (
    <Grid md={12} lg={12} xs={12}>
      <Card sx={{
        background: mode === 'dark' ? '#323337' : '#fff',
        // width: '100%',
        mt: -25
      }}>
        <CardHeader title={<Typography variant='h6'>
          Impression
        </Typography>} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container spacing={2} md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label='Section'
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label='Option'
                    defaultValue='null'
                    value={optionState}
                    onChange={handelChangeOption}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Classe</InputLabel>
                  <Select
                    size='small'
                    label='Classe'
                    defaultValue='null'
                    value={classeState}
                    onChange={(e) => setClasseState(e.target.value)}
                    fullWidth
                    disabled={toggleViewClasse}
                  >
                    <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                    {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Session</InputLabel>
                  <Select
                    size='small'
                    label='Session'
                    defaultValue={session}
                    value={session}
                    onChange={(e) => setSession(e.target.value)}
                    fullWidth
                  >
                    {/* <MenuItem value={`null`}>Selectioné une session</MenuItem> */}
                    {sessionList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container spacing={2} md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>degré</InputLabel>
                  <Select
                    size='small'
                    label='Degré'
                    defaultValue={'null'}
                    value={degre}
                    onChange={handelChangeDegre}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Selectioné le degré</MenuItem>
                    {degreState.degreList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Période</InputLabel>
                  <Select
                    size='small'
                    label='Période'
                    defaultValue={'null'}
                    value={periode}
                    onChange={(e) => setPeriode(e.target.value)}
                    fullWidth
                    disabled={toggleViewPeriode}
                  >
                    <MenuItem value={`null`}>Selectioné une période</MenuItem>
                    {periodeList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type des document</InputLabel>
                  <Select
                    size='small'
                    label='Type des document'
                    defaultValue='a'
                    value={typeDocument}
                    onChange={(e) => handleChangeTypeDocument(e)}
                    fullWidth
                  >
                    <ListSubheader sx={{ color: '#00AEEF' }}>Bulletin</ListSubheader>
                    <MenuItem sx={{ fontSize: 13 }} value={1}>Bulletin Annuel</MenuItem>
                    <ListSubheader sx={{ color: '#00AEEF' }}>Fiche Centralisatrice</ListSubheader>
                    <MenuItem sx={{ fontSize: 13 }} value={2}>Fiche Centralisatrice Périodique</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value={3}>Fiche Centralisatrice Semestrielle</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value={4}>Fiche Centralisatrice Annuelle</MenuItem>
                    <ListSubheader sx={{ color: '#00AEEF' }}>Autres types de Fiche</ListSubheader>
                    <MenuItem sx={{ fontSize: 13 }} value='fiche de déliberation'>Fiche de déliberation</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value='fiche de conduite'>Fiche de conduite</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value='fiche de cotes'>Fiche de cotes</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value='fiche de tenasosp'>Fiche de TENASOSP</MenuItem>
                    <ListSubheader sx={{ color: '#00AEEF' }}>Autres types de Liste</ListSubheader>
                    <MenuItem sx={{ fontSize: 13 }} value='liste de récupération'>Liste de récupération</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value='liste de repêchage'>Liste de repêchage</MenuItem>
                    <ListSubheader sx={{ color: '#00AEEF' }}>Palmares</ListSubheader>
                    <MenuItem sx={{ fontSize: 13 }} value={5}>Palmares Périodique</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value={6}>Palmares Semestrielle</MenuItem>
                    <MenuItem sx={{ fontSize: 13 }} value={7}>Palmares Annuelle</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <ButtonStyled
                  startIcon={<LocalPrintshopIcon htmlColor='#323337' />}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'none', color: '#323337' }}
                  fullWidth
                  component='label'
                  variant='contained'
                >
                  Imprimer
                </ButtonStyled>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function EleveImprimerViewStore() {
  const { mode } = useColorScheme();
  return <EleveImprimerView mode={mode} />
}

export default EleveImprimerViewStore;