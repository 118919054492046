import React, { useEffect, useState } from "react";
import MUIDataTable, { Responsive, FilterType, MUIDataTableOptions } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";
import { GetClasseByIdOption, GetEleves, GetElevesByIdClasse, GetOptionByIdSection, GetSection } from "../../../services/api";
import { Box, Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});

export type dataType = {
  n:number;
  id: number;
  etatCivil: string;
  nom: string;
  postnom: string;
  prenom: string;
  sexe: string;
  classe: string;
  lieuDeNaissance: string;
  dateDeNaissance: string;
  nationalite: string;
  photo: string;
  telephone: string;
  email: string;
}

function ElevesList({ handleClickRow, ElevesList, rowClicked }: any) {

  const [open, setOpen] = React.useState(false);
  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [tableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight] = useState("");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();
  // const [rowClicked, setRowClicked] = useState<any[]>([]);

  useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      const eleve = ElevesList[0];
      // setRowClicked([eleve?.n, eleve?.nom, eleve?.postnom, eleve?.prenom]);
    }
    return () => {
      isMuted = false;
    }
  }, []);

  const columns = [
    {
      name: "n", label: 'N°',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "nom", label: 'Nom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "postnom", label: 'Postnom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "prenom", label: 'Prénom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "sexe", label: 'Sexe',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
  ];

  const handleClose = () => setOpen(false);
  const handleRowClick = (rowData: any, rowMeta: any) => {
    handleClickRow(rowMeta);
    // setRowClicked(rowData);
  };

  const options: MUIDataTableOptions = {
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: true,
    filterType,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onRowClick: handleRowClick,
    setRowProps: (row: any[], dataIndex: number, rowIndex: number) => {
      if (row[0] === rowClicked[0] && row[1] === rowClicked[1]
        && row[2] === rowClicked[2] && row[3] === rowClicked[3]) {
        return {
          style: {
            background: "#F5F5F5",
            boxShadow: '5px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }
        }
      } else {
        return {}
      }


    },
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important',
              cursor: "pointer"
            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },

        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
            '&:hover': {
              backgroundColor: '#fff'
            }
          },
        },
      },
    },
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme()}>
        
        <MUIDataTable
          title={"Liste des élèves"}
          data={ElevesList}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default ElevesList;