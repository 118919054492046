import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert, Box, CardHeader, FormControl, InputLabel, MenuItem, Snackbar, SnackbarOrigin } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Button, { ButtonProps } from '@mui/material/Button'
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useAppSelector } from '../../../services/store/hooks';
import { selectAnneeScolaire } from '../../../services/store/annee/annee.scolaire.slice';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import { State } from '../../auth/auth.agent.view';
import { CreatTranferEleve, GetClasseByIdOption, GetOptionByIdSection, GetSection, InscriptionEleveUpdate } from '../../../services/api';
import { GetSession } from '../../../services/api/sessionApi';
import BackdropWithPortal from '../../../components/global/backdrop';

interface FeaturedPostProps {
  allElevesSelected: {
    id: number,
    row: number
  }[] | undefined;
  mode: any,
  handleTranfertsSuccess: any
}

export default function EleveTranfertCard(props: FeaturedPostProps) {
  const { allElevesSelected, mode, handleTranfertsSuccess } = props;
  console.log(allElevesSelected);

  const anneeScolaire: any = useAppSelector(selectAnneeScolaire);
  const AnneeEncours = anneeScolaire.anneeScolaireList[0];
  const [annee, setAnnee] = React.useState<string>(`${AnneeEncours.id}`);
  const [sessionList, setSessionList] = React.useState<{ id: number, nom: string }[]>([]);
  const [session, setSession] = React.useState<string>('null');
  const Auth: any = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  })
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [toggleViewSection, setToggleViewSection] = React.useState<boolean>(true);

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      const section = section_List[0];
      setSectionList(section_List);
      setOptionState(`null`);
      setToggleViewSection(false);
    } else {
      setSectionList(undefined);
      setOptionState(`null`);
      setToggleViewSection(true);
    }
  }

  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      setClasseState(`null`);
    } else {
      setOptionList(undefined);
      setClasseState(`null`);
      setToggleViewOption(true);
    }
  }

  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
    } else {
      setClasseList([]);
      setClasseState('null');
      setToggleViewClasse(true);
    }
  }

  const getSession = async () => {
    const session: any = await GetSession();
    if (session?.data !== undefined && session?.data.status === 200) {
      console.log(session?.data);
      const sessionList = session?.data.sessionList[0]
      setSessionList(session?.data.sessionList);
      setSession(`${sessionList.id}`);
    }
  }

  React.useEffect(() => {
    let isMuted = true
    if (isMuted) {
      getSession();
      getSection();
    }
    return () => {
      isMuted = false
    }
  }, []);

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handleTranferEleve = async () => {
    if (annee === '0') {
      setRes({
        type: 'error',
        msg: "Veillez choisire l'année scolaire."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else if (classeState === 'null') {
      setRes({
        type: 'error',
        msg: "Veillez choisire une classe."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else if (allElevesSelected === undefined) {
      setRes({
        type: 'error',
        msg: "Veillez selectionné au moins un élève."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else {
      setOpenBackdrop(true);
      const listEleveInscription: {
        eleve: number,
        classe: number,
        anneeScolaire: number
      }[] = []
      for (let e = 0; e < allElevesSelected.length; e++) {
        const item = allElevesSelected[e];
        listEleveInscription.push({
          eleve: item.id,
          classe: parseInt(classeState),
          anneeScolaire: parseInt(annee),
        })
      }
      const creatTranferEleve = await InscriptionEleveUpdate(listEleveInscription);
      if (creatTranferEleve?.data !== undefined && creatTranferEleve?.data.status === 201) {
        setStateSuccess({
          openSuccess: true, ...{
            vertical: 'top',
            horizontal: 'center',
          }
        });
        setRes({
          type: 'success',
          msg: creatTranferEleve?.data.message
        });
        setTimeout(() => {
          setStateSuccess({
            openSuccess: false, ...{
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }, 3000);
        handleTranfertsSuccess();
      } else {
        console.log(creatTranferEleve?.data);
        setRes({
          type: 'error',
          msg: creatTranferEleve?.data.message
        });
        handleClick({
          vertical: 'top',
          horizontal: 'center',
        })
        setTimeout(() => {
          setState({ open: true, ...{
            vertical: 'top',
            horizontal: 'center',
          } })
          
        }, 3000);
      }
      setOpenBackdrop(false);
    }
  }

  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: 720
        }}
      >
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e: any) => {
              e.preventDefault();
              handleTranferEleve();
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Année scolaire</InputLabel>
                  <Select
                    size='small'
                    label='Année scolaire'
                    defaultValue={annee}
                    value={annee}
                    onChange={(e) => setAnnee(e.target.value)}
                    fullWidth
                  >
                    {anneeScolaire.anneeScolaireList.map((item: { id: number, annee: string }) =>
                      <MenuItem value={`${item.id}`}>{item.annee}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label='Section'
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label='Option'
                    defaultValue='null'
                    value={optionState}
                    onChange={handelChangeOption}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Classe</InputLabel>
                  <Select
                    size='small'
                    label='Classe'
                    defaultValue='null'
                    value={classeState}
                    onChange={(e) => setClasseState(e.target.value)}
                    fullWidth
                    disabled={toggleViewClasse}
                  >
                    <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                    {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                  variant="contained"
                  startIcon={<MoveDownIcon htmlColor='#323337' />}
                >
                  Transférer
                </Button>
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                  variant="contained"
                  startIcon={<MoveDownIcon htmlColor='#323337' />}
                >
                  Transférer
                </Button>
              </Grid> */}
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'dkkfrii'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

