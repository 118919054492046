import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { dataCoursType } from '../DataTable/listcours';
import { cours } from '../DataTable/data';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CardHeader from '@material-ui/core/CardHeader';
import { dataProfType } from '../DataTable/listEnseignant';

interface CoursPostProps {
  changedCoursInCard: any,
  coursList: dataCoursType[],
  cours: dataCoursType | undefined
  setCours: any
  mode: any
}
export default function CoursDetailCard(props: CoursPostProps) {
  const { changedCoursInCard, cours, coursList, setCours, mode } = props;
  const [open, setOpen] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState<string>(cours?.tutilaire ? cours?.tutilaire.photo : '/images/avatars/9.jpg');
  const [index, setIndex] = useState<number>(0)
  const totalEleve = coursList.length - 1;
  const [mouseInCard, setMouseInCard] = useState<boolean>(false);

  const handleChangeItemPrev = () => {
    setCours(coursList[index - 1]);
    setIndex(index - 1)
    changedCoursInCard(coursList[index - 1]);
  };

  const handleChangeItemNext = () => {
    setCours(coursList[index + 1]);
    setIndex(index + 1)
    changedCoursInCard(coursList[index + 1]);

  };

  const onChange = (file: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = file.target as HTMLInputElement
    if (files && files.length !== 0) {
      reader.onload = () => {
        setImgSrc(reader.result as string)
      }
      reader.readAsDataURL(files[0]);
    }
  }
  const getImage = () => {
    if (cours?.tutilaire?.photo === undefined) {
      return '/images/avatars/9.jpg'
    } else if (cours?.tutilaire?.photo === null) {
      return '/images/avatars/9.jpg'
    } else if (cours?.tutilaire?.photo === "") {
      return '/images/avatars/9.jpg'
    } else {
      return cours?.tutilaire?.photo
    }
  }
  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: { xs: 'auto', md: 550 },
        }}
        onMouseOver={() => {
          setMouseInCard(true)
        }}
        onMouseOut={() => {
          setMouseInCard(false)
        }}
      >
        <CardHeader title={<Typography variant='h6'>
          Cours
        </Typography>} />
        <CardMedia
          component="img"
          sx={{
            width: 200,
            height: 200,
            margin: 'auto',
            mt: 5,
            borderRadius: 25,
          }}
          image={getImage()}
          alt={'profile'}
        />
        <CardContent>
          {cours ? <Grid container spacing={1} >
            <Grid item xs={1}>
              <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                {index > 0 ? <>{mouseInCard ? <IconButton
                  sx={{
                    background: mode !== "dark" ? '#fff' : '#323337',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleChangeItemPrev()}
                  size="large"
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton> : null} </> : null}

              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Box sx={{
                pb: 1
              }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {cours?.tutilaire?.prenom} {cours?.tutilaire?.nom} {cours?.tutilaire?.postnom}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{
                pt: 1,
                height: 150,
                overflowY: 'scroll',
              }}>
                <Typography variant="h6">Cour</Typography>
                <Typography variant="subtitle1" >
                  Intutilé: {cours?.nom}
                </Typography>
                <Typography variant="subtitle1" >
                  Maxima: {cours?.maximum}
                </Typography>
                <Typography variant="subtitle1" >
                  Sous-domaine: {cours?.sousDomaine.nom}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                {totalEleve !== index ? <>{mouseInCard ? <IconButton
                  sx={{
                    background: mode !== "dark" ? '#fff' : '#323337',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleChangeItemNext()}
                  size="large"
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton> : null}</> : null}
              </Stack>
            </Grid>
          </Grid> : <Typography variant="subtitle1" textAlign={'center'} >
            Aucun cours sélectionner
          </Typography>}

        </CardContent>
      </Card>
    </Grid>
  );
}

