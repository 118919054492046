import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import { Grid, Paper } from '@mui/material';
import { EleveSteppers } from './eleve';
import { CoursSteppers } from './cours';
import { CotationSteppers } from './cotation';
import OptionsList from '../optionsList';
import { EnseignantSteppers } from './enseignants';
import { DirectionSteppers } from './organiserEcole';
import { OptionSteppers } from './option';
import { ClasseSteppers } from './classe';
import { ImpressionSteppers } from './impression';
import { GestionRolesSteppers } from './GestionRoles';

const bounceAnimation = keyframes`${bounce}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export const checkCurrentLink = (link:string) => {
  const pathname = window.location.pathname.substring(15);
  const position = pathname.indexOf('-');
  if(position !== -1){
    const path = pathname.substring(position+1);
    console.log(path);
    
    const option = OptionsList.find(item => item.name === link);
    const steps = option?.options
    const index = steps?.findIndex(item => item.root === path);
    console.log(index);
    if(index === undefined || index == -1) return -1
    else return (index)
  }else{
    return -1
  }
}

const OptionMenu = ({Link,mode}:any) => {

  const handelMenuChoise = () => {
    switch (Link) {
      case 'Elève':
       return <EleveSteppers mode={mode}/>
      case 'Cours':
        return <CoursSteppers mode={mode}/>
      case 'Cotation':
        return <CotationSteppers mode={mode}/>
      case 'Agent':
        return <EnseignantSteppers mode={mode}/>
      case `Organiser l'école`:
        return <DirectionSteppers mode={mode}/>
      // case 'Gestion des rôles':
      //   return <GestionRolesSteppers mode={mode}/>
      case 'Option':
        return <OptionSteppers mode={mode}/>
      case 'Classe':
        return <ClasseSteppers mode={mode}/>
      // case 'Impression':
      //   return <ImpressionSteppers mode={mode}/>
      default:
        return null
    }
  }
  return (
    <>
      {handelMenuChoise()}
    </>
  )
}

export default OptionMenu;

