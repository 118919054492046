import * as React from 'react';
import { Card, CardContent, CardHeader, Grid, FormControl, InputLabel, MenuItem, Stack, Typography, SnackbarOrigin, Snackbar, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button'
import { eleves } from '../DataTable/data';
import { dataType } from '../DataTable/transfertEleve';
import { CreateCours, GetOptionByIdSection, GetSection, GetSubDomaine, GetClasseByIdOption } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';
import { useAppSelector } from '../../../services/store/hooks';
import { selectAuth } from '../../../services/store/auth/auth.slice';

interface Props {
  mode: Mode | undefined
}


function CoursEnregistreView({ mode }: Props) {
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [maxima, setMaxima] = React.useState<string>('5');
  const [sousDomaine, setSousDomaine] = React.useState<string>('1');
  const [sousDomaineList, setSousDomaineList] = React.useState<{ id: number, nom: string }[]>([]);
  const MaximaList: number[] = [];
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  })
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [domaineState, setDomaineState] = React.useState('null');
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [toggleViewSousDomaine, setToggleViewSousDomaine] = React.useState<boolean>(true);

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }
  const handleCreateCours = async (value: { nom: string }) => {
    setOpenBackdrop(true);
    if (sectionState === 'null' && optionState === 'null' && classeState === 'null') {
      setRes({
        type: 'error',
        msg: "la section , l'option ou la classe n'a pas était sélectioné"
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else {
      const createCours = await CreateCours({
        nom: value.nom,
        maximum: parseInt(maxima),
        classe: parseInt(classeState),
        sousDomaineCours: parseInt(sousDomaine),
        utilisateur: user.id,
      });
      setOpenBackdrop(false);
      if (createCours?.data !== undefined && createCours?.data.status === 201) {
        console.log(createCours?.data);
        setStateSuccess({
          openSuccess: true, ...{
            vertical: 'top',
            horizontal: 'center',
          }
        });
        setRes({
          type: 'success',
          msg: createCours?.data.message
        });
        setTimeout(() => {
          setStateSuccess({
            openSuccess: false, ...{
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }, 3000);
        values.nom = '';
        setMaxima('5');
      } else {
        console.log("error", createCours);
        if (createCours?.data === undefined)
          setRes({
            type: 'error',
            msg: 'une erreur est survenue lors du traitement de la requête'
          });
        else {
          setRes({
            type: 'error',
            msg: createCours?.data.message
          });
        }
        handleClick({
          vertical: 'top',
          horizontal: 'center',
        })
      }
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList;
      setSectionList(section_List);
      setOptionState(`null`);
    } else {
      setSectionList(undefined);
      setOptionState(`null`);
    }
  }
  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      // setOptionState(`${option.id}`);
    } else {
      setToggleViewOption(true)
      setToggleViewClasse(true)
      setOptionList([]);
      setOptionState(`null`);
      setClasseState(`null`);
    }
  }
  const getClasse = async (id: number) => {
    const get_classe_by_option: any = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
    } else {
      setToggleViewClasse(true);
      setClasseList([]);
      setClasseState('null');
    }
  }

  async function getSubDomaine() {
    const sous_Domaine = await GetSubDomaine();
    if (sous_Domaine?.data !== undefined && sous_Domaine?.data.status === 200) {
      console.log(sous_Domaine?.data);
      const sous_DomaineList = sous_Domaine?.data.sousDomaineCoursList;
      const firstItem = sous_DomaineList[0];
      setSousDomaine(`${firstItem.id}`);
      setSousDomaineList(sous_DomaineList);
    }
  }

  React.useState(() => {
    var isMuted = true;
    if (isMuted) {
      getSubDomaine();
      getSection();
    }
    return () => {
      isMuted = false;
    }
  })

  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      nom: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreateCours(values)
    }
  });

  const generatedMaxima = () => {
    for (let i = 5; i <= 300; i += 5) {
      MaximaList.push(i)
    }
  }
  generatedMaxima();
  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handelChangeDomaineCours = async () => {
    setToggleViewSousDomaine(true);
    
    setToggleViewSousDomaine(false);
  }

  return (<Grid md={12} lg={12} xs={12}>
    <Card sx={{
      background: mode === 'dark' ? '#323337' : '#fff',
    }}>
      <CardContent sx={{ height: "100%" }}>
        <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}

          >
            <Grid container spacing={1}>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Domaines de cours</InputLabel>
                    <Select
                      size='small'
                      label='Domaine de cours'
                      defaultValue='null'
                      value={domaineState}
                      onChange={handelChangeDomaineCours}
                      fullWidth
                    >
                      <MenuItem value={`null`}>Sélectionnez le domaine de cours</MenuItem>
                      {classeList?.map((item, i) => <MenuItem key={i} value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Sous-domaine</InputLabel>
                    <Select
                      size='small'
                      label='Sous-domaine'
                      defaultValue={sousDomaine}
                      value={sousDomaine}
                      onChange={(e) => setSousDomaine(e.target.value)}
                      fullWidth
                      disabled={toggleViewSousDomaine}
                    >
                      {sousDomaineList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Maxima</InputLabel>
                    <Select
                      size='small'
                      label='Maxima'
                      defaultValue='1'
                      value={maxima}
                      onChange={(e) => setMaxima(e.target.value)}
                      fullWidth
                    >
                      {MaximaList.map((item: number) => <MenuItem value={`${item}`}>{item}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    size='small'
                    label="Cours"
                    id="nom"
                    value={values.nom}
                    onChange={handleChange}
                    fullWidth
                    error={errors.nom === 'Required' ? true : false}
                    helperText={errors.nom === 'Required' ? "le nom du cours est obligatoire." : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6} spacing={1}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Section</InputLabel>
                    <Select
                      size='small'
                      label='Section'
                      defaultValue='null'
                      value={sectionState}
                      onChange={handelChangeSection}
                      fullWidth
                    >
                      <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                      {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Option</InputLabel>
                    <Select
                      size='small'
                      label='Option'
                      defaultValue='null'
                      value={optionState}
                      onChange={handelChangeOption}
                      fullWidth
                      disabled={toggleViewOption}
                    >
                      <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                      {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Classe</InputLabel>
                    <Select
                      size='small'
                      label='Classe'
                      defaultValue='null'
                      value={classeState}
                      onChange={(e) => setClasseState(e.target.value)}
                      fullWidth
                      disabled={toggleViewClasse}
                    >
                      <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                      {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    fullWidth
                    type={'submit'}
                    sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                    variant="contained"
                    startIcon={<SaveIcon htmlColor='#323337' />}
                  >
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </CardContent>
    </Card>
    <Snackbar
      open={open}
      onClose={handleClose}
      key={vertical + 'left'}
    >
      <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
    </Snackbar>
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openSuccess}
      onClose={handleCloseSuccess}
      key={vertical + horizontal}
    >
      <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
    </Snackbar>
    <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
  </Grid>
  );
}

function CoursEnregistreViewStore() {
  const { mode } = useColorScheme();
  return <CoursEnregistreView mode={mode} />
}

export default CoursEnregistreViewStore;
