import * as React from 'react';
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { State } from '../../auth/auth.agent.view';

interface Props {
  mode: Mode | undefined
}

function DocumentationIndexView({ mode }: Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleCreatEleve = (values: any) => { }


  return (
    <Grid md={12} lg={12} xs={12} sx={{mt:{xs: -20, md: -10}}}>
      <Grid container spacing={0.5}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant='h6' sx={{textAlign:'center'}}>
           <a href="/DocumentationRooTech_V1.pdf">Documentation</a> 
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}


function DocumentationIndexViewStore() {
  const { mode } = useColorScheme();
  return <DocumentationIndexView mode={mode} />
}
export default DocumentationIndexViewStore;
