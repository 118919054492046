import * as React from 'react';
import { styled as StyLed } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

//icon 
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import HubIcon from '@mui/icons-material/Hub';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StyleIcon from '@mui/icons-material/Style';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import OptionsList from '../optionsList';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { checkCurrentLink } from '.';


const bounceAnimation = keyframes`${bounce}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;
const ColorlibConnector = StyLed(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = StyLed('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <HubIcon htmlColor='#323337'/>,
    2: <StyleIcon htmlColor='#323337'/>,
    3: <AutoAwesomeMotionIcon htmlColor='#323337'/>,
    4: <EarbudsIcon htmlColor='#323337'/>,
    5: <LocalPrintshopIcon htmlColor='#323337'/>,
    6: <ListAltIcon htmlColor='#323337'/>,
  };

  return (
    <ColorlibStepIconRoot sx={{cursor:"pointer"}} ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const option = OptionsList.find(item => item.name === `Organiser l'école`);
const steps = option?.options

export function DirectionSteppers({mode}:any) {
  const [stateStep, setStateStep] = React.useState<number>(checkCurrentLink(`Organiser l'école`));
  const Navigate = useNavigate();
  const goTo = (i:number,root:string) => {
    setStateStep(i);
    Navigate(root);
  }

  return (
    <BouncyDiv>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Paper
             square
             elevation={0}
             sx={{
               display: 'flex',
               alignItems: 'center',
               p: 1,
               background: mode === 'dark'?'#323337':'#fff',
               color: mode === 'dark'?'#fff':'#323337',
             }}
           >
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel activeStep={stateStep} connector={<ColorlibConnector />}>
                {steps?.map((label,i:number) => {
                    return(
                      <Step key={label.name+i} sx={{cursor:"pointer"}} onClick={() => {
                        goTo(i,`/etablissement/OrganiserEcole${`-`+label.root}`)
                      }}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label.name}</StepLabel>
                      </Step>
                    )
                  }
                )}
              </Stepper>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </BouncyDiv>   
  );
}
