import React, { useEffect, useState } from "react";
import MUIDataTable, { Responsive,FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";
import { GetCours } from "../../../services/api";
import { dataProfType } from "./listEnseignant";

const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});
export type dataCoursType = {
  id:  number
  n: number
  nom: string
  maximum: number
  sousDomaine: {
    domaineCours: number
    id: number
    nom: string
  },
  nomSousDomaine: string
  tutilaire: dataProfType | undefined
}


const ListCoursDataTable = ({handleClickRow, coursList, rowClicked}:any) => { 

  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [tableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight] = useState("");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();

  const columns = [
    { name: "n",label:'N°',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "nom",label:'Nom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "maximum",label:'Maximum',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "nomSousDomaine",label:'Sous-domaine',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
  ];
  
  const handleRowClick = (rowData:any, rowMeta:any) => {
    handleClickRow(rowMeta);
  };

  const options = {
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: filterBtn,
    filterType,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onRowClick: handleRowClick,
    setRowProps: (row: any[], dataIndex: number, rowIndex: number) => {
      if (row[0] === rowClicked[0] && row[1] === rowClicked[1] 
        && row[2] === rowClicked[2] && row[3] === rowClicked[3] ){
        return {
          style: {
            background: "#F5F5F5",
            boxShadow:'5px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }
        }
      }else {
        return {}
      }
    }
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides:{
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important'
              cursor:"pointer"

            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
          
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
    },
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider  theme={getMuiTheme()}>
        <MUIDataTable
            title={"Liste des cours"}
            data={coursList}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
    </CacheProvider> 
  );
}

export default ListCoursDataTable;

