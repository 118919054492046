import * as React from 'react';
import { Alert, Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import Button, { ButtonProps } from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import { CreateOption, GetSection } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';
interface Props {
  mode : Mode | undefined
}

function EnregistreOptionView({mode}:Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type:'',
    msg:'',
  })
  const { vertical, horizontal, open } = state;
  const {openSuccess} = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number,nom: string}[]>();
  const [sectionState, setSectionState] = React.useState('1');
  const [onFocused, setOnFocused] = React.useState<boolean>(false);
  
  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false});
  }

  const handleCreateOption = async (value:{nom: string}) => {
    setOpenBackdrop(true);
    const createSection = await CreateOption({
      nom: value.nom,
      section: parseInt(sectionState),
    });
    setOpenBackdrop(false);
    if(createSection?.data !== undefined && createSection?.data.status === 201){
      console.log(createSection?.data);
      setStateSuccess({ openSuccess: true, ...{vertical: 'top',
      horizontal: 'center',} });
      setRes({
        type: 'success',
        msg: createSection?.data.message
      });
      setTimeout(() => {
        setStateSuccess({ openSuccess: false, ...{vertical: 'top',
        horizontal: 'center'} });
        setOnFocused(true)
      }, 3000);
      values.nom = '';
    }else{
      console.log("error",createSection);
      setRes({
        type: 'error',
        msg: 'une erreur est survenue lors du traitement de la requête'
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      const section = section_List[0];
      setSectionList(section_List);
      setSectionState(`${section.id}`)
    }
  }
  
  React.useState(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false
    }
  });

  const {handleChange, values, handleSubmit, errors} = useFormik({ 
    initialValues: {
      nom: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreateOption(values)
    }
  });

  return (
    <Grid md={12} lg={12} xs={12}>
      <Card sx={{
          background:mode === 'dark'?'#323337':'#fff',
          width: '100%',
          mt:{xs:-27,md:-6,sm:-6,lg:-6}
        }}>
        <CardContent>
          <Box 
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                  error={errors.nom === 'Required' ? true : false}
                  helperText={errors.nom === 'Required' ? "le nom de l'option est obligatoire." : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select 
                    size='small'
                    label={`Section`}
                    defaultValue='1'
                    value={sectionState}
                    onChange={(e) => setSectionState(e.target.value)}
                    fullWidth
                  >
                    {sectionList?.map((item) =><MenuItem key={item.id} value={item.id}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} />
              <Grid item xs={4}>
                <Button 
                  fullWidth
                  type={'submit'}
                  sx={{background:'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',textTransform:'capitalize',color:'#323337'}}
                  variant="contained" 
                  startIcon={<SaveIcon htmlColor='#323337'/>}
                >
                  Enregistrer
                </Button>
              </Grid>
              <Grid item md={4} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
     
        <Snackbar
          open={open}
          onClose={handleClose}
          key={vertical + 'left'}
        >
          <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSuccess}
          onClose={handleCloseSuccess}
          key={vertical + horizontal}
        >
          <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
        </Snackbar>
      
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop}/>
    </Grid>
  );
}

function EnregistreOptionViewStore(){
  const { mode } = useColorScheme();
  return <EnregistreOptionView mode={mode}/>
}
  
export default EnregistreOptionViewStore;