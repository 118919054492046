import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OptionsList from './optionsList';
import { useNavigate } from "react-router-dom";
import "../../../../assets/styles/authentification.css"

const checkCurrentLinkOption = () => {
  const pathname = window.location.pathname.substring(15);
  const position = pathname.indexOf('-');
  if(position !== -1){
    const path = pathname.substring(0,position);
    if(path === 'Eleve')
      return 'Elève'
    else if (path === 'OrganiserEcole')
      return `Organiser l'école`
    else if (path === 'GestionRoles')
      return `Gestion des rôles`
    else
      return path
  }else{
    return 'Elève'
  }
}
const selectedIndexOptionsList = () => {
  console.log(checkCurrentLinkOption())
  return OptionsList.findIndex(item => item.name === checkCurrentLinkOption())
}

export default function TemporaryDrawer({ChangeLinkOPtion,currentTheme}: any) {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(selectedIndexOptionsList());
  const Navigate = useNavigate();
  
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    link: string
  ) => {
    setSelectedIndex(index);
    ChangeLinkOPtion(link);
    const option = OptionsList.find(item => item.name === link);
    const Link = option?.options;
    let root: string = ''
    Link?.map((item:any,i:number) => {
      if(i === 0)
      root = item.root
    })
    console.log(`${link}-${root}`);
    if(link === 'Elève')
      Navigate(`Eleve`);
    else if (link === `Organiser l'école`)
      Navigate(`OrganiserEcole-${root}`);
    else if (link === `Gestion des rôles`)
      Navigate(`GestionRoles-${root}`);
    else
      Navigate(`${link}-${root}`);
  };
  console.log('mode drawer',currentTheme);
  
  const list = () => (
    <Box style={{width: 270, height:'100%', 
      background: currentTheme === 'dark' ? '#323337': '#fff',
      color: currentTheme === 'dark' ? '#fff': '#323337',
      border: 'none'
    }}>
      <List>
        <ListItem>
          <ListItemButton sx={{
             alignItems:"center",
            justifyContent:'center', 
            alignContent:'center',
          }}>
            <ListItemIcon>
              <img alt="rootech" src="/rootech.png" width={100}/>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
      
      <List>
        {OptionsList.map((item, index) => {
          return(
            <ListItem key={index}>
              <ListItemButton
                // selected={selectedIndex === index}
                onClick={(event) => {item.name === 'Documentation'? document.location = '/DocumentationRooTech_V1.pdf'
                 :
                  handleListItemClick(event, index, item.name)
                }
                }
                sx={{
                  '&:hover':{
                    background:'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                    boxShadow: 5
                  },
                  background: selectedIndex === index ? 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)' : null,
                  boxShadow: selectedIndex === index ? 5 : null
                }}
              >
                <ListItemIcon sx={{
                  color:currentTheme === 'dark' ? '#fff': '#323337',
                }}>
                  <item.icon/>
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={'left'}
      variant="permanent"
      sx={{
        border:"none"
      }}
    >
      {list()}
    </Drawer>
  );
}
