//Store Import
import { store } from '../store';
import { baseURL } from ".";
import axios, { AxiosError, AxiosResponse } from "axios";


// const api = useAxios();


export async function HttpRequest(
  path: string ,
  method: string,
  data?: any,
  params?: any
) {
  
  const Store = store.getState();
  const Auth = Store.auth;
  console.log( `${baseURL}${path}`);
  const request = {
    url:`${baseURL}${path}`,
    method,
    headers:{
      Authorization:`${Auth.compteUtilisateur.Authorization}`,
      CompteUtilisateur: `${Auth.compteUtilisateur.nomUtilisateur}`
    },
    params,
    data
  }      
  return await axios(request).then(r => {
      console.log('then', r);
      return r;
  }).catch((error: AxiosError ) => {
    console.log('catch', error.response);
    return error.response
  })
}
