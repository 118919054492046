import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert, Box, FormControl, InputLabel, MenuItem, Snackbar, SnackbarOrigin, Stack } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useAppSelector } from '../../../services/store/hooks';
import { GetPeriode } from '../../../services/api/periodeApi';
import { GetDegre } from '../../../services/api/degreApi';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import { State } from '../../auth/auth.agent.view';
import { CreatConduitEleve, GetClasseByIdOption, GetOptionByIdSection, GetSection } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';
import { dataType } from '../DataTable';

interface FeaturedPostProps {
  eleve: {
    n: number
    id: number
    nom: string
    postnom: string
    prenom: string
    sexe: string
    classe: string
    lieuDeNaissance: string
    dateDeNaissance: string
    nationalite: string
    photo: string
    telephone: string
    email: string
    etatCivil: string
  } | undefined;
  classeSelected: (id: string) => void,
  mode: any,
  setEleves: any
  index: any
  setIndex: any
  elevesList: dataType[] | undefined,
}


export default function CoterConduiteEleveCard(props: FeaturedPostProps) {
  const { eleve, mode, classeSelected, index, setIndex, elevesList, setEleves } = props;
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  });
 
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>([]);
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>([]);
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>([]);
  const [classeState, setClasseState] = React.useState('null');
  const [degreState, setDegreState] = React.useState<string>(`null`);
  const [degreList, setDegreList] = React.useState<{ id: number, nom: string }[]>();
  const [periodeState, setPeriodeState] = React.useState<string>('null');
  const [periodeList, setPeriodeList] = React.useState<{ id: number, nom: string }[]>([])
  const [cote, setCote] = React.useState<string>('')
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [toggleViewPeriode, setToggleViewPeriode] = React.useState<boolean>(true);
  const [coteAutoFocus, setcoteAutoFocus] = React.useState<boolean>(true)
  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  }
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }
  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }
  const handleClose = () => {
    setState({ ...state, open: false });
  }
  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      const section_List = get_section?.data.sectionList
      setSectionList(section_List);
      setOptionState(`null`);
    }
  }
  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
    } else {
      setOptionList([]);
      setOptionState(`null`);
      setToggleViewOption(true);

    }
  }
  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
      classeSelected(`${classe.id}`)
    } else {
      setToggleViewClasse(true);
      setClasseList([]);
      setClasseState('null');
      classeSelected('0')

    }
  }
  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
  }
  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }
  const handelChangeClasse = (e: SelectChangeEvent<string>) => {
    classeSelected(e.target.value);
    setClasseState(e.target.value);
  }
  async function getDegre() {
    const degre = await GetDegre();
    if (degre?.data !== undefined && degre?.data.status === 200) {
      console.log(degre?.data);
      const degreList = degre?.data.degreList
      setDegreList(degreList);
    }
  }
  const handelChangeDegre = async (e: SelectChangeEvent) => {
    setToggleViewPeriode(true);
    setDegreState(e.target.value)
    const getPeriode = await GetPeriode(e.target.value);
    if (getPeriode?.data !== undefined && getPeriode?.data.status === 200) {
      console.log(getPeriode?.data);
      const epreuveList = getPeriode?.data.epreuveList;
      const firstItem = epreuveList[0]
      setPeriodeState(`${firstItem.id}`);
      setPeriodeList(getPeriode?.data.epreuveList);
      setToggleViewPeriode(false);

    }

  }

  const handleCreatConduiteEleve = async () => {
    if (classeState === 'null') {
      setRes({
        type: 'error',
        msg: "Veillez choisire une classe."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else if (periodeState === 'null') {
      setRes({
        type: 'error',
        msg: "Veillez choisire une période."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else if (eleve === undefined) {
      setRes({
        type: 'error',
        msg: "Veillez selectionné au moins un élève."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else if (cote === '') {
      setRes({
        type: 'error',
        msg: "Le cote ne dois pas être vide."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    } else {
      setOpenBackdrop(true);
      const creatConduitEleve = await CreatConduitEleve({
        cote: cote,
        eleve: eleve.id,
        epreuve: parseInt(periodeState),
      });
      setOpenBackdrop(false);
      if (creatConduitEleve?.data !== undefined && creatConduitEleve?.data.status === 201) {
        setStateSuccess({
          openSuccess: true, ...{
            vertical: 'top',
            horizontal: 'center',
          }
        });
        setRes({
          type: 'success',
          msg: creatConduitEleve?.data.message
        });
        setTimeout(() => {
          setStateSuccess({
            openSuccess: false, ...{
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }, 3000);
        if (index !== undefined && elevesList !== undefined) {
          console.log(index);
          if (elevesList[index + 1] !== undefined) {
            setEleves(elevesList[index + 1]);
            setIndex(index + 1);
          }
          
          setCote('');
          setcoteAutoFocus(true);
        }
      } else {
        console.log(creatConduitEleve);
        setRes({
          type: 'error',
          msg: creatConduitEleve?.data?.message
        });
        handleClick({
          vertical: 'top',
          horizontal: 'center',
        })
      }
    }
  }

  React.useEffect(() => {
    let isMuted = true
    if (isMuted) {
      getSection();
      getDegre();
    }
    return () => {
      isMuted = false
    }
  }, []);

  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: 600
        }}
      >
        <CardContent sx={{ height: "100%" }}>
          <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={(e: any) => {
                e.preventDefault();
                handleCreatConduiteEleve()
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Section</InputLabel>
                    <Select
                      size='small'
                      label='Section'
                      defaultValue='null'
                      value={sectionState}
                      onChange={handelChangeSection}
                      fullWidth
                    >
                      <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                      {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Option</InputLabel>
                    <Select
                      size='small'
                      label='Option'
                      defaultValue='null'
                      value={optionState}
                      onChange={handelChangeOption}
                      fullWidth
                      disabled={toggleViewOption}
                    >
                      <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                      {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Classe</InputLabel>
                    <Select
                      size='small'
                      label='Classe'
                      defaultValue='null'
                      value={classeState}
                      onChange={handelChangeClasse}
                      fullWidth
                      disabled={toggleViewClasse}
                    >
                      <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                      {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>degré</InputLabel>
                    <Select
                      size='small'
                      label='Degré'
                      defaultValue={'null'}
                      value={degreState}
                      onChange={handelChangeDegre}
                      fullWidth
                    >
                      <MenuItem value={`null`}>Selectioné le degré</MenuItem>
                      {degreList?.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Période</InputLabel>
                    <Select
                      size='small'
                      label='Période'
                      defaultValue={'null'}
                      value={periodeState}
                      onChange={(e) => setPeriodeState(e.target.value)}
                      fullWidth
                      disabled={toggleViewPeriode}
                    >
                      <MenuItem value={`null`}>Selectioné une période</MenuItem>
                      {periodeList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    size='small'
                    label="Cote"
                    id="text"
                    value={cote}
                    onChange={(e) => setCote(e.target.value)}
                    fullWidth
                    autoFocus={coteAutoFocus}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type={'submit'}
                    sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                    variant="contained"
                    startIcon={<BorderColorIcon htmlColor='#323337' />}
                    onClick={() => console.log('ddd')}
                  >
                    Coter
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'dkkfrii'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

