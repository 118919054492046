import "../../../assets/styles/authentification.css"
import {useColorScheme, getInitColorSchemeScript} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {ReactNode, useEffect} from "react";
import ResponsiveAppBar from "./header/ResponsiveAppBar";

interface Props {
    children: ReactNode,
    currentTheme?: any 
}
const GlobalLayout = ({children}: Props) => {
  const { mode, setMode } = useColorScheme();
  const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: ${mode})`);
    
  // useEffect(()=> {
  //   // setMode(prefersDarkMode ? 'dark' : 'light');
  // }, [prefersDarkMode,setMode]);
    
  return (
    <>
      {getInitColorSchemeScript()}
      <main className={"main-" + mode}>
        <ResponsiveAppBar
          currentTheme={mode}
          setMode={setMode}
         />
          {children}
      </main>
      <div className={"circle circle-" + mode + " circle1"}></div>
      <div className={"circle circle-" + mode + " circle2"}></div>
      <div className={"circle circle-" + mode + " circle3"}></div>
      <div className={"circle circle-" + mode + " circle4"}></div>
      <div className={"circle circle-" + mode + " circle5"}></div>
      <div className={"circle circle-" + mode + " circle6"}></div>
    </>
  );
} 

export default GlobalLayout