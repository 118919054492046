import * as React from 'react';
import { Alert, Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import Button, { ButtonProps } from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import SaveIcon from '@mui/icons-material/Save';
import { CreateClasse, GetOptionByIdSection, GetSection } from '../../../services/api';
import BackdropWithPortal from '../../../components/global/backdrop';

interface Props {
  mode: Mode | undefined
}

function EnregistreClasseView({ mode }: Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  })
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [onFocused, setOnFocused] = React.useState<boolean>(false);
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const handleCreateClasse = async (value: { nom: string }) => {
    setOpenBackdrop(true);
    const createOption = await CreateClasse({
      nom: value.nom,
      option: parseInt(optionState),
    });
    setOpenBackdrop(false);
    if (createOption?.data !== undefined && createOption?.data.status === 201) {
      console.log(createOption?.data);
      setStateSuccess({
        openSuccess: true, ...{
          vertical: 'top',
          horizontal: 'center',
        }
      });
      setRes({
        type: 'success',
        msg: createOption?.data.message
      });
      setTimeout(() => {
        setStateSuccess({
          openSuccess: false, ...{
            vertical: 'top',
            horizontal: 'center'
          }
        });
        setOnFocused(true)
      }, 3000);
      values.nom = '';
      // setOptionState('null');
      // setSectionState('null');
    } else {
      console.log("error", createOption);
      setRes({
        type: 'error',
        msg: 'une erreur est survenue lors du traitement de la requête'
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      const section = section_List[0];
      setSectionList(section_List);
      // setSectionState(`${section.id}`)
    }
  }
  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      // setOptionState(`${option_List.id}`);
    }else{
      setOptionList([]);
      setToggleViewOption(true)
    }
  }

  React.useState(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false
    }
  });

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      nom: "",

    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreateClasse(values)
    }
  });

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value)
    getOption(parseInt(e.target.value))
    setToggleViewOption(false);
  }

  return (
    <Grid md={12} lg={12} xs={12}>
      <Card sx={{
        background: mode === 'dark' ? '#323337' : '#fff',
        width: '100%',
        mt: { xs: -27, md: -6, sm: -6, lg: -6 }
      }}>
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label={`Section`}
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                  >
                    <MenuItem value={'null'}>Selection la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem key={`${item.id}`} value={item.id}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label={`Option`}
                    defaultValue='null'
                    value={optionState}
                    onChange={(e) => setOptionState(e.target.value)}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={'null'}>Selection l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem key={`${item.id}`} value={item.id}>{item.nom}</MenuItem>)}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{
                    background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337',
                    '&:hover': {
                      background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                    }
                  }}
                  variant="contained"
                  startIcon={<SaveIcon htmlColor='#323337' />}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={open}
        onClose={handleClose}
        key={vertical + 'left'}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>

      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

function EnregistreClasseViewStore() {
  const { mode } = useColorScheme();
  return <EnregistreClasseView mode={mode} />
}

export default EnregistreClasseViewStore;