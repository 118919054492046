import { store } from "../store";
import { HttpRequest } from "./httpRequest"

export const baseURL = `https://root.rootech-drc.com/`;
// export const baseURL = `http://192.168.1.101/`;
// export const baseURL = `http://192.168.1.100/`;
// export const baseURL = `http://10.160.0.172/`;
// export const baseURL = `http://localhost/`;
export * from './AuthApi'
export const GetRoleUsers = async () => await HttpRequest(`rolesutilisateurs/`, 'GET');
export const GetSubDomaine = async () => await HttpRequest(`sousdomainescours/`, 'GET');
export const CreateSection = async (values: { nom: string, ecole: number }) => await HttpRequest(`section/`, 'POST', values);
export const GetSection = async () => {
    const state = store.getState();
    const auth = state.auth
    const compteUtilisateur = auth.compteUtilisateur;
    return await HttpRequest(`sectionsparecole/${compteUtilisateur.ecole.id}`, 'GET');
}
export const CreateOption = async (values: { nom: string, section: number}) => await HttpRequest(`option/`, 'POST', values);
export const CreateClasse = async (values: { nom: string, option: number}): Promise<any> => await HttpRequest(`classe/`, 'POST', values);
export const GetClasseById = async (id: string) => await HttpRequest(`classe/${id}`, 'GET');
export const GetOptionByIdSection = async (id: number) => await HttpRequest(`optionsparsection/${id}`, 'GET');
export const CreateCours = async (values: { nom: string, maximum: number, classe: number, sousDomaineCours: number, utilisateur: number }) => await HttpRequest(`cours/`, 'POST', values);
export const GetClasseByIdOption = async (id: number) => await HttpRequest(`classesparoption/${id}`, 'GET');
export const CreateEleve = async (values: any) => await HttpRequest(`eleve`, 'POST', values);
export const GetEleves = async () => await HttpRequest(`eleves/`, 'GET');
export const GetElevesByIdClasse = async (id: string) => {
    const state = store.getState();
    const annee = state.anneeScolaireEncours
    return await HttpRequest(`inscriptions/${id}/${annee.id}`, 'GET');
}
export const GetUsers:any = async () => {
    const state = store.getState();
    const auth = state.auth
    const compteUtilisateur = auth.compteUtilisateur;
    return  await HttpRequest(`utilisateursparecole/${compteUtilisateur.ecole.id}`, 'GET')
};
export const CreateUser = async (values: any) =>await HttpRequest(`utilisateur/`, 'POST', values);
export const GetCours = async () => await HttpRequest(`cours/`, 'GET');
export const GetCoursByIdClasse = async (id: string) => await HttpRequest(`coursparclasse/${id}`,'GET');
export const InscriptionEleve = async (values: { anneeScolaire: number,eleve: number,classe: number }) => {
    console.log(values);
   return await HttpRequest(`inscription/`, 'POST', values);
}
export const CreatTranferEleve = async (data:{eleve: {id: number}[],class: number,anneeScolaire: number}) => await HttpRequest(`url`,'POST',data);
export const CreatConduitEleve = async (data:{cote: string,eleve: number,epreuve: number}) => await HttpRequest(`coteconduite/`,'POST',data);
export const InscriptionEleveUpdate = async (values: { anneeScolaire: number,eleve: number,classe: number }[]) => {return await HttpRequest(`inscription/`, 'PUT', values);}
export const InscriptionEleveUpdatePatch = async (values: { anneeScolaire: number,eleve: number,classe: number }[]) => {return await HttpRequest(`inscription/`, 'PATCH', values);}
export const getTutilaireCours = async (id: string) => await HttpRequest(`utilisateurduncours/${id}`, 'GET');
export const TransfertTutilaireCours = async (data:{cours: number, utilisateur: number}) => await HttpRequest(`coursutilisateur/`, 'POST', data);
export const CreatCoteEleve = async (data:{
    cote: string,
    eleve: number,
    epreuve: number,
    cours: number,
    session: number
}) => await HttpRequest(`cotecours/`,'POST',data);
