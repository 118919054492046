import * as React from 'react';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import ListCoursDataTable, { dataCoursType } from '../DataTable/listcours';
import CoursPost from '../FeaturedPost/coursPost';
import { cours } from '../DataTable/data';
import { GetClasseByIdOption, GetCours, GetCoursByIdClasse, GetOptionByIdSection, GetSection, getTutilaireCours } from '../../../services/api';
interface Props {
  mode: Mode | undefined
}

function CoursListesView({ mode }: Props) {
  const [coursList, setCoursList] = React.useState<dataCoursType[]>([]);
  const [currentCours, setCurrentCours] = React.useState<dataCoursType | undefined>(coursList[0]);
  const [rowClicked, setRowClicked] = React.useState<any[]>([0, '', '', '']);
  const [sectionState, setSectionState] = React.useState('null');
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewSection, setToggleViewSection] = React.useState<boolean>(true);
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);

  const getCours = async (id: string) => {
    const get_cours = await GetCoursByIdClasse(id);
    if (get_cours?.data !== undefined && get_cours?.data.status === 200) {
      console.log(get_cours?.data);
      const cours_List: dataCoursType[] = [];
      for (let i = 0; i < get_cours?.data.coursList.length; i++) {
        let Cours = get_cours?.data.coursList[i];
        const getTutilaire = await getTutilaireCours(`${Cours.id}`);
        if (getTutilaire?.data !== undefined && getTutilaire?.data.status === 200) {
          const tutilaire = getTutilaire?.data?.coursUtilisateur?.utilisateur
          console.log(tutilaire);
          tutilaire.n = tutilaire.id
          Cours.tutilaire = tutilaire
        } else {
          Cours.tutilaire = undefined
        }
        Cours.n = i + 1;
        Cours.nomSousDomaine = Cours.sousDomaine.nom
        cours_List.push(Cours);
      }
      const cours = cours_List[0]
      setCoursList(cours_List);
      setRowClicked([cours.n, cours.nom, cours.maximum, cours.nomSousDomaine]);
      setCurrentCours(cours);

    }else{
      setCoursList([]);
      setRowClicked([]);
      setCurrentCours(undefined);

    }
  }

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      const section = section_List[0];
      setSectionList(section_List);
      setOptionState(`null`);
      setToggleViewSection(false);
    } else {
      setSectionList(undefined);
      setOptionState(`null`);
      setToggleViewSection(true);
    }
  }

  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      setClasseState(`null`);
    } else {
      setOptionList(undefined);
      setClasseState(`null`);
      setToggleViewOption(true);
    }
  }

  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
      getCours(`${classe.id}`);
    } else {
      setClasseList([]);
      setClasseState('null');
      getCours(`0`);
      setToggleViewClasse(true);
    }
  }

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
    getCours(`0`);
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handelChangeClasse = async (e: SelectChangeEvent<string>) => {
    setClasseState(e.target.value);
    console.log('change classe');
    await getCours(e.target.value);
  }

  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false;
    }
  }, []);

  const handleClickRow = (rowMeta: { dataIndex: number, rowIndex: number }) => {
    const cours = coursList[rowMeta.rowIndex]
    setCurrentCours(cours);
    console.log(cours);
    setRowClicked([cours.n, cours.nom, cours.maximum, cours.nomSousDomaine])
  }

  return (
    <Grid container md={12} lg={12} xs={12}>
      <Grid item md={12} lg={12} xs={12} sm={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label='Section'
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label='Option'
                    defaultValue='null'
                    value={optionState}
                    onChange={handelChangeOption}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Classe</InputLabel>
                  <Select
                    size='small'
                    label='Classe'
                    defaultValue='null'
                    value={classeState}
                    onChange={handelChangeClasse}
                    fullWidth
                    disabled={toggleViewClasse}
                  >
                    <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                    {classeList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container xs={12} md={12} lg={12} sx={{ pt: 0.5 }}>
        <Grid item md={8} lg={8} xs={12} sm={8}  pr={0.5}>
          <ListCoursDataTable handleClickRow={handleClickRow} coursList={coursList} rowClicked={rowClicked} />
        </Grid>
        <Grid item md={4} lg={4} xs={12} sm={4}>
          <CoursPost cours={currentCours} mode={mode} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function CoursListesViewStore() {
  const { mode } = useColorScheme();
  return <CoursListesView mode={mode} />
}

export default CoursListesViewStore;