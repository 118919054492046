import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { AppState } from '..';

export interface State {
  periodeList: [
    {
      id: number
      nom: string
    },

  ]
}

export interface Action {
}

const initialState: State = {
    periodeList: [
    {
      id: 0,
      nom: ""
    },

  ]
}


export const periodeSlice = createSlice({
  name: 'periode',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addPeriode: (state, action: PayloadAction<State>) => {
      state.periodeList = action.payload.periodeList
    },
  },
})

export const { addPeriode } = periodeSlice.actions

export const selectPeriode = (state: AppState) => state.periode

export default periodeSlice.reducer

export const persistConfigperiode = {
  key: 'periodeRootLevel3',
  version: 1,
  storage
}
