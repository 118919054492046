import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Avatar, Box, CardHeader, FormControl, IconButton, InputLabel, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import GradingIcon from '@mui/icons-material/Grading';
import Divider from '@mui/material/Divider';
import { dataCoursType } from '../DataTable/listcours';
import { dataProfType } from '../DataTable/listEnseignant';

interface CoursPostProps {
  cours: dataCoursType | undefined
  mode: any
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  width: { xs: '100%', md: 500 },
};

export default function CoursPost(props: CoursPostProps) {
  const { cours, mode } = props;
  const [open, setOpen] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState<string>(cours?.tutilaire ? cours?.tutilaire.photo : '/images/avatars/9.jpg');
  const [eleveData, setEleveData] = React.useState(cours);
  const handleClose = () => setOpen(false);
  console.log(cours);

  const handleOpen = () => {
    setEleveData(cours);
    // setImgSrc(cours.tutilaire.photo)
    // setOpen(true)
    // console.log(values);
    // values.tutilaire.id = cours.tutilaire.id
    // values.tutilaire.nom = cours.tutilaire.nom
    // values.tutilaire.postnom = cours.tutilaire.postnom
    // values.tutilaire.prenom = cours.tutilaire.prenom
    // values.tutilaire.email = cours.tutilaire.email
    // values.tutilaire.telephone = cours.tutilaire.telephone
  };

  const onChange = (file: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = file.target as HTMLInputElement
    if (files && files.length !== 0) {
      reader.onload = () => {
        // setImgSrc(reader.result as string)
      }
      reader.readAsDataURL(files[0]);
    }
  }

  const handleCreatEleve = (values: any) => { }

  const getProfile = () => {
    if (cours?.tutilaire?.photo === undefined) {
      return '/images/avatars/9.jpg'
    } else if (cours?.tutilaire?.photo === null) {
      return '/images/avatars/9.jpg'
    } else if (cours?.tutilaire?.photo === "") {
      return '/images/avatars/9.jpg'
    } else {
      return cours?.tutilaire?.photo
    }
  }

  return (
    <Grid item>
      <CardActionArea component="div" onClick={handleOpen}>
        <Card
          sx={{
            background: mode === 'dark' ? '#323337' : '#fff',
            pl: 3,
            pr: 3,
            height: { xs: 'auto', md: 720 },
          }}
        >
          <CardHeader title={<Typography variant='h6'>
            Tutilaire
          </Typography>} />
          <CardMedia
            component="img"
            sx={{
              width: 200,
              height: 200,
              margin: 'auto',
              borderRadius: 25,
            }}
            image={getProfile()}
            alt={'profile'}
          />
          <CardContent>
            {cours ? <>
              <Box sx={{
                pb: 2
              }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  {cours?.tutilaire?.prenom} {cours?.tutilaire?.nom} {cours?.tutilaire?.postnom}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{
                pt: 2
              }}>
                <Typography variant="h6">Cours</Typography>
                <Typography variant="subtitle1" >
                  Intutilé : {cours?.nom}
                </Typography>
                <Typography variant="subtitle1" >
                  Maxima : {cours?.maximum}
                </Typography>
                <Typography variant="subtitle1" >
                  Sous-domaine : {cours?.sousDomaine.nom}
                </Typography>
              </Box>
            </>
              : <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                Aucun cours n'a était sélectionner
              </Typography>
            }

          </CardContent>
        </Card>
      </CardActionArea>
      {GetModal({
        post: eleveData,
        open,
        handleClose,
      })}
    </Grid>
  );
}

const GetModal = ({
  post,
  open,
  handleClose,
}: any) => {


  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        {/* <Card >
          <CardHeader
            avatar={
              <Avatar alt='profile img' src={imgSrc} />
            }
            action={
              <IconButton aria-label="settings" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            }
            title="Modification"
            subheader={`${post?.tutilaire.prenom} ${post?.tutilaire.nom} ${post?.tutilaire.postnom}`}
          />
          <CardContent sx={{ P: 15 }}>
            <Grid container spacing={2} md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Tutilaire</InputLabel>
                  <Select
                    size='small'
                    label='Tutilaire'
                    defaultValue='m'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='m'>{`${post?.tutilaire.prenom} ${post?.tutilaire.nom} ${post?.tutilaire.postnom}`}</MenuItem>
                    <MenuItem value='m'>jean paul</MenuItem>
                    <MenuItem value='f'>malira exauce</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  size='small'
                  label="Cours"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Maxima</InputLabel>
                  <Select
                    size='small'
                    label='Maxima'
                    defaultValue='m'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='m'>10</MenuItem>
                    <MenuItem value='f'>20</MenuItem>
                    <MenuItem value='f'>30</MenuItem>
                    <MenuItem value='f'>40</MenuItem>
                    <MenuItem value='f'>50</MenuItem>
                    <MenuItem value='f'>60</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Sous-domaine</InputLabel>
                  <Select
                    size='small'
                    label='Sous-domaine'
                    defaultValue='m'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='m'>MATH</MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              <Grid item md={12} xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                  variant="contained"
                  startIcon={<GradingIcon htmlColor='#323337' />}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card> */}
      </Box>
    </Modal>
  );
}