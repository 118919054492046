import * as React from 'react';
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import SendIcon from '@mui/icons-material/Send';
import ListEleveMsgDataTable from '../DataTable/listEleveInMsg';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';

interface Props {
  mode: Mode | undefined
}

function MessagerieIndexView({ mode }: Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleCreatEleve = (values: any) => { }

  return (
    <Grid container spacing={0.5} md={12} lg={12} xs={12} sx={{
      mt: -10
    }}>
      <Grid item md={12} lg={12} xs={12}>
        <Card sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
        }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container style={{ padding: 2 }}>
                  <Grid item xs={11}>
                    <TextareaAutosize
                      id="outlined-basic-email" aria-label="minimum height"
                      minRows={5}
                      placeholder="Message"
                      style={{ width: '100%', maxWidth: '100%' }}
                    />
                  </Grid>
                  <Grid xs={1} p={1}>
                    <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                      <IconButton sx={{
                        p: 2,
                        '&:hover': {
                          background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                        }
                      }}>
                        <SendIcon htmlColor='#323337' />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <ListEleveMsgDataTable handleClickRow={() => { }} />
      </Grid>
    </Grid>
  );
}


function MessagerieIndexViewStore() {
  const { mode } = useColorScheme();
  return <MessagerieIndexView mode={mode} />
}
export default MessagerieIndexViewStore;
