import AuthModule from '../modules/auth/auth.module';
import EtablissementModule from '../modules/etablissement';
import HomeModule from '../modules/home/home.module';

import AuthAgentView from '../views/auth/auth.agent.view';
import AuthEleveView from '../views/auth/auth.eleve.view';
import HomeDashBoardView from '../views/home/home.dashboard.view';
// Eleve  module view
import EtablissementDashboardView from '../views/etablissement/etablissement.dashboard.view';
import EleveInscriptionView from '../views/etablissement/Eleve/eleve.inscription.view';
import EleveImprimerView from '../views/etablissement/Eleve/eleve.imprimer.view';
import EleveTranfertView from '../views/etablissement/Eleve/eleve.transfert.view';
import EleveCoteConduiteView from '../views/etablissement/Eleve/eleve.coteconduite.view'
// Cours module view
import CoursListesView from '../views/etablissement/Cours/listes';
import CoursEnregistreView from '../views/etablissement/Cours/enregistrer';
import CoursCoterConduiteView from '../views/etablissement/Cours/coterCours';
// Enseignant module view
import EnseignantSommaireView from '../views/etablissement/Enseignant/enseignant.sommaire.view';
import EnseignantEnregistreView from '../views/etablissement/Enseignant/enseignant.enregistre.view';
import EnseignantAttribuerCoursView from '../views/etablissement/Enseignant/enseignant.attribuercour';
//Organiser Ecole
import EnregistreSectionView from '../views/etablissement/Organiser/organiser.enregistre.section.view'; 
import EnregistreOptionView from '../views/etablissement/Organiser/organiser.enregistre.option.view';
import EnregistreClasseView from '../views/etablissement/Organiser/organiser.enregistre.classe.view';
import EtablissementIndexView from '../views/etablissement/etablissement/etablissement.index.view';

//Messagerie
import MessagerieIndexView from '../views/etablissement/Messagerie/messagerie.index.view';

//Documentation 
import DocumentationIndexView from '../views/etablissement/Documentation/documentation.index.view';

//Aide 
import AideIndexView from '../views/etablissement/Aide';
import RolesSommaireView from '../views/etablissement/GestionRoles';
import EleveDeplacementStore from '../views/etablissement/Eleve/eleve.deplacement.view';

export type RolesType = {}

export type StoreRoutesType = {
  name: string,
  path: string,
  component?: any,
  roles?: string[],
  routes?: StoreRoutesType[],
};

export const storeRoutes: StoreRoutesType[] = [
  {
    'name': 'auth',
    'path': '/auth',
    'component': AuthModule,
    'routes':[
      {
        'name': 'authEleve',
        'path': '/authEleve',
        'component': AuthEleveView
      },
      {
        'name': 'authAgent',
        'path': '/authAgent',
        'component': AuthAgentView
      }
    ]
  },
  {
    'name': 'home',
    'path': '/',
    'component': HomeModule,
    'roles': ['ROLE_USER'],
    'routes':[
      {
        'name': 'homeDashboard',
        'path': '/homeDashboard',
        'component': HomeDashBoardView 
      }
    ]
  },
  {
    'name': 'produits',
    'path': '/',
    'component': HomeModule,
    'roles': ['ROLE_USER'],
    'routes':[
      {
        'name': 'produitsDashboard',
        'path': '/produitsDashboard',
        'component': HomeDashBoardView 
      },
      {
        'name': 'produitsDashboard',
        'path': '/produitsDashboard',
        'component': HomeDashBoardView 
      }
    ]
  },
  {
    'name': 'etablissement',
    'path': '/etablissement',
    'component': EtablissementModule,
    'roles': ['ROLE_USER'],
    'routes':[
      {
        'name': 'etablissementDashboard',
        'path': '/etablissementDashboard',
        'component': EtablissementDashboardView 
      },
      // Eleve routes
      {
        'name': 'Eleve',
        'path': '/Eleve',
        'component': EtablissementDashboardView,
      },
      {
        'name': 'Inscrire',
        'path': '/Eleve-Inscription',
        'component': EleveInscriptionView,
      },
      {
        'name': 'Impression',
        'path': '/Eleve-Impression',
        'component': EleveImprimerView,
      },
      {
        'name':'Cotelaconduite',
        'path':'/Eleve-Cotelaconduite',
        'component':EleveCoteConduiteView
      },
      {
        'name': 'Transfert',
        'path': '/Eleve-Transfert',
        'component': EleveTranfertView,
      },
      {
        'name': 'Deplacement',
        'path': '/Eleve-Deplacement',
        'component': EleveDeplacementStore,
      },
      
      //Cours
      {
        'name': 'Sommaire',
        'path': '/Cours-Sommaire',
        'component': CoursListesView,
      },
      {
        'name': 'Enregistrer',
        'path': '/Cours-Enregistrer',
        'component': CoursEnregistreView,
      },
      {
        'name': 'Attrubuer',
        'path': '/Cours-CoterConduite',
        'component': CoursCoterConduiteView,
      },
      // Enseignant
      {
        'name': 'Agent',
        'path': '/Agent-Sommaire',
        'component': EnseignantSommaireView,
      },
      {
        'name': 'Agent',
        'path': '/Agent-Enregistrer',
        'component': EnseignantEnregistreView,
      },
      {
        'name':'Agent',
        'path':'/Agent-AttribuerCours',
        'component': EnseignantAttribuerCoursView,
      },
      //Organiser l'école
      {
        'name':'OrganiserEcole',
        'path':'OrganiserEcole-EnregistreSection',
        'component': EnregistreSectionView
      },
      {
        'name':'OrganiserEcole',
        'path':'OrganiserEcole-EnregistreOption',
        'component': EnregistreOptionView
      },
      {
        'name':'OrganiserEcole',
        'path':'OrganiserEcole-EnregistreClasse',
        'component': EnregistreClasseView
      },
      //Gestion des Rôles
      {
        'name':'GestionRoles',
        'path':'GestionRoles-Sommaire',
        'component': RolesSommaireView
      },
      //Impression
      {
        'name':'Impression',
        'path': 'Impression-Index',
        'component':EleveImprimerView

      },
      //Etablissement
      {
        'name':'Etablissement',
        'path': 'Etablissement-Index',
        'component':EtablissementIndexView

      },
      //Messagerie 
      {
        'name':'Messagerie',
        'path': 'Messagerie-Index',
        'component':MessagerieIndexView
      },
      //Documentation
      {
        'name':'Documentation',
        'path': 'Documentation-Index',
        'component':DocumentationIndexView
      },
      //Documentation
      {
        'name':'Aide',
        'path': 'Aide-Index',
        'component':AideIndexView
      }
    ]
  },
];
