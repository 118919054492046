import * as React from 'react';
import { Grid } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import EnseignantDetailCard from '../FeaturedPost/enseignantDetailCard';
import TranfertCoursEnseignantCard from '../FeaturedPost/transfereCoursEnseignant';
import CoursDetailCard from '../FeaturedPost/CoursDetailCard';
import { GetCoursByIdClasse, getTutilaireCours, GetUsers } from '../../../services/api';
import { dataProfType } from '../DataTable/listEnseignant';
import { dataCoursType } from '../DataTable/listcours';

interface Props {
  mode: Mode | undefined
}

const EnseignantAttribuerCoursView = ({ mode }: Props) => {
  const [userList, setUserList] = React.useState<dataProfType[]>([]);
  const [currentUser, setCurrentUsers] = React.useState<dataProfType>();
  const [coursList, setCoursList] = React.useState<dataCoursType[]>([])
  const [currentCours, setCurrentCours] = React.useState<dataCoursType>()

  const getUsers = async () => {
    const get_users = await GetUsers();
    if (get_users?.data !== undefined && get_users?.data.status === 200) {
      console.log(get_users?.data);
      const users_List = get_users?.data.utilisateurList
      const users = users_List[0];
      setUserList(users_List);
      setCurrentUsers(users);
    } else {
      setUserList([]);
      setCurrentUsers(undefined);
    }
  }

  const getCoursByClasse = async (id: string) => {
    const get_cours = await GetCoursByIdClasse(id);
    if (get_cours?.data !== undefined && get_cours?.data.status === 200) {
      console.log(get_cours?.data);
      const cours_List: dataCoursType[] = [];
      for (let i = 0; i < get_cours?.data.coursList.length; i++) {
        let Cours = get_cours?.data.coursList[i];
        const getTutilaire = await getTutilaireCours(`${Cours.id}`);
        if (getTutilaire?.data !== undefined && getTutilaire?.data.status === 200) {
          const tutilaire = getTutilaire?.data?.coursUtilisateur?.utilisateur
          console.log(tutilaire);
          tutilaire.n = tutilaire.id
          Cours.tutilaire = tutilaire
        } else {
          Cours.tutilaire = undefined
        }
        Cours.n = i + 1;
        cours_List.push(Cours);
      }
      const cours = cours_List[0]
      console.log(cours_List);
      setCoursList(cours_List);
      setCurrentCours(cours);
    } else {
      setCoursList([]);
      setCurrentCours(undefined);
    }
  }
  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getUsers();
    }
    return () => {
      isMuted = false;
    }
  }, []);

  const changedUsersInCard = (user: dataProfType) => {
    setCurrentUsers(user);
    console.log(user);
  }
  const changedCoursInCard = (cour: dataCoursType) => {

  }
  const changeClasse = (id: string) => {
    getCoursByClasse(id)
  }
 
  return (<Grid container spacing={0.5} md={12} lg={12} xs={12}>
    <Grid item md={4} lg={4} xs={12} sm={12}>
      <EnseignantDetailCard enseignantList={userList} user={currentUser} setUser={setCurrentUsers} changedUsersInCard={changedUsersInCard} mode={mode} />
    </Grid>
    <Grid item md={4} lg={4} xs={12} sm={12}>
      <CoursDetailCard changedCoursInCard={changedCoursInCard} coursList={coursList} cours={currentCours} setCours={setCurrentCours} mode={mode} />
    </Grid>
    <Grid item md={4} lg={4} xs={12} sm={12}>
      <TranfertCoursEnseignantCard enseignant={currentUser} changeClasse={changeClasse} cours={currentCours} mode={mode} />
    </Grid>
  </Grid>
  )
}

function EnseignantAttribuerCoursStore() {
  const { mode } = useColorScheme();
  return <EnseignantAttribuerCoursView mode={mode} />
}

export default EnseignantAttribuerCoursStore;
