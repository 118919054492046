import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { AppState } from '..';

export interface State {
  compteUtilisateur: {
    Authorization: string
    actif: boolean
    dateDeCreation: string
    nomUtilisateur: string
    role: string
    ecole: {
      id: number,
      nom: string,
      logo: null | string,
      description: null | string,
      entete: null | string,
      boitePostal: null | string,
      provinceEducationnelle: number
    }
    utilisateur: {
      id: number
      dateNaissance: string
      domaineFormation: string
      email: string
      etatCivil: string
      fonction: string
      grade: string
      lieuNaissance: string
      nationalite: string
      niveauEtude: string
      nom: string
      photo: string
      postNom: string
      prenom: string
      sexe: string
      telephone: string
    }
  },
  isAuthenticated: boolean
}

export interface Action {
}

const initialState: State = {
  compteUtilisateur: {
    Authorization: '',
    actif: false,
    dateDeCreation: '',
    nomUtilisateur: '',
    role: '',
    ecole: {
      id: 0,
      nom: "",
      logo: null,
      description: null,
      entete: null,
      boitePostal: null,
      provinceEducationnelle: 1
    },
    utilisateur: {
      id: 0,
      dateNaissance: '',
      domaineFormation: '',
      email: '',
      etatCivil: '',
      fonction: '',
      grade: '',
      lieuNaissance: '',
      nationalite: '',
      niveauEtude: '',
      nom: '',
      photo: '',
      postNom: '',
      prenom: '',
      sexe: '',
      telephone: ''
    }
  },
  isAuthenticated: false
}


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loginUser: (state, action: PayloadAction<State>) => {
      state.compteUtilisateur = action.payload.compteUtilisateur;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    logoutUser: (state) => {
      state.compteUtilisateur = {
        Authorization: '',
        actif: false,
        dateDeCreation: '',
        nomUtilisateur: '',
        role: '',
        ecole: {
          id: 1,
          nom: '',
          logo: null,
          description: null,
          entete: null,
          boitePostal: null,
          provinceEducationnelle: 1
        },
        utilisateur: {
          id: 0,
          dateNaissance: '',
          domaineFormation: '',
          email: '',
          etatCivil: '',
          fonction: '',
          grade: '',
          lieuNaissance: '',
          nationalite: '',
          niveauEtude: '',
          nom: '',
          photo: '',
          postNom: '',
          prenom: '',
          sexe: '',
          telephone: ''
        }
      };
      state.isAuthenticated = false
    },
  },
})

export const { loginUser, logoutUser } = authSlice.actions

export const selectAuth = (state: AppState) => state.auth

export default authSlice.reducer

export const persistConfigAuth = {
  key: 'authRootLevel3',
  version: 1,
  storage
}
