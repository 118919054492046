export const eleves = [
  {
    n:1,
    id: 517,
    etatCivil: "célibataire",
    nom: "Cruz",
    postnom:'kasawa',
    prenom: "Ortiz",
    sexe: "m",
    classe: "5eme",
    lieuDeNaissance: "Neahkahnie",
    dateDeNaissance: "Mon Dec 19 2005 03:34:53 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "North Dakota",
    photo: "/images/eleve/img8.jpg",
    telephone: "+ 234 (868) 409-2680",
    email: "cruzortiz@medalert.com",
    code_identification: 381
  },
  {
    n:1,
    id: 666,
    etatCivil: "célibataire",
    nom: "Marva",
    postnom:'kasawa',
    prenom: "Townsend",
    sexe: "f",
    classe: "5éme",
    lieuDeNaissance: "Eagletown",
    dateDeNaissance: "Fri Oct 06 1995 17:07:30 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Marshall Islands",
    photo: "/images/eleve/img1.jpg",
    telephone: "+ 234 (891) 424-2725",
    email: "marvatownsend@medalert.com",
    code_identification: 658
  },
  {
    n:1,
    id: 240,
    etatCivil: "célibataire",
    nom: "Greer",
    postnom:'kasawa',
    prenom: "Roberts",
    sexe: "m",
    classe: "2éme",
    lieuDeNaissance: "Keller",
    dateDeNaissance: "Thu Sep 30 1976 02:06:35 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Palau",
    photo: "/images/eleve/img2.jpg",
    telephone: "+ 234 (966) 456-2816",
    email: "greerroberts@medalert.com",
    code_identification: 654
  },
  {
    n:1,
    id: 340,
    etatCivil: "célibataire",
    nom: "Wood",
    postnom:'kasawa',
    prenom: "Craig",
    sexe: "m",
    classe: "4éme",
    lieuDeNaissance: "Utting",
    dateDeNaissance: "Wed Jan 09 2002 23:59:09 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Mississippi",
    photo: "/images/eleve/img3.jpg",
    telephone: "+ 234 (907) 576-2273",
    email: "woodcraig@medalert.com",
    code_identification: 618
  },
  {
    n:1,
    id: 886,
    etatCivil: "célibataire",
    nom: "Baldwin",
    postnom:'kasawa',
    prenom: "Holcomb",
    sexe: "m",
    classe: "6éme",
    lieuDeNaissance: "Tooleville",
    dateDeNaissance: "Mon Jan 10 2005 14:04:17 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Louisiana",
    photo: "/images/eleve/img4.jpg",
    telephone: "+ 234 (951) 486-3436",
    email: "baldwinholcomb@medalert.com",
    code_identification: 919
  },
  {
    n:1,
    id: 599,
    etatCivil: "célibataire",
    nom: "Mercedes",
    postnom:'kasawa',
    prenom: "Coleman",
    sexe: "f",
    classe: "5éme",
    lieuDeNaissance: "Harrison",
    dateDeNaissance: "Sat Dec 31 2011 01:55:42 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Alabama",
    photo: "/images/eleve/img5.jpg",
    telephone: "+ 234 (857) 550-2936",
    email: "mercedescoleman@medalert.com",
    code_identification: 473
  },
  {
    n:1,
    id: 202,
    etatCivil: "célibataire",
    nom: "Mildred",
    postnom:'kasawa',
    prenom: "Merritt",
    sexe: "f",
    classe: "3eme",
    lieuDeNaissance: "Dundee",
    dateDeNaissance: "Fri Jan 07 1994 15:42:49 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "West Virginia",
    photo: "/images/eleve/img6.jpg",
    telephone: "+ 234 (992) 451-2662",
    email: "mildredmerritt@medalert.com",
    code_identification: 248
  },
  {
    n:1,
    id: 679,
    etatCivil: "célibataire",
    nom: "Patel",
    postnom:'kasawa',
    prenom: "Stephens",
    sexe: "m",
    classe: "6eme",
    lieuDeNaissance: "Osage",
    dateDeNaissance: "Wed Nov 20 1974 08:09:27 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Tennessee",
    photo: "/images/eleve/img9.jpg",
    telephone: "+ 234 (933) 565-2512",
    email: "patelstephens@medalert.com",
    code_identification: 560
  },
  {
    n:1,
    id: 679,
    etatCivil: "célibataire",
    nom: "Patel",
    postnom:'kasawa',
    prenom: "Stephens",
    sexe: "f",
    classe: "6eme",
    lieuDeNaissance: "Osage",
    dateDeNaissance: "Wed Nov 20 1974 08:09:27 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Tennessee",
    photo: "/images/eleve/img8.jpg",
    telephone: "+ 234 (933) 565-2512",
    email: "patelstephens@medalert.com",
    code_identification: 560
  },
  {
    n:1,
    id: 679,
    etatCivil: "célibataire",
    nom: "Patel",
    postnom:'kasawa',
    prenom: "Stephens",
    sexe: "f",
    classe: "6eme",
    lieuDeNaissance: "Osage",
    dateDeNaissance: "Wed Nov 20 1974 08:09:27 GMT+0100 (heure normale d’Afrique de l’Ouest)",
    nationalite: "Tennessee",
    photo: "/images/eleve/img9.jpg",
    telephone: "+ 234 (933) 565-2512",
    email: "patelstephens@medalert.com",
    code_identification: 560
  }
]

export const cours = [
  {
    id:  1,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  1,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  2,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  2,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  3,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  3,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  4,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  4,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  4,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  4,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  5,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  5,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  6,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  6,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  7,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  7,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  8,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  8,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  9,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  9,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img8.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  },
  {
    id:  10,
    nom: 'anglais',
    classe: '5eme',
    domaine: 'string',
    maxima:50,
    tutilaire: {
      id:  10,
      nom: 'jean',
      postnom: 'rob',
      prenom:  'anto',
      photo:  '/images/eleve/img5.jpg',
      telephone: '08569847855',
      email: 'jeanrob@gmail.com',
    }
  }
]