import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { dataType } from '../DataTable/ElevesList';
import { eleves } from '../DataTable/data';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import { Box } from '@mui/material';
import { dataProfType } from '../DataTable/listEnseignant';

interface EnseignantDetailCardProps {
  changedUsersInCard: any,
  enseignantList: dataProfType[],
  user: dataProfType | undefined
  setUser: any
  mode: any
}

export default function EnseignantDetailCard(props: EnseignantDetailCardProps) {

  const { changedUsersInCard, enseignantList, setUser, user, mode } = props;
  const [index, setIndex] = useState<number>(0)
  const totalEleve = enseignantList.length - 1;
  const [mouseInCard, setMouseInCard] = useState<boolean>(false);

  const handleChangeItemPrev = () => {
    let User = enseignantList[index - 1];
    setUser(User);
    setIndex(index - 1)
    changedUsersInCard(User)

  };

  const handleChangeItemNext = () => {
    setUser(enseignantList[index + 1]);
    setIndex(index + 1)
    changedUsersInCard(enseignantList[index + 1])
  };

  const getImage = () => {
    if (user?.photo === undefined) {
      return '/images/avatars/9.jpg'
    } else if (user?.photo === null) {
      return '/images/avatars/9.jpg'
    } else if (user?.photo === "") {
      return '/images/avatars/9.jpg'
    } else {
      return user?.photo
    }
  }
  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: { xs: 'auto', md: 550 },
        }}
        onMouseOver={() => {
          setMouseInCard(true)
        }}
        onMouseOut={() => {
          setMouseInCard(false)
        }}
      >
        <CardHeader title={<Typography variant='h6'>
          Agent
        </Typography>} />
        <CardMedia
          component="img"
          sx={{
            width: 200,
            height: 200,
            margin: 'auto',
            mt: 5,
            borderRadius: 25,
          }}
          image={getImage()}
          alt={user?.photo}
        />
        <CardContent>
          {user ?
            <Grid container spacing={1} >
              <Grid item xs={1}>
                <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                  {index > 0 ? <>{mouseInCard ? <IconButton
                    sx={{
                      background: mode !== "dark" ? '#fff' : '#323337',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => handleChangeItemPrev()}
                    size="large"
                  >
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton> : null} </> : null}
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                  <Box>
                    <Typography variant="subtitle1">
                      Nom: {user?.nom}
                    </Typography>
                    <Typography variant="subtitle1">
                      Post-nom: {user?.postnom}
                    </Typography>
                    <Typography variant="subtitle1">
                      Prénom: {user?.prenom}
                    </Typography>
                    <Typography variant="subtitle1">
                      Sexe: {user?.sexe}
                    </Typography>
                    <Typography variant="subtitle1">
                      Tel: {user?.telephone}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                  {totalEleve !== index ? <>{mouseInCard ? <IconButton
                    sx={{
                      background: mode !== "dark" ? '#fff' : '#323337',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => handleChangeItemNext()}
                    size="large"
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton> : null}</> : null}
                </Stack>
              </Grid>
            </Grid> : <Typography variant="subtitle1" textAlign={'center'} >
              Aucun agent n'a était enregistrer
            </Typography>}
        </CardContent>
      </Card>
    </Grid>
  );
}
