import GlobalLayout from "../../components/layout/global";
import {Outlet} from "react-router-dom";

const HomeModule = () => {

  return <GlobalLayout>
    <Outlet />
  </GlobalLayout>
}

export default HomeModule;
