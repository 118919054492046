import * as React from 'react';
import { Grid } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { eleves } from '../DataTable/data';
import EleveCoursPost from '../FeaturedPost/eleveCoursPost';
import { dataType } from '../DataTable/ElevesList';
import CoursCouterCard from '../FeaturedPost/CoursCoterCard';
import { GetElevesByIdClasse } from '../../../services/api';
interface Props {
  mode: Mode | undefined
}

function CoursCoterConduiteView({ mode }: Props) {
  const [elevesList, setElevesList] = React.useState<dataType[] | undefined>();
  const [eleve, setEleves] = React.useState<dataType | undefined>();
  const [currentEleve, setCurrentEleve] = React.useState<dataType | undefined>();
  const [index, setIndex] = React.useState<number>(0)
  const changedEleveInCard = (rowMeta: dataType) => {

  }
  
  const getEleves = async (id: string) => {
    const res: any = await GetElevesByIdClasse(id);
    if (res?.data !== undefined && res?.data.status === 200) {
      const eleveList: dataType[] = [];
      for (let i = 0; i < res?.data.eleveList.length; i++) {
        let Eleve = res?.data.eleveList[i];
        Eleve.n = i + 1;
        eleveList.push(Eleve);
      }
      const firstEleve: dataType = eleveList[0];
      setElevesList(eleveList);
      setEleves(firstEleve)
      setCurrentEleve(firstEleve);
    }else{
      setElevesList([]);
      setEleves(undefined);
      setCurrentEleve(undefined);
    }
  }
 
  const classeSelected = (id: string) => {
    getEleves(id);
    setIndex(0)
  }
  return (<Grid container xs={12} md={12} lg={12} spacing={0.5}>
    <Grid item md={6} lg={6} xs={12} sm={6}>
      <EleveCoursPost elevesList={elevesList} eleve={eleve} setEleves={setEleves} index={index} setIndex={setIndex} changedEleveInCard={changedEleveInCard} mode={mode} />
    </Grid>
    <Grid item md={6} lg={6} xs={12} sm={6}>
      <CoursCouterCard classeSelected={classeSelected} mode={mode} elevesList={elevesList} setEleves={setEleves} index={index} setIndex={setIndex} currentEleve={eleve}/>
    </Grid>
  </Grid>
  );
}

function CoursCoterConduiteViewStore() {
  const { mode } = useColorScheme();
  return <CoursCoterConduiteView mode={mode} />
}

export default CoursCoterConduiteViewStore;