import * as React from 'react';
import { Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { dataType } from "../DataTable";
import TranfertEleveDataTable from '../DataTable/transfertEleve';
import EleveTranfertCard from './eleve.card.transfert.view';
import { GetClasseByIdOption, GetElevesByIdClasse, GetOptionByIdSection, GetSection } from '../../../services/api';

interface Props {
  mode: Mode | undefined
}

const EleveTranfertView = ({ mode }: Props) => {
  const [sectionState, setSectionState] = React.useState('null');
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewSection, setToggleViewSection] = React.useState<boolean>(true);
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [elevesList, setElevesList] = React.useState<dataType[]>([]);
  const [allRowsSelected, setAllRowsSelected] = React.useState<{ id: number, row: number }[] | undefined>();
  const [rowsSelected, setRowsSelected] = React.useState<any[]>([]);
  const getEleves = async (id: string) => {
    const get_eleves = await GetElevesByIdClasse(id);
    if (get_eleves?.data !== undefined && get_eleves?.data.status === 200) {
      const eleveList: dataType[] = [];
      for (let i = 0; i < get_eleves?.data.eleveList.length; i++) {
        let Eleve = get_eleves?.data.eleveList[i];
        Eleve.n = i + 1;
        eleveList.push(Eleve);
      }
      setElevesList(eleveList);
    }else{
      setElevesList([]);
    }
  }

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      setSectionList(section_List);
      setOptionState(`null`);
    }else{
      setSectionList(undefined);
      setOptionState(`null`);
      setToggleViewSection(true)
      setToggleViewOption(false);
    }
  }

  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      setOptionList(option_List);
      setClasseState(`null`);
    }else{
      setOptionList(undefined);
      setClasseState(`null`);
      setToggleViewOption(true);
    }
  }

  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
      getEleves(`${classe.id}`);
    } else {
      setClasseList([]);
      setClasseState('null');
      getEleves(`0`);
      setToggleViewClasse(true);
    }
  }
  
  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
    getEleves(`0`);
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handelChangeClasse = async (e: SelectChangeEvent<string>) => {
    setClasseState(e.target.value);
    console.log('change classe');
    getEleves(e.target.value);
  }
  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false;
    }
  }, []);
  const handleSelectedRow = (rowMeta: [{ index: number, dataIndex: number }]) => {
    const eleveSeleted : {id: number, row: number}[] = [];
    for (let i = 0; i < rowMeta.length; i++) {
      const e = rowMeta[i];
      const eleve = elevesList.find((item: dataType ,index: number) => index === e.index);
      if (eleve) eleveSeleted.push({id: eleve.id, row: e.index + 1});
    }
    setAllRowsSelected(eleveSeleted);
  }

  const handleTranfertsSuccess = () => {
    setRowsSelected([])
    getEleves(`${classeState}`);
  }

  return (<Grid container md={12} lg={12} xs={12}>
    <Grid item md={12} lg={12} xs={12} sm={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Section</InputLabel>
                <Select
                  size='small'
                  label='Section'
                  defaultValue='null'
                  value={sectionState}
                  onChange={handelChangeSection}
                  fullWidth
                >
                  <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                  {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Option</InputLabel>
                <Select
                  size='small'
                  label='Option'
                  defaultValue='null'
                  value={optionState}
                  onChange={handelChangeOption}
                  fullWidth
                  disabled={toggleViewOption}
                >
                  <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                  {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Classe</InputLabel>
                <Select
                  size='small'
                  label='Classe'
                  defaultValue='null'
                  value={classeState}
                  onChange={handelChangeClasse}
                  fullWidth
                  disabled={toggleViewClasse}
                >
                  <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                  {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={12} lg={8} xs={12} sm={12} pr={0.5} pt={0.5}>
      <TranfertEleveDataTable rowsSelected={rowsSelected} setRowsSelected={setRowsSelected} eleveList={elevesList} handleSelectedRow={handleSelectedRow} />
    </Grid>
    <Grid item md={4} lg={4} pt={0.5}>
      <EleveTranfertCard allElevesSelected={allRowsSelected} mode={mode}  handleTranfertsSuccess={handleTranfertsSuccess}/>
    </Grid>
  </Grid>
  )

}

function EleveTranfertStore() {
  const { mode } = useColorScheme();
  return <EleveTranfertView mode={mode} />
}

export default EleveTranfertStore;
