import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import moment from 'moment';
import { dataType } from '../DataTable/ElevesList';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@material-ui/core/CardHeader';

interface EleveCoursPostProps {
  changedEleveInCard: any,
  elevesList: dataType[] | undefined,
  eleve: any,
  setEleves: any,
  index: any
  setIndex: any
  mode: any
}

export default function EleveCoursPost(props: EleveCoursPostProps) {
  const { changedEleveInCard, mode, elevesList, eleve, setEleves, index, setIndex } = props;
  const totalEleve = elevesList !== undefined ? elevesList.length - 1 : 0;
  const [mouseInCard, setMouseInCard] = useState<boolean>(false);

  const handleChangeItemPrev = () => {
    if (elevesList) {
      let Eleve = elevesList[index - 1];
      setEleves(Eleve);
      setIndex(index - 1)
      changedEleveInCard(Eleve);

    }

  };

  const handleChangeItemNext = () => {
    if (elevesList) {
      setEleves(elevesList[index + 1]);
      setIndex(index + 1);
      changedEleveInCard(elevesList[index + 1]);
    }
  };

  useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      setEleves(elevesList !== undefined ? elevesList[0] : undefined)
    }
    return () => {
      isMuted = false
    }
  }, [])
  const getImage = () => {
    if (eleve?.photo === undefined) {
      return '/images/avatars/9.jpg'
    } else if (eleve?.photo === null) {
      return '/images/avatars/9.jpg'
    } else if (eleve?.photo === "") {
      return '/images/avatars/9.jpg'
    } else {
      return eleve?.photo
    }
  }
  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: { xs: 'auto', md: 600 },
        }}
        onMouseOver={() => {
          setMouseInCard(true)
        }}
        onMouseOut={() => {
          setMouseInCard(false)
        }}
      >
        <CardHeader title={<Typography variant='h6'>
          Elève
        </Typography>} />
        <CardMedia
          component="img"
          sx={{
            width: 200,
            height: 200,
            margin: 'auto',
            borderRadius: 25,
          }}
          image={getImage()}
          alt={'profil'}
        />
        <CardContent>
          {eleve ?  <Grid container spacing={2} >
            <Grid item xs={1} spacing={0.5}>
              <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                {index > 0 ? <>{mouseInCard ? <IconButton
                  sx={{
                    background: mode !== "dark" ? '#fff' : '#323337',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleChangeItemPrev()}
                  size="large"
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton> : null} </> : null}

              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                <Box>
                  <Typography variant="subtitle1">
                    Nom: {eleve?.nom}
                  </Typography>
                  <Typography variant="subtitle1">
                    Post-nom: {eleve?.postnom}
                  </Typography>
                  <Typography variant="subtitle1">
                    Prénom: {eleve?.prenom}
                  </Typography>
                  <Typography variant="subtitle1">
                    Sexe: {eleve?.sexe}
                  </Typography>
                  <Typography variant="subtitle1">
                    Date de naissance : {eleve?.dateDeNaissance}
                  </Typography>
                  <Typography variant="subtitle1">
                    Lieux de naissance :  {eleve?.lieuDeNaissance}
                  </Typography>
                  <Typography variant="subtitle1">
                    Nationalité: {eleve?.nationalite}
                  </Typography>
                  <Typography variant="subtitle1">
                    Etat civil: {eleve?.etatCivil}
                  </Typography>
                  <Typography variant="subtitle1">
                    Tel: {eleve?.telephone}
                  </Typography>
                  <Typography variant="subtitle1">
                    email: {eleve?.email}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                {totalEleve !== index ? <>{mouseInCard ? <IconButton
                  sx={{
                    background: mode !== "dark" ? '#fff' : '#323337',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleChangeItemNext()}
                  size="large"
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton> : null}</> : null}
              </Stack>
            </Grid>
          </Grid>:  <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            Aucun eleve n'a était sélectionner
          </Typography>}
         
        </CardContent>
      </Card>
    </Grid>
  );
}
