import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid, Stack, TextareaAutosize } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ProblemeFrequent from './problemeFrequent';


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const probleme = [
  {
    probleme: 'Comment je peut modifier les information de mon compte',
    resolution:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur cumque quis, nulla consequatur nisi exercitationem nihil fuga ipsa quae accusamus delectus ipsam facere cum voluptatibus, eum perspiciatis commodi sint culpa.'
  },
  {
    probleme: 'Probleme',
    resolution:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur cumque quis, nulla consequatur nisi exercitationem nihil fuga ipsa quae accusamus delectus ipsam facere cum voluptatibus, eum perspiciatis commodi sint culpa.'
  },
  {
    probleme: 'Probleme',
    resolution:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur cumque quis, nulla consequatur nisi exercitationem nihil fuga ipsa quae accusamus delectus ipsam facere cum voluptatibus, eum perspiciatis commodi sint culpa.'
  },
]
export default function DemandeAssistance() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: '100%' , '&:hover':{cursor:'pointer'}}}>
      <CardHeader
        onClick={handleExpandClick}
        avatar={
          <SupportAgentIcon fontSize='large' />
        }
        action={
          <IconButton aria-label="settings">
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          </IconButton>
        }
        title={<Typography variant='h6'>Démandes d'assistance</Typography>}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid item xs={12}>
            <Grid container style={{ padding: 2 }}>
              <Grid item xs={11}>
                <Typography variant='h6'>Signalements</Typography>
                <TextareaAutosize
                  id="outlined-basic-email" aria-label="minimum height"
                  minRows={5}
                  placeholder="Message"
                  style={{ width: '100%', maxWidth: '100%' }}
                />
              </Grid>
              <Grid item xs={1} p={1}>
                <Stack sx={{ height: "100%" }} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                  <IconButton sx={{
                    p: 2,
                    '&:hover': {
                      background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                    }
                  }}>
                    <SendIcon htmlColor='#323337' />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>

            <Divider/>
            <Grid container spacing={0.5}>
              <Typography variant='h6'>
                Probleme fréquent
              </Typography>
              {probleme.map((item:{ probleme: string; resolution: string; }, i: number) => <Grid item xs={12}>
                <ProblemeFrequent
                  probleme={`${item.probleme}`}
                  resolution={`${item.resolution}`}
                />
              </Grid>)}
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
