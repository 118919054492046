// ** MUI Imports
import Grid from '@mui/material/Grid';

// ** Styled Component Import
import ApexChartWrapper from './react-apexcharts';

// ** Demo Components Imports
import DataTable from './dashboard/DataTable';
import StatisticsCard from './dashboard/StatisticsCard';
import WeeklyOverview from './dashboard/WeeklyOverview';
import CarouselMy from './dashboard/carousel';

const ApexChartWrapperDash = () => {
 
  return (
    <ApexChartWrapper>
      <Grid container spacing={0.5}>
        {/* <Grid item xs={12} sm={12} md={4}>
          <Trophy />
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} sx={{ml:{xs:0.5,md:0,sm:0,lg:0}}}>
          <StatisticsCard />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <CarouselMy/>
        </Grid>
        
        <Grid item xs={12} sm={12} md={8} lg={8} sx={{ml:{xs:0.5,md:0,sm:0,lg:0}}}>
          <WeeklyOverview />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} ml={0.5} display={{xs:'none',md:'block',sm:'block'}}>
          <DataTable/>
        </Grid>

      </Grid>
    </ApexChartWrapper>
  )
}

export default ApexChartWrapperDash
