import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert, Box, CardHeader, FormControl, InputLabel, MenuItem, Snackbar, SnackbarOrigin } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { GetDegre } from '../../../services/api/degreApi';
import { addDegre, selectDegre } from '../../../services/store/degre/degre.slice';
import { GetSession } from '../../../services/api/sessionApi';
import { useAppDispatch, useAppSelector } from '../../../services/store/hooks';
import { GetPeriode } from '../../../services/api/periodeApi';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import { CreatCoteEleve, GetClasseByIdOption, GetCoursByIdClasse, GetOptionByIdSection, GetSection } from '../../../services/api';
import { State } from '../../auth/auth.agent.view';
import BackdropWithPortal from '../../../components/global/backdrop';
import { dataType } from '../DataTable/ElevesList';

interface FeaturedPostProps {
  mode: any,
  classeSelected: any,
  currentEleve: any,
  elevesList: dataType[] | undefined,
  setEleves: any
  index: any
  setIndex: any
}

export default function CoursCouterCard(props: FeaturedPostProps) {
  const { mode, classeSelected, currentEleve , elevesList , setEleves, index, setIndex } = props;
  console.log(currentEleve);
  
  const dispatch = useAppDispatch();
  const degreState = useAppSelector(selectDegre);
  const [periode, setPeriode] = React.useState<string>('null');
  const [periodeList, setPeriodeList] = React.useState<{ id: number, nom: string }[]>([])
  const [degre, setDegre] = React.useState<string>(`null`);
  const [toggleViewPeriode, setToggleViewPeriode] = React.useState<boolean>(true);
  const [sessionList, setSessionList] = React.useState<{ id: number, nom: string }[]>([]);
  const [session, setSession] = React.useState<string>('null');
  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  })
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [sectionState, setSectionState] = React.useState('null');
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [coursList, setCoursList] = React.useState<{ id: number, nom: string, maximum: number, sousDomaine: string }[]>();
  const [coursState, setCoursState] = React.useState('null');
  const [currentCours, setCurrentCours] = React.useState<{ id: number, nom: string, maximum: number, sousDomaine: string }>()
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [toggleViewCours, setToggleViewCours] = React.useState<boolean>(true);
  const [cote, setCote] = React.useState<string>('');
  const [coteAutoFocus, setcoteAutoFocus] = React.useState<boolean>(true);

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList;
      setSectionList(section_List);
      setOptionState(`null`);
    } else {
      setSectionList(undefined);
      setOptionState(`null`);
    }
  }

  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      setOptionState(`null`);
    } else {
      setOptionList([]);
      setOptionState(`null`);
      setToggleViewOption(true);
      classeSelected('0');
    }
  }
  
  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
      classeSelected(`${classe.id}`);
      getCoursByClasse(`${classe.id}`)
    } else {
      setClasseList([]);
      setClasseState(`null`);
      setToggleViewClasse(true);
      classeSelected('0');
    }
  }
  
  const getCoursByClasse = async (id: string) => {
    const res: any = await GetCoursByIdClasse(id);
    if (res?.data !== undefined && res?.data.status === 200) {
      const cours_List = res?.data.coursList
      const cours = cours_List[0];
      console.log(cours_List);
      setCurrentCours(cours);
      setCoursList(cours_List);
      setCoursState(`${cours.id}`);
      setToggleViewCours(false);
    } else {
      setCoursList([]);
      setCoursState(`null`);
      setToggleViewCours(true)
    }
  }

  async function getDegre() {
    const degre = await GetDegre();
    if (degre?.data !== undefined && degre?.data.status === 200) {
      console.log(degre?.data);
      const degreList = degre?.data.degreList
      dispatch(addDegre({
        degreList: degreList,
      }));
    }
  }

  const getSession = async () => {
    const session = await GetSession();
    if (session?.data !== undefined && session?.data.status === 200) {
      console.log(session?.data);
      const sessionList = session?.data.sessionList[0]
      setSessionList(session?.data.sessionList);
      setSession(`${sessionList.id}`);
    } else {
      setSessionList([]);
      setSession(`null`);
    }
  }

  React.useEffect(() => {
    let isMuted = true
    if (isMuted) {
      getSession();
      getDegre();
      getSection();
    }
    return () => {
      isMuted = false
    }
  }, []);

  const handelChangeDegre = async (e: SelectChangeEvent<string>) => {
    setToggleViewPeriode(true);
    setDegre(e.target.value)
    const getPeriode = await GetPeriode(e.target.value);
    if (getPeriode?.data !== undefined && getPeriode?.data.status === 200) {
      console.log(getPeriode?.data);
      const epreuveList = getPeriode?.data.epreuveList;
      const firstItem = epreuveList[0]
      setPeriode(`${firstItem.id}`);
      setPeriodeList(getPeriode?.data.epreuveList);
      setToggleViewPeriode(false);

    }

  }

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handelChangeClasse = (e: SelectChangeEvent<string>) => {
    classeSelected(e.target.value);
    setClasseState(e.target.value);
    setCoursState('null');
    getCoursByClasse(e.target.value);
  }

  const handelChangeCours = (e: SelectChangeEvent<string>) => {
    setCoursState(e.target.value);
    const cours = coursList?.find(item => item.id === parseInt(e.target.value))
    setCurrentCours(cours);
  }

  const changeCote: any = (e: any) => {
    console.log(e.target.value);
    if (currentCours !== undefined && currentCours?.maximum >= e.target.value) {
      setCote(e.target.value)
    }
  }

  const handleCreatCoteEleve = async () => {
    if(classeState === 'null') {
      setRes({
        type: 'error',
          msg: "Veillez choisire une classe."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }else if(periode === 'null') {
      setRes({
        type: 'error',
          msg: "Veillez choisire une période."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }else if (currentEleve === undefined) {
      setRes({
        type: 'error',
          msg: "Veillez selectionné au moins un élève."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    // }
    // else if (cote === 0){
    //   setRes({
    //     type: 'error',
    //       msg: "Le cote ne dois pas être vide ou égale à 0."
    //   });
    //   handleClick({
    //     vertical: 'top',
    //     horizontal: 'center',
    //   })
    }else if (session === 'null'){
      setRes({
        type: 'error',
          msg: "Veillez choisire une session."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }else if (coursState === 'null'){
      setRes({
        type: 'error',
          msg: "Veillez choisire un cours."
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }else{
      setOpenBackdrop(true);
      const creatCoteEleve = await CreatCoteEleve({
        cote,
        eleve: currentEleve.id,
        cours: parseInt(coursState),
        epreuve: parseInt(periode),
        session: parseInt(session)
      });
      setOpenBackdrop(false);
      console.log(creatCoteEleve?.data);
      
      if (creatCoteEleve?.data !== undefined && creatCoteEleve?.data.status === 201) {
        setStateSuccess({
          openSuccess: true, ...{
            vertical: 'top',
            horizontal: 'center',
          }
        });
        setRes({
          type: 'success',
          msg: creatCoteEleve?.data.message
        });
        setTimeout(() => {
          setStateSuccess({
            openSuccess: false, ...{
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }, 3000);
        setcoteAutoFocus(true);
        if (index !== undefined && elevesList !== undefined) {
          setEleves(elevesList[index + 1]);
          setIndex(index + 1);
          setCote('');
        }
       
      }else{
        setRes({
          type: 'error',
          msg: creatCoteEleve?.data?.message
        });
        handleClick({
          vertical: 'top',
          horizontal: 'center',
        })
      }
    }
  }

  return (
    <Grid item>
      <Card
        sx={{
          background: mode === 'dark' ? '#323337' : '#fff',
          pl: 3,
          pr: 3,
          height: 600
        }}
      >
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e: any) => {
              e.preventDefault();
              handleCreatCoteEleve()
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Section</InputLabel>
                  <Select
                    size='small'
                    label='Section'
                    defaultValue='null'
                    value={sectionState}
                    onChange={handelChangeSection}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                    {sectionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Option</InputLabel>
                  <Select
                    size='small'
                    label='Option'
                    defaultValue='null'
                    value={optionState}
                    onChange={handelChangeOption}
                    fullWidth
                    disabled={toggleViewOption}
                  >
                    <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                    {optionList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Classe</InputLabel>
                  <Select
                    size='small'
                    label='Classe'
                    defaultValue='null'
                    value={classeState}
                    onChange={handelChangeClasse}
                    fullWidth
                    disabled={toggleViewClasse}
                  >
                    <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                    {classeList?.map((item) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Session</InputLabel>
                  <Select
                    size='small'
                    label='Session'
                    defaultValue='null'
                    value={session}
                    onChange={(e) => setSession(e.target.value)}
                    fullWidth
                  >

                    {sessionList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>degré</InputLabel>
                  <Select
                    size='small'
                    label='Degré'
                    defaultValue={'null'}
                    value={degre}
                    onChange={handelChangeDegre}
                    fullWidth
                  >
                    <MenuItem value={`null`}>Selectioné le degré</MenuItem>
                    {degreState.degreList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Période</InputLabel>
                  <Select
                    size='small'
                    label='Période'
                    defaultValue={'null'}
                    value={periode}
                    onChange={(e) => setPeriode(e.target.value)}
                    fullWidth
                    disabled={toggleViewPeriode}
                  >
                    <MenuItem value={`null`}>Selectioné une période</MenuItem>
                    {periodeList.map((item: { id: number, nom: string }) => <MenuItem value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Cours</InputLabel>
                  <Select
                    size='small'
                    label='Cours'
                    defaultValue='null'
                    value={coursState}
                    onChange={handelChangeCours}
                    fullWidth
                    disabled={toggleViewCours}
                  >
                    <MenuItem value={`null`}>Selectioné un cours</MenuItem>
                    {coursList?.map((item) => <MenuItem key={`cours${item.id}`} value={`${item.id}`}>{item.nom}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  size='small'
                  label="Cote"
                  id="text"
                  value={cote}
                  onChange={(e) => changeCote(e)}
                  fullWidth
                  autoFocus={coteAutoFocus}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                  variant="contained"
                  startIcon={<BorderColorIcon />}
                >
                  Coter
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'dkkfrii'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

