import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  LightMode,
  Login,
  DarkMode
} from "@mui/icons-material";
import { useAppSelector } from '../../../../services/store/hooks';
import { selectAuth } from '../../../../services/store/auth/auth.slice';
import { useEffect } from 'react';
const pages = ['Produits', 'Services','Solutions','Support','RooTech RDC'];

const ResponsiveAppBar = ({currentTheme, setMode}:any) => {

  const Auth = useAppSelector(selectAuth);
  let Navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [curentMenu, setCurentMenu] = React.useState<string>("");
 

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

 
  
  const goToAuth = () => {
    Navigate('/auth/');
  }

 
  return (
    <Box sx={{ display: 'flex', padding:'0px !important'}}>
      <AppBar position="fixed"
        sx={{
          background : 'rgba(21,21,22, 0.9) !important',
         }}
      >
        <Toolbar style={{ alignItems: "center", justifyContent: "center" }}>
            <span
              onClick={() => Navigate('/') }
              style={{ 
                cursor:'pointer',
              }}
            >
              <img alt="rootech" src="/rootech.png" width={100} style={{marginBottom:8}} />
            </span>
            {/* menu on mobile */}
            <Grid justify={"center"} alignItems={"center"} container>
              <Grid item>
                <Grid container justify={"center"}>
                  <Box sx={{flexGrow:1, display: { xs: 'flex', md: 'none' } }}>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                        vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                      >
                        {pages.map((page) => (
                          <MenuItem key={page} onClick={handleCloseNavMenu}>
                            <Typography textAlign="center">{page}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'center' },alignContent:"center", alignItems:'center'  }}>
                      {pages.map((page) => (
                        <span
                          key={page}
                          onClick={() => setCurentMenu(page)}
                          style={{ marginRight: 15, marginLeft:15, color:curentMenu === page ? '#00AEEF':'#fff', display: 'block', cursor:'pointer' }}
                        >
                          <Typography>
                            {page} 
                          </Typography>
                        </span>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            
            <Box sx={{ flexGrow: 0, display:'flex' }}>
              <Tooltip title="Login">
                <Button onClick={goToAuth} 
                sx={{
                  background:'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)',
                  textTransform:'capitalize',
                  color:currentTheme !== 'dark'?'#323337':'#fff'
                }} 
                  size="small" 
                  variant="contained" 
                  startIcon={<Login />}
                >
                  Connexion
                </Button>
              </Tooltip>
              {/* <Button
                sx={{
                  ml:2,
                  background: currentTheme !== "dark"? '#fff' : '#323337',
                  textTransform:'capitalize',
                    '&:hover':{
                        background: currentTheme === "dark"? '#fff' : '#323337',
                    }
                }}
                onClick={() => setMode((currentTheme !== "dark"? 'dark' : 'light'))}
              >
                {currentTheme !== "light" ? <LightMode htmlColor='linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)' />:<DarkMode  htmlColor='linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)'/>} 
              </Button> */}
            </Box>
          </Toolbar>
      </AppBar>
    </Box>
  );
};
export default ResponsiveAppBar;
