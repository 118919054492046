import {Chip, Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import {
    Call,
    Facebook,
    Instagram,
    LinkedIn,
    Mail,
    Telegram,
    Twitter,
    WhatsApp,
    YouTube
} from "@mui/icons-material";
import {useColorScheme} from "@mui/material/styles";
import SkypeIcon from "../../../global/skypeIcon";
import TiktokIcon from "../../../global/TiktokIcon";

const Footer = ({currentTheme}:any) => {
    return(
        <Box>
           <Grid container spacing={2} p={5} sx={{background: currentTheme == "dark"? '#323337' : '#fff',}}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} mb={3}>
                                <Grid item xs={12}>
                                    <span className="sub-ty-text">Suivez-nous sur :</span>
                                    <Stack direction="row" spacing={1} pt={2} sx={{flexWrap:'wrap'}}>
                                        <a href='https://www.instagram.com/invites/contact/?i=8bb6p9b5la4i&utm_content=ofbklpd' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                            <IconButton className='lienHyperText' >
                                                <Instagram className='lienHyperText-icon'/>
                                            </IconButton>
                                        </a>

                                        <a href='https://www.linkedin.com/in/rootech-drc-8a19ba23b' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                            <IconButton className='lienHyperText' >
                                                <LinkedIn className='lienHyperText-icon'/> 
                                            </IconButton>
                                        </a>

                                        <a href='https://youtube.com/channel/UCC_OmKSmHSI6Kf7cVhNVLZg' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                            <IconButton className='lienHyperText'>
                                                <YouTube className='lienHyperText-icon'/>
                                            </IconButton>
                                        </a>

                                        <a href='https://vm.tiktok.com/ZMLoLpFJw/' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                            <IconButton className='lienHyperText'>
                                                <TiktokIcon className='lienHyperText-icon'/>
                                            </IconButton>
                                        </a>

                                        <a href='mailto:rootech@rootech-drc.com' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                          <IconButton className='lienHyperText' >
                                            <Mail className='lienHyperText-icon'/>
                                          </IconButton>
                                        </a>

                                        <a href='https://twitter.com/Rootech_drc?t=fi1gQSFeYSonbXQ8xlK1EA&s=09' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                          <IconButton className='lienHyperText' >
                                            <Twitter className='lienHyperText-icon'/>
                                          </IconButton>
                                        </a>

                                        <a href='https://www.facebook.com/rootechdrc/' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                          <IconButton className='lienHyperText' >
                                            <Facebook className='lienHyperText-icon'/>
                                          </IconButton>
                                        </a>

                                    </Stack>
                                </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={0.5} mb={1}>
                            <Grid item xs={12}>
                                    <span className="sub-ty-text">Contactez-nous :</span>
                                    <Grid container spacing={1} mt={1} mb={1}>
                                        <Grid item xs={6} md={3}>
                                            <a href='tel:+243842505161' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                                <Chip className='lienHyperText' icon={<Call className='lienHyperText-icon' />} label=": +243 84 250 51 61"/>
                                            </a>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <a href='whatsapp://send?text=Salut, je souhaite en savoir plus sur vos produits et services&phone=+243891333763' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                                <Chip className='lienHyperText' icon={<WhatsApp className='lienHyperText-icon' />} label=": +243 89 13 33 763"/>
                                            </a>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <a href='https://telegram.me/RooTech_drc' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                                <Chip className='lienHyperText' icon={<Telegram className='lienHyperText-icon' />} label=": +243 89 13 33 763"/>
                                            </a>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <a href='https://join.skype.com/invite/ChEZcnXMNhwz' target="_blank" style={{textDecoration:'none'}} rel="noreferrer">
                                                <Chip className='lienHyperText' icon={<SkypeIcon className='lienHyperText-icon' />} label=": +243 89 13 33 763"/>
                                            </a>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <hr />
                        <Typography variant='body1' pt={2} sx={{textAlign:"center"}}>
                        Copyright © 2022 RooTech DRC. Tous droits réservés.
                        </Typography>
                    </Grid>
            </Grid>
        </Box>
    )
}

function FooterStore(){
    const { mode } = useColorScheme();
    return <Footer currentTheme={mode}/>
  }

export default FooterStore;