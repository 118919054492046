import { Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useColorScheme} from "@mui/material/styles";
import ScrollAnimation from 'react-animate-on-scroll';
import img6 from '../../assets/img/img6.jpg'
import img7 from '../../assets/img/img7.jpg'
import img10 from '../../assets/img/img10.png'
import img11 from '../../assets/img/img11.jpg'
import Footer from "../../components/layout/global/footer";
import ApexChartWrapperDash from "./apexChartWrapper";
import FeaturedPost from "./FeaturedPost";
import Stack from '@mui/material/Stack';
import { useState } from "react";

const styles = {
  paperContainer: {
    backgroundImage: `url(${img6})`,
    backgroundAttachment:"fixed",
    backgroundSize:"cover",
    height:500,
    borderRadius:0,
    borderWidth:0,
    boxShadow:"none",
  },
  paperContainerIdee: {
    backgroundImage: `url(${img10})`,
    height:500,
    width: '100%',
  },
  paperContainerBanier: {
    backgroundImage: `url(${img11})`,
    height:500,
    borderRadius:0,
    borderWidth:0,
    boxShadow:"none",
    backgroundAttachment:"fixed",
    backgroundSize:"cover",
  }
};


function HomeDashBoardView({currentTheme, ontoggle}:any){
  const [textColor] = useState<string>('#F06E27');
 
  // #00AEEF
  return <>
      <Grid container>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Paper style={styles.paperContainerBanier}>
                  <Stack sx={{height:"100%"}} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
                      <Typography variant="h3" sx={{textAlign:"center",color:textColor}}>
                          RooTech, une startup d'innovation technologique !
                      </Typography>
                  </Stack>


              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={0.5} sx={{mt:0.5}}>
            <Grid  item xs={12} md={12} sm={12}>
                <FeaturedPost post={
                 { description: `Un système éducatif interconnecté, assurant une traçabilité sans faille.`,
                  image: img7,
                  imageLabel: 'innovation',
                  title: 'RooTech'}
                }mode={currentTheme}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{pr:0.5}}>
                <ApexChartWrapperDash/>
              </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{mt:0.5}}>
            <Paper style={styles.paperContainer}>
              <ScrollAnimation animateIn='fadeIn'>
                <Typography variant="h5" sx={{textAlign:"left",pt:25,color:"white", pl:5}}>
                  Au centre de l'Afrique <br/>
                  Dans la République Démocratique du Congo<br/>
                </Typography>
              </ScrollAnimation>
            </Paper>
          </Grid>
           {/* Footer */}
          <Grid item xs={12} sm={12} md={12}>
            <Footer/>
          </Grid>
      </Grid>
    </>
}

function HomeDashBoardStore(){
  const { mode } = useColorScheme();
  return <HomeDashBoardView currentTheme={mode}/>
}

export default HomeDashBoardStore;
