import {createPortal} from "react-dom";
import {Backdrop, CircularProgress} from "@mui/material";

export default function BackdropWithPortal ({openBackdrop,handleClose}:any){
    return createPortal(
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>,document.body)
}