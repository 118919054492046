import * as React from 'react';
import { Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import FeaturedPost from "./FeaturedPost";
import { eleves } from './DataTable/data';
import ElevesList, { dataType } from './DataTable/ElevesList'
import { GetClasseByIdOption, GetEleves, GetElevesByIdClasse, GetOptionByIdSection, GetSection } from "../../services/api";
import { AxiosError, AxiosResponse } from 'axios';

interface Props {
  mode: Mode | undefined
}

const EtablissementDashboardView = ({ mode }: Props) => {
  const [rowClicked, setRowClicked] = React.useState<any[]>([1, 'Cruz', 'kasawa', 'Ortiz'])
  const [selectedFilter, setSelectedFilter] = React.useState("All");
  const [sectionState, setSectionState] = React.useState('null');
  const [sectionList, setSectionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionList, setOptionList] = React.useState<{ id: number, nom: string }[]>();
  const [optionState, setOptionState] = React.useState('null');
  const [classeList, setClasseList] = React.useState<{ id: number, nom: string }[]>();
  const [classeState, setClasseState] = React.useState('null');
  const [toggleViewSection, setToggleViewSection] = React.useState<boolean>(true);
  const [toggleViewOption, setToggleViewOption] = React.useState<boolean>(true);
  const [toggleViewClasse, setToggleViewClasse] = React.useState<boolean>(true);
  const [elevesList, setElevesList] = React.useState<dataType[]>([]);
  const [currentEleve, setCurrentEleve] = React.useState<dataType | undefined>(elevesList[0]);
  
  const getEleves = async (id: string) => {
    const res: any = await GetElevesByIdClasse(id);
    if (res?.data !== undefined && res?.data.status === 200) {
      const eleveList: dataType[] = [];
      for (let i = 0; i < res?.data.eleveList.length; i++) {
        let Eleve = res?.data.eleveList[i];
        Eleve.n = i + 1;
        eleveList.push(Eleve);
      }
      const firstEleve: dataType = eleveList[0];
      setElevesList(eleveList);
      setCurrentEleve(firstEleve);
      setRowClicked([firstEleve.n, firstEleve.nom, firstEleve.postnom, firstEleve.prenom]);
    } else {
      setElevesList([]);
      setCurrentEleve(undefined);
      setRowClicked([]);
    }


  }

  const getSection = async () => {
    const get_section = await GetSection();
    if (get_section?.data !== undefined && get_section?.data.status === 200) {
      console.log(get_section?.data);
      const section_List = get_section?.data.sectionList
      const section = section_List[0];
      setSectionList(section_List);
      setOptionState(`null`);
      setToggleViewSection(false);
    }else{
      setSectionList(undefined);
      setOptionState(`null`);
      setToggleViewSection(true);
    }
  }

  const getOption = async (id: number) => {
    const get_option = await GetOptionByIdSection(id);
    if (get_option?.data !== undefined && get_option?.data.status === 200) {
      console.log(get_option?.data);
      const option_List = get_option?.data.optionList
      const option = option_List[0];
      setOptionList(option_List);
      setClasseState(`null`);
    }else{
      setOptionList(undefined);
      setClasseState(`null`);
      setToggleViewOption(true);
    }
  }

  const getClasse = async (id: number) => {
    const get_classe_by_option = await GetClasseByIdOption(id);
    if (get_classe_by_option?.data !== undefined && get_classe_by_option?.data.status === 200) {
      console.log(get_classe_by_option?.data);
      const classe_List = get_classe_by_option?.data.classeList
      const classe = classe_List[0];
      setClasseList(classe_List);
      setClasseState(`${classe.id}`);
      getEleves(`${classe.id}`);
    } else {
      setClasseList([]);
      setClasseState('null');
      getEleves(`0`);
      setToggleViewClasse(true);
    }
  }

  const handelChangeSection = async (e: SelectChangeEvent<string>) => {
    setToggleViewOption(true);
    setSectionState(e.target.value);
    getOption(parseInt(e.target.value));
    setToggleViewOption(false);
    setOptionState(`null`);
    setToggleViewClasse(true);
    setClasseList([]);
    setClasseState('null');
    getEleves(`0`);
  }

  const handelChangeOption = async (e: SelectChangeEvent<string>) => {
    setToggleViewClasse(true);
    setOptionState(e.target.value);
    getClasse(parseInt(e.target.value));
    setToggleViewClasse(false);
    setClasseState(`null`);
  }

  const handelChangeClasse = async (e: SelectChangeEvent<string>) => {
    setClasseState(e.target.value);
    console.log('change classe');
    await getEleves(e.target.value);
  }

  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getSection();
    }
    return () => {
      isMuted = false;
    }
  }, []);

  const handleClickRow = (rowMeta: { dataIndex: number, rowIndex: number }) => {
    setCurrentEleve(elevesList[rowMeta.rowIndex]);
    const eleve = elevesList[rowMeta.rowIndex];
    setRowClicked([eleve.n, eleve.nom, eleve.postnom, eleve.prenom])
  }

  return (<Grid container md={12} lg={12} xs={12}>
    <Grid item md={12} lg={12} xs={12} sm={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Section</InputLabel>
                <Select
                  size='small'
                  label='Section'
                  defaultValue='null'
                  value={sectionState}
                  onChange={handelChangeSection}
                  fullWidth
                >
                  <MenuItem value={`null`}>Sélectionnez la section</MenuItem>
                  {sectionList?.map((item) => <MenuItem key={item.id}  value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Option</InputLabel>
                <Select
                  size='small'
                  label='Option'
                  defaultValue='null'
                  value={optionState}
                  onChange={handelChangeOption}
                  fullWidth
                  disabled={toggleViewOption}
                >
                  <MenuItem value={`null`}>Sélectionnez l'option</MenuItem>
                  {optionList?.map((item) => <MenuItem key={item.id}  value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Classe</InputLabel>
                <Select
                  size='small'
                  label='Classe'
                  defaultValue='null'
                  value={classeState}
                  onChange={handelChangeClasse}
                  fullWidth
                  disabled={toggleViewClasse}
                >
                  <MenuItem value={`null`}>Sélectionnez la classe </MenuItem>
                  {classeList?.map((item) => <MenuItem key={item.id} value={`${item.id}`}>{item.nom}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    <Grid item container md={12} lg={12} xs={12} sm={12} sx={{ pt: 0.5 }}>
      <Grid item md={12} lg={8} xs={12} sm={12} pr={0.5}>
        <ElevesList ElevesList={elevesList} handleClickRow={handleClickRow} rowClicked={rowClicked} />
      </Grid>
      <Grid item md={4} lg={4} xs={12}>
        <FeaturedPost post={currentEleve} mode={mode} />
      </Grid>
    </Grid>
  </Grid>
  )

}

function EtablissementDashboardStore() {
  const { mode } = useColorScheme();
  return <EtablissementDashboardView mode={mode} />
}

export default EtablissementDashboardStore;
