import {Chip, Grid, Typography} from "@mui/material";
import logo from "../../../../assets/rootech.png";
import {DarkMode, LightMode} from "@mui/icons-material";


export default function Head({currentTheme, setMode}:any){
    return <>
        <Grid container style={{marginBottom:'2rem',background:"transparent"}}>
            <Grid item xs={6}>
                <a href="/" className="ty-text-gray" style={{textDecoration:"none" }}>
                    <img alt="logo" src={logo} width='150px'/>
                    <Typography variant='body1' className="sub-ty-text-gray">
                        Root Technologies
                    </Typography>
                </a>
            </Grid>
            {/* <Grid item xs={6} textAlign="right" pt={3}>
                <Chip 
                    className='lienHyperText' 
                    icon={
                      currentTheme !== "light" ? 
                        <LightMode className='lienHyperText-icon' fontSize="small"/>:
                        <DarkMode className='lienHyperText-icon' fontSize="small" />
                    } 
                    label={(currentTheme !== "dark"? 'Sombre' : 'Clair')} 
                    variant={currentTheme !== "dark"?"outlined":"filled"}
                    size="small" onClick={() => setMode((currentTheme !== "dark"? 'dark' : 'light'))}
                />
            </Grid> */}
        </Grid>
    </>
}