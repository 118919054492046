import React, { useEffect, useState } from "react";
import MUIDataTable, { Responsive, FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";
import { eleves } from "./data";
import { GetUsers } from "../../../services/api";

const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});

export type dataProfType = {
  id: number;
  n: number;
  etatCivil: string;
  nom: string;
  postnom: string;
  prenom: string;
  sexe: string;
  classe: string;
  lieuDeNaissance: string;
  dateDeNaissance: string;
  nationalite: string;
  photo: string;
  telephone: string;
  email: string;
  niveauEtude: string,
  domaineDeFormation: string,
  fonction: string,
  grade: string,
}

const ListUserDataTable = ({ handleClickRow }: any) => {
  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [tableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight] = useState("");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();
  const [rowClicked, setRowClicked] = useState<any[]>([1, 'Cruz', 'kasawa', 'Ortiz'])

  const [usersList, setUsersList] = useState<dataProfType[]>([]);
  const getUsers = async () => {
    const get_eleves = await GetUsers();
    if (get_eleves?.data !== undefined && get_eleves?.data.status === 200) {
      console.log(get_eleves?.data);
      const users_List = get_eleves?.data.utilisateurList
      const users = users_List[0];
      setUsersList(users_List);
      setRowClicked([users.id, users.nom, users.postnom, users.prenom]);
    }
  }
  const columns = [
    {
      name: "id", label: 'N°',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "nom", label: 'Nom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "postnom", label: 'Postnom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "prenom", label: 'Prénom',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "sexe", label: 'Sexe',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "dateEnregistrement", label: 'Date Enregistrement',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
    {
      name: "role", label: 'Rôle',
      setCellHeaderProps: () => ({
        style: {
          backgroundColor: mode === 'dark' ? '#323337' : '#fff',
        }
      }),
    },
  ];
  // const rows:dataType[] = [];
  const rows = [];
  for (let i = 0; i < eleves.length; i++) {
    const d = eleves[i];
    rows.push({
      id: i + 1,
      nom: d.nom,
      postnom: d.postnom,
      prenom: d.prenom,
      sexe: d.sexe,
      classe: d.classe,
      lieuDeNaissance: d.lieuDeNaissance,
      dateDeNaissance: d.dateDeNaissance,
      nationalite: d.nationalite,
      photo: d.photo,
      telephone: d.telephone,
      email: d.email,
      etatCivil: d.etatCivil
    });
  }

  const handleRowClick = (rowData: any, rowMeta: any) => {
    handleClickRow(rowMeta);
    setRowClicked(rowData);
  };
  
  const options = {
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: filterBtn,
    filterType,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onRowClick: handleRowClick,
    setRowProps: (row: any[], dataIndex: number, rowIndex: number) => {
      if (row[0] === rowClicked[0] && row[1] === rowClicked[1]
        && row[2] === rowClicked[2] && row[3] === rowClicked[3]) {
        return {
          style: {
            background: "#F5F5F5",
            boxShadow: '5px 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }
        }
      } else {
        return {}
      }


    }
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important'
              cursor: "pointer"

            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },

        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? '#323337' : '#fff',
          },
        },
      },
    },
  });
  useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      getUsers();
    }
    return () => {
      isMuted = false;
    }
  }, [])

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Liste des agents"}
          data={usersList}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </CacheProvider>
  );

}

export default ListUserDataTable;

