import React, { useState } from "react";
import MUIDataTable, { Responsive,FilterType } from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useColorScheme } from "@mui/material/styles";


export type dataType = {
  n:number;
  id:number;
  nom: string
  postnom: string
  prenom:  string
  sexe:  string
  classe: string
  lieuDeNaissance: string
  dateDeNaissance:  string
  nationalite: string
  photo:  string
  telephone: string
  email: string
  etatCivil: string
}
const muiCache = createCache({
  key: "mui-datatablesdf",
  prepend: true
});

const TranfertEleveDataTable = ({eleveList,handleSelectedRow,rowsSelected,setRowsSelected}:any) => { 
  const [responsive] = useState<Responsive>("vertical");
  const [filterType] = useState<FilterType>("dropdown");
  const [tableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight] = useState("");
  const [filterBtn] = useState(true);
  const { mode } = useColorScheme();
  const columns = [
    { name: "n",label:'N°',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "nom",label:'Nom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "postnom",label:'Postnom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "prenom",label:'Prénom',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
    { name: "sexe",label:'Sexe',
      setCellHeaderProps: () => ({ style: {
       backgroundColor: mode === 'dark'?'#323337':'#fff',
      }}),},
  ];
  
  const options = {
    selectableRowsHideCheckboxes: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    filter: filterBtn,
    filterType,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    rowsSelected: rowsSelected,
    // onRowsSelect : (curRowSelected:any, allRowsSelected: any,rowsSelected: any) => {
    //   console.log("All Selected: ", allRowsSelected);
    //   handleSelectedRow(allRowsSelected)
    // },
    onRowSelectionChange: (rowsSelectedData:any, allRows: any, rowsSelected: any) => {
      console.log(allRows);
      setRowsSelected(rowsSelected);
      handleSelectedRow(allRows)
    },
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiTableHead: {
        styleOverrides:{
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              // backgroundColor: '#00AEEF !important'
              cursor:"pointer"

            },
            '&:seleted': {
              // backgroundColor: '#00AEEF !important'
            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
          
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark'?'#323337':'#fff',
          },
        },
      },
    },
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider  theme={getMuiTheme()}>
        <MUIDataTable
            title={"Transfert des élèves"}
            data={eleveList}
            columns={columns}
            options={options}
          />
      </ThemeProvider>
    </CacheProvider> 
  );
}

export default TranfertEleveDataTable;

