import * as React from 'react';
import Box from '@mui/material/Box';
import {useColorScheme} from "@mui/material/styles";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import OptionsList from './optionsList';
import OptionMenu from './options';
import { useNavigate } from "react-router-dom";



const checkCurrentLinkOption = () => {
  const pathname = window.location.pathname.substring(15);
  const position = pathname.indexOf('-');
  if(position !== -1){
    const path = pathname.substring(0,position);
    if(path === 'Eleve')
      return 'Elève'
    else if (path === 'OrganiserEcole')
      return `Organiser l'école`
    else
      return path
  }else{
    return 'Elève'
  }
}
const selectedIndexOptionsList = () => {
  console.log(checkCurrentLinkOption())
  return OptionsList.findIndex(item => item.name === checkCurrentLinkOption())
}

export default function MobileDrawer({toggleDrawer,open,ChangeLinkOPtion,currentTheme}: any) {
  const drawerWidth = 250;
  const [selectedIndex, setSelectedIndex] = React.useState<number>(selectedIndexOptionsList());
  const Navigate = useNavigate();
  
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    link: string
  ) => {
    setSelectedIndex(index);
    ChangeLinkOPtion(link);
    toggleDrawer(false)
    const option = OptionsList.find(item => item.name === link);
    const Link = option?.options;
    let root: string = ''
    Link?.map((item:any,i:number) => {
      if(i === 0)
      root = item.root
    })
    console.log(`${link}-${root}`);
    if(link === 'Elève')
      Navigate(`Eleve`);
    else if (link === `Organiser l'école`)
    Navigate(`OrganiserEcole-${root}`);
    else
      Navigate(`${link}-${root}`);
  };

  const list = () => (
    <Box style={{width: 270, height:'100%',}} className={"main-" + currentTheme}>
      <List>
        <ListItem  disablePadding>
          <ListItemButton>
            <ListItemIcon>
            <span
              style={{ 
                cursor:'pointer',
              }}
            >
              <img alt="rootech" src="/rootech.png" width={100} style={{marginBottom:8}} />
            </span>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {OptionsList.map((item, index) => {
            if (item.name === 'Documentation'){
              return (<>
                <Divider />
                <ListItem key={index} disablePadding>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, index, item.name)}
                    >
                      <ListItemIcon>
                        <item.icon/>
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
              </>)
            }else{
              return(<>
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, index, item.name)}
                    >
                      <ListItemIcon>
                        <item.icon/>
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
              </>)
            }
        })}
      </List>
    </Box>
  );


  return (
    <div>
      <React.Fragment>
          <Drawer
            anchor={'left'}
            open={open}
            onClose={() => toggleDrawer(false)}
            onClick={() => toggleDrawer(false)}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
          >
            {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
