import * as React from 'react';
import { Grid } from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import FeaturedPost from "../FeaturedPost";
import { dataType } from "../DataTable";
import ListEnseignantDataTable from "../DataTable/listEnseignant";
import { eleves } from '../DataTable/data';
import EnseignantCardPost from '../FeaturedPost/enseignantCard';
import { GetUsers } from '../../../services/api';

interface Props {
  mode : Mode | undefined
}

const EnseignantSommaireView = ({mode}: Props) => {
  const [userList, setUserList] = React.useState<dataType[]>([]);
  const [currentUser, setCurrentUsers] = React.useState<dataType | undefined>(userList[0]);
 
  const getUsers = async () => {
    const get_users = await GetUsers();
    if (get_users?.data !== undefined && get_users?.data.status === 200) {
      console.log(get_users?.data);
      const users_List = get_users?.data.utilisateurList
      const users = users_List[0];
      setUserList(users_List);
      setCurrentUsers(users);
    }else{
      
    }
  }

  React.useEffect(() => {
    let isMuted = true;
    if(isMuted) {
      getUsers();
    }
    return () => {
      isMuted = false;
    }
  },[]);

  const handleClickRow = (rowMeta: {dataIndex: number,rowIndex: number}) => {
    setCurrentUsers(userList[rowMeta.rowIndex]);
    console.log(eleves[rowMeta.rowIndex]);
    
  }
  return( <Grid container md={12} lg={12} xs={12}>
    <Grid item md={12} lg={8} xs={12} sm={12} pr={0.5}>
      <ListEnseignantDataTable handleClickRow={handleClickRow}/>
    </Grid>
    <Grid item md={4} lg={4}>
      <EnseignantCardPost post={currentUser} mode={mode}/>
    </Grid>
  </Grid>
  )
}

function EnseignantSommaireStore(){
  const { mode } = useColorScheme();
  return <EnseignantSommaireView mode={mode}/>
}
  
export default EnseignantSommaireStore;
