import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';

interface FeaturedPostProps {
  post: {
    description: string;
    image: string;
    imageLabel: string;
    title: string;
  };
  mode:any
}

export default function FeaturedPost(props: FeaturedPostProps) {
  const { post,mode } = props;

  return (
    <Grid item xs={12} md={12} sm={12} mr={0.5} ml={0.5}>
      <CardActionArea component="div">
        <Card 
          sx={{ 
            display: 'flex', 
            background:mode === 'dark'?'#323337':'#fff',
            flexDirection:{xs:'column',md:'row'},
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: {xs:'100%',md:250}, flex: 1}}
            image={post.image}
            alt={post.imageLabel}
          />
          <CardContent sx={{ flex: 1 }}>
            <Stack sx={{height:"100%"}} alignItems={"center"} justifyContent={'center'} alignContent={'center'}>
              <Typography variant="h6" paragraph >
                {post.description}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}