import * as React from 'react';
import { Alert, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import { useColorScheme } from "@mui/material/styles";
import { Mode } from "@mui/system/cssVars/useCurrentColorScheme";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State } from '../../auth/auth.agent.view';
import Button from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import { CreateUser } from '../../../services/api';
import { useAppSelector } from '../../../services/store/hooks';
import { selectAuth } from '../../../services/store/auth/auth.slice';
import BackdropWithPortal from '../../../components/global/backdrop';
import moment from 'moment';
import sha1 from 'sha1';
import axios from 'axios';

interface Props {
  mode: Mode | undefined
}

function EnseignantEnregistreView({ mode }: Props) {
  const [openBackdrop, setOpenBackdrop] = React.useState<boolean>(false);

  const Auth = useAppSelector(selectAuth);
  const compteUtilisateur = Auth.compteUtilisateur;
  const user = compteUtilisateur.utilisateur;
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [sexe, setSexe] = React.useState('F')
  const [imgSrc, setImgSrc] = React.useState<string>('/images/avatars/9.jpg');
  const [dateState, setDateState] = React.useState<Date | null>();
  const [Etatcivil, setEtatCivil] = React.useState<string>('Célibataire');
  const [gradeState, setGradeState] = React.useState<string>('agb 1');
  const [niveauEtudeState, setNiveauEtudeState] = React.useState<string>('d4');
  const [stateSuccess, setStateSuccess] = React.useState({
    openSuccess: false,
  });
  const [res, setRes] = React.useState({
    type: '',
    msg: '',
  });
  const { vertical, horizontal, open } = state;
  const { openSuccess } = stateSuccess;
  const [fileImg, setFile] = React.useState<any>();

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSuccess = () => {
    setStateSuccess({ ...state, openSuccess: false });
  }

  const handleCreatUser = async (values: any) => {
    setOpenBackdrop(true);
    // console.log(values)
    var data = new FormData();
    var CurrentDate = moment().unix();
    var publicID = sha1(`${values.nom}${values.postnom}${values.prenom}${CurrentDate}`)
    var si = `public_id=${publicID}${CurrentDate}&timestamp=${CurrentDate}k_qCVXtdjeAhGgIathi2Cl3dPg8`;
    data.append('public_id', `${publicID}${CurrentDate}`);
    data.append('timestamp', `${CurrentDate}`);
    data.append('signature', sha1(si));
    data.append('api_key', '425764859379838');
    data.append('file', fileImg);

    var config = {
      method: 'post',
      url: 'https://api.cloudinary.com/v1_1/rootech-drc/image/upload',
      data: data
    };

    const UploadImg = await axios(config)
      .then(function (response) {
        console.log(response.data);
        return response
      })
      .catch(function (error) {
        console.log(error);
        return error
      });
    var image = null
    if (UploadImg?.data !== undefined) {
      image = UploadImg.data.secure_url
    }
    const createUser = await CreateUser({
      nom: values.nom,
      postnom: values.postnom,
      prenom: values.prenom,
      sexe: sexe,
      lieuDeNaissance: values.lieuDeNaissance,
      dateDeNaissance: moment(dateState).calendar(),
      nationalite: values.nationalite,
      photo: image,
      etatCivil: Etatcivil,
      telephone: values.telephone,
      email: values.email,
      niveauEtude: niveauEtudeState,
      domaineDeFormation: values.domaineDeFormation,
      fonction: values.fonction,
      grade: gradeState,
      ecole: compteUtilisateur.ecole.id,
    })

    setOpenBackdrop(false);
    if (createUser?.data !== undefined && createUser?.data.status === 201) {
      console.log(createUser?.data);
      setStateSuccess({
        openSuccess: true, ...{
          vertical: 'top',
          horizontal: 'center',
        }
      });
      setRes({
        type: 'success',
        msg: createUser?.data.message
      });
      setTimeout(() => {
        setStateSuccess({
          openSuccess: false, ...{
            vertical: 'top',
            horizontal: 'center'
          }
        });
      }, 3000);
      values.nom = '';
    } else {
      console.log("error", createUser);
      setRes({
        type: 'error',
        msg: 'Une erreur est survenue lors du traitement de la requête'
      });
      handleClick({
        vertical: 'top',
        horizontal: 'center',
      })
    }

  }

  const onChange = (file: React.ChangeEvent) => {
    const reader = new FileReader()
    const { files } = file.target as HTMLInputElement
    if (files && files.length !== 0) {
      reader.onload = () => {
        setImgSrc(reader.result as string)
      }
      setFile(files[0]);
      reader.readAsDataURL(files[0]);
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSexe(event.target.value);
  }

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      nom: "",
      postnom: "",
      prenom: "",
      lieuDeNaissance: "",
      nationalite: "",
      photo: "",
      telephone: "",
      email: "",
      domaineFormation: "",
      fonction: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required('Required'),
      postnom: Yup.string().required('Required'),
      prenom: Yup.string().required('Required'),
      lieuDeNaissance: Yup.string().required('Required'),
      nationalite: Yup.string().required('Required'),
      telephone: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('email incorrect!'),
      domaineFormation: Yup.string().required('Required'),
      fonction: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleCreatUser(values)
    }
  });

  return (
    <Grid md={12} lg={12} xs={12}>
      <Card sx={{
        background: mode === 'dark' ? '#323337' : '#fff',
        width: '100%',
        mt: { xs: -5, md: -1, sm: -1, lg: -1 }
      }}>
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>

              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ display: 'flex', alignContent: 'center' }} src={imgSrc} alt='Profile Pic' width={250} height={250} onClick={() => setImgSrc('/images/avatars/9.jpg')} />
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ ml: 1 }}>
                        <Button
                          sx={{ textTransform: 'none' }}
                          color={'success'}
                          fullWidth
                          component='label'
                          variant='outlined'
                          htmlFor='image'>
                          <input
                            hidden
                            type='file'
                            onChange={onChange}
                            accept='image/png, image/jpeg'
                            id='image'
                          />
                          <AddAPhotoIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Nom"
                  id="nom"
                  value={values.nom}
                  onChange={handleChange}
                  fullWidth
                  error={touched.nom ? errors.nom === 'Required' ? true : false : false}
                  helperText={touched.nom ? errors.nom === 'Required' ? "Le nom de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Post-nom"
                  id="postnom"
                  value={values.postnom}
                  onChange={handleChange}
                  fullWidth
                // error={touched.postnom? errors.postnom === 'Required' ? true : false : false}
                // helperText={touched.postnom? errors.postnom === 'Required' ? "Le Post-nom de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  label="Prénom"
                  id="prenom"
                  value={values.prenom}
                  onChange={handleChange}
                  fullWidth
                  error={touched.prenom ? errors.prenom === 'Required' ? true : false : false}
                  helperText={touched.prenom ? errors.prenom === 'Required' ? "Le Prénom de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Sexe</InputLabel>
                  <Select
                    size='small'
                    label='Sexe'
                    defaultValue='F'
                    value={sexe}
                    onChange={(e) => handleSelectChange(e)}
                    fullWidth
                  >
                    <MenuItem value='F'>Féminin</MenuItem>
                    <MenuItem value='M'>Masculin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="lieuDeNaissance"
                  label="Lieu de naissance"
                  value={values.lieuDeNaissance}
                  onChange={handleChange}
                  placeholder='kinshasa ...'
                  fullWidth
                // error={touched.lieuDeNaissance? errors.lieuDeNaissance === 'Required' ? true : false: false}
                // helperText={touched.lieuDeNaissance? errors.lieuDeNaissance === 'Required' ? "Le lieux de naissance de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date de naissance"
                    value={dateState}
                    onChange={(newValue) => {
                      setDateState(newValue);
                    }}
                    renderInput={(params) => <TextField size='small' {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="nationalite"
                  label="Nationalité"
                  value={values.nationalite}
                  onChange={handleChange}
                  fullWidth
                  error={touched.nationalite ? errors.nationalite === 'Required' ? true : false : false}
                  helperText={touched.nationalite ? errors.nationalite === 'Required' ? "La nationalité de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Etat civil</InputLabel>
                  <Select
                    size='small'
                    label='Etat civil'
                    defaultValue='Célibataire'
                    value={Etatcivil}
                    onChange={(e) => setEtatCivil(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value='Célibataire'>Célibataire</MenuItem>
                    <MenuItem value='Marié(e)'>Marié(e)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="telephone"
                  label="Téléphone"
                  value={values.telephone}
                  onChange={handleChange}
                  fullWidth
                // error={touched.telephone? errors.telephone === 'Required' ? true : false : false}
                // helperText={touched.telephone? errors.telephone === 'Required' ? "Le numéro de téléphone de l'enseignant est obligatoire." : '': ''} 
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                // error={touched.telephone? errors.email === 'Required' ? true : false : false}
                // helperText={touched.telephone? errors.email === 'Required' ? "L'email de l'enseignant est obligatoire." : '' : ''}
                />
              </Grid>


              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Niveau d'étude</InputLabel>
                  <Select
                    size='small'
                    label={`Niveau d'étude`}
                    defaultValue='d4'
                    value={niveauEtudeState}
                    onChange={(e) => setNiveauEtudeState(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value='d4'>D4</MenuItem>
                    <MenuItem value='d6'>D6</MenuItem>
                    <MenuItem value='licence'>Licence</MenuItem>
                    <MenuItem value='master'>Master</MenuItem>
                    <MenuItem value='doctorat'>Doctorat</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="domaineFormation"
                  label="Domaine de formation"
                  value={values.domaineFormation}
                  onChange={handleChange}
                  fullWidth
                // error={touched.domaineFormation? errors.domaineFormation === 'Required' ? true : false : false}
                // helperText={touched.domaineFormation? errors.domaineFormation === 'Required' ? "Le domaine de formation de l'enseignant est obligatoire." : '': ''}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  size='small'
                  id="fonction"
                  label="Fonction"
                  value={values.fonction}
                  onChange={handleChange}
                  fullWidth
                // error={touched.fonction? errors.fonction === 'Required' ? true : false : false}
                // helperText={touched.fonction? errors.fonction === 'Required' ? "La fonction de l'enseignant est obligatoire." : '': ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grade</InputLabel>
                  <Select
                    size='small'
                    label='Grade'
                    defaultValue='agb 1'
                    value={gradeState}
                    onChange={(e) => setGradeState(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value='agb 1'>AGB 1</MenuItem>
                    <MenuItem value='agb 2'>AGB 2</MenuItem>
                    <MenuItem value='att 1'>ATT 1</MenuItem>
                    <MenuItem value='att 2'>ATT 2</MenuItem>
                    <MenuItem value='directeur chef de bureau'>Directeur Chef de bureau</MenuItem>
                    <MenuItem value='directeur chef de servise'>Directeur Chef de servise</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} />
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  type={'submit'}
                  sx={{ background: 'linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)', textTransform: 'capitalize', color: '#323337' }}
                  variant="contained"
                  startIcon={<SaveIcon htmlColor='#323337' />}
                >
                  Enregistrer
                </Button>
              </Grid>
              <Grid item md={4} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error">{res.msg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSuccess}
        onClose={handleCloseSuccess}
        key={vertical + 'dkkfrii'}
      >
        <Alert onClose={handleCloseSuccess}>{res.msg}</Alert>
      </Snackbar>
      <BackdropWithPortal openBackdrop={openBackdrop} handleClose={handleCloseBackdrop} />
    </Grid>
  );
}

function EnseignantEnregistreViewStore() {
  const { mode } = useColorScheme();
  return <EnseignantEnregistreView mode={mode} />
}

export default EnseignantEnregistreViewStore;