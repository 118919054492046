import React from "react";
import { Outlet } from "react-router-dom";
import { useColorScheme } from "@mui/material/styles";
import EtablissementLayout from "../../components/layout/etablissement";
import { store } from "../../services/store";

export const checkAuth = (): boolean => {
  const state = store.getState();
  const { auth } = state;
  if (!auth.isAuthenticated)
    return false
  else return true
}
const EtablissementModule = () => {
  const { mode } = useColorScheme();
  React.useEffect(() => {
    let isMuted = true;
    if (isMuted) {
      // if (!checkAuth()) document.location = '/auth';
    }
    return () => {
      isMuted = false;
    }
  }, [])
  return <EtablissementLayout currentTheme={mode}>
    <Outlet />
  </EtablissementLayout>
}

export default EtablissementModule;
